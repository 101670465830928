import { PAGES } from '@core/constants';
import { domain as globalDomain } from '@core/domain';

export const domain = globalDomain.domain('@appService');

const EXCLUDED_PAGES = [
	PAGES.VEHICLES.NAME,
	PAGES.PRIORITISED_VEHICLES.NAME,
	PAGES.DEALERS.NAME,
	PAGES.USERS.NAME,
	PAGES.ENQUIRY_EDIT.NAME,
	PAGES.VEHICLE_EDIT.NAME,
];

export const getPageMixPanelEvent = (pathname) => {
	const { NAME, PATTERN: RGX }: any =
		Object.values(PAGES).find(({ PATTERN }: any) => PATTERN && PATTERN.test(pathname)) || {};
	const [, id] = (RGX && pathname.match(RGX)) || [];
	return NAME && !EXCLUDED_PAGES.includes(NAME)
		? {
				event: NAME,
				...(id && { id }),
			}
		: undefined;
};
