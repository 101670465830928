import * as app from '../app/internal';

import * as events from './events';
import type { AppNotification } from './model';
import { domain } from './utils';

/**
 * Notification with redirect store
 */
export const $notificationForRedirect = domain
	.store<AppNotification | null>(null)
	.on(events.notify, (state, items) => (!state && items.find((it) => it.redirect)) || state)
	.reset([events.resetAfterRedirect, events.reset, app.reset]);
