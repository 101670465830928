import type { ListResponse } from '@core/model';
import type {
	IAccountManager,
	IDealerExclusive,
	ISalesSpecialistManager,
	ISupportSpecialist,
} from '@core/services/user';

import { DISPLAY_PERMISSIONS } from './constants';
import * as effects from './effects';
import * as events from './events';
import type { IMake, IPermission, IRole } from './model';
import { domain } from './utils';

/**
 * All system roles
 */
export const $roles = domain
	.store<IRole[]>([])
	.on(effects.getRolesFx.doneData, (_, data) => data)
	.reset(events.reset);

/**
 * All system permissions
 */
export const $permissions = domain
	.store<IPermission[]>([])
	.on(effects.getPermissionsFx.doneData, (_, { data }) => data.filter(({ name }) => DISPLAY_PERMISSIONS.includes(name)))
	.reset(events.reset);

/**
 * All account managers roles
 */
export const $accountManagers = domain
	.store<IAccountManager[]>([])
	.on(effects.getAccountManagersFx.doneData, (_, { data }) => data)
	.reset(events.reset);

/**
 * All dealer exclusives
 */
export const $dealerExclusives = domain
	.store<ListResponse<IDealerExclusive>>({ list: [], total: null })
	.on(effects.getDealerExclusivesFx.doneData, (_, data) => data)
	.reset(events.reset);
/**
 * All makes
 */
export const $makes = domain
	.store<IMake[]>([])
	.on(effects.getMakesFx.doneData, (_, { data }) => data)
	.reset(events.reset);

/**
 * Create options field list
 */
export const $makesOptions = $makes.map((list) => list.map(({ id, name }) => ({ id, name })));
/**
 * All sales specialist managers roles
 */
export const $salesSpecialists = domain
	.store<ISalesSpecialistManager[]>([])
	.on(effects.getSalesSpecialistsFx.doneData, (_, { data }) => data)
	.reset(events.reset);

/**
 * All support specialist managers
 */
export const $supportSpecialists = domain
	.store<ISupportSpecialist[]>([])
	.on(effects.getSupportSpecialistsFx.doneData, (_, { data }) => data)
	.reset(events.reset);
