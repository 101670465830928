import { createEffect, createEvent, createStore, sample } from 'effector';
import { debounce } from 'lodash-es';

import { PAGES } from '@core/constants';

import * as ga4Events from '../analytics/internal';

import * as events from './events';
import * as location from './location.store';

const ping = createEvent();
const stop = createEvent();
const send = createEvent();

const debouncePing = debounce(ping, 300);

const timers: { event?: number; inactive?: number } = {
	event: undefined,
	inactive: undefined,
};

const initEventLoop = () => {
	window.clearInterval(timers.event);
	timers.event = window.setInterval(send, 60 * 1000);
};

const $page = location.$pathname.map((pathname) => {
	const { NAME = null }: any = Object.values(PAGES).find(({ PATTERN }: any) => PATTERN && PATTERN.test(pathname)) || {};
	return NAME;
});

const $active = createStore(true)
	.on(ping, () => true)
	.on(stop, () => false)
	.reset(events.reset);

/**
 * Send GA time on page event
 */
sample({
	clock: send,
	filter: ({ active, page }) => active && !!page,
	fn: ({ page }) => page,
	source: { active: $active, page: $page },
	target: ga4Events.sendGA4TimeOnPageEvent,
});

sample({
	clock: ping,
	target: createEffect(() => {
		window.clearTimeout(timers.inactive);
		timers.inactive = window.setTimeout(stop, 30 * 1000);
	}),
});

sample({
	clock: $page,
	target: createEffect(() => {
		initEventLoop();
	}),
});

['mousemove', 'click', 'mouseup', 'keyup', 'submit', 'change', 'scroll', 'dblclick'].forEach((event) =>
	window.addEventListener(event as any, debouncePing, false),
);

// initEventLoop();
