import type { Effect } from 'effector';
import { lowerCase } from 'lodash-es';

import { VEHICLE_SIDE_IMAGE_URL } from '@core/app-state';
import { TIME_24_HOURS } from '@core/constants';
import type { RequestConfig } from '@core/http';
import { request } from '@core/http';
import { ORIGINAL_SIZES } from '@services/vehicleSides/constants';

import type { VehicleSide, VehicleSideData, VehicleSides } from './model';
import { domain, extractSVGParts } from './utils';

export const getVehicleSideDataFx: Effect<{ bodyType: string; enquiryId: number; height: number }, VehicleSideData> =
	domain.effect({
		handler: ({ bodyType, enquiryId, height }) =>
			request({
				baseURL: VEHICLE_SIDE_IMAGE_URL,
				cacheTTL: TIME_24_HOURS,
				isPublic: true,
				method: 'get',
				url: `/getPanelAsset/?enquiryId=${enquiryId}&bodyType=${lowerCase(bodyType)}&height=${height}`,
				withCredentials: false,
			} as RequestConfig),
	});

export const getVehicleSideImagesFx: Effect<{ bodyType: string; sides: VehicleSide[] }, VehicleSides> = domain.effect({
	handler: async ({ bodyType, sides }) => {
		const images = await Promise.all(
			sides.map(({ imageUrl }) =>
				fetch(imageUrl, {
					credentials: 'omit',
					mode: 'cors',
				}).then((res) => res.text()),
			),
		);

		return {
			[bodyType]: sides.reduce((acc, it, idx) => {
				acc[it.id] = extractSVGParts(images[idx], sides[idx].imageUrl, ORIGINAL_SIZES[bodyType][sides[idx].id]);
				return acc;
			}, {}),
		};
	},
});
