import { sample } from 'effector';

import * as events from './events';
import * as location from './location.store';
import type { IPageMetaProps } from './model';
import { domain as serviceDomain } from './utils';

const domain = serviceDomain.domain('@page');

export const setMeta = domain.event<IPageMetaProps>();
export const reset = domain.event();

/**
 * Current page meta store
 */
export const $meta = domain
	.store<IPageMetaProps>({
		documentTitle: 'Motorway Dashboard',
		title: '',
	})
	.on(setMeta, (state, meta) => ({ ...state, ...meta }))
	.reset([events.reset, reset]);

sample({
	clock: $meta,
	fn: ({ documentTitle }) => documentTitle,
	target: domain.effect((title?: string) => {
		title && (document.title = title);
	}),
});

// Reset page data on page change
sample({ clock: location.$pathname, target: reset });
