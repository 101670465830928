import { sample } from 'effector';

import * as app from '../app/internal';

import * as events from './events';

sample({
	clock: app.reset,
	target: events.reset,
});
