export enum SnowplowEventCategory {
	DOCUMENTS = 'Documents',
	IMAGES = 'Images',
	KYC = 'KYC',
	MANUAL_PAYMENT = 'Manual payment',
	STATUS_CHANGE = 'Status change',
}

export enum SnowplowEventSchema {
	BACKEND_TRIGGER = 'backend_trigger',
	CLICK = 'click',
	CTA_CLICKED = 'cta_clicked',
	HOVER = 'hover',
	LOAD = 'load',
	POPUP_APPEAR = 'popup_appear',
	TOGGLE_CLICK = 'toggle_click',
}

export const SNOWPLOW_EVENT_TYPE = {
	BACKEND_TRIGGER: {
		schema: SnowplowEventSchema.BACKEND_TRIGGER,
		version: '1-0-1',
	},
	CLICK: {
		schema: SnowplowEventSchema.CLICK,
		version: '2-0-0',
	},
	CTA_CLICKED: {
		schema: SnowplowEventSchema.CTA_CLICKED,
		version: '5-0-1',
	},
	HOVER: {
		schema: SnowplowEventSchema.HOVER,
		version: '2-0-1',
	},
	LOAD: {
		schema: SnowplowEventSchema.LOAD,
		version: '1-0-0',
	},
	POPUP_APPEAR: {
		schema: SnowplowEventSchema.POPUP_APPEAR,
		version: '1-0-0',
	},
	TOGGLE_CLICK: {
		schema: SnowplowEventSchema.TOGGLE_CLICK,
		version: '2-0-0',
	},
};

export enum ImageActionType {
	ARCHIVE = 'delete',
	DOWNLOAD = 'download',
	UPLOAD = 'upload',
}
