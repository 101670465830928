import type { DEALER_STATUSES } from '@core/constants';

export interface IAccountManager {
	Email: string;
	id: number;
	isDeleted: boolean;
	name: string;
}

export interface IDealerStatus {
	id?: number;
	name: string;
	slug: DEALER_STATUSES;
}

export interface IDealer {
	accountManager: IAccountManager;
	accountManagerId: number;
	additionalInfo: string | null;
	address1: string | null;
	address2: string | null;
	address3: string | null;
	ageFrom: number | null;
	ageTo: number | null;
	billingEmail: string | null;
	billingEntity: string | null;
	billingName: string | null;
	billingSameAsContactDetails: boolean;
	buysAllMakes: boolean;
	carsPerMonth: string | null;
	city: string | null;
	companyPhone: string | null;
	companyType: string | null;
	contactId: number;
	contactName: string;
	contactPhone: string | null;
	contactPosition: string | null;
	dealerStatus: IDealerStatus;
	email: string;
	firstLoginActivatedAt: string | null;
	goCardlessActive: boolean;
	goCardlessMandatory: boolean | null;
	howDidYouHearAboutUs: string | null;
	howDidYouHearAboutUsOtherDescription: string | null;
	id: number;
	inspectionPaymentNotes: string | null;
	inspectionProcess: string | null;
	isOperable?: boolean;
	kycStatus?: string | null;
	latitude: number | null;
	logo: string | null;
	longitude: number | null;
	makes: number[];
	maximumCollectionDistance: string | null;
	mileageFrom: number | null;
	mileageTo: number | null;
	name: string;
	notes: string | null;
	paymentSpeed: string | null;
	postcode: string | null;
	priceFrom: number | null;
	priceTo: number | null;
	purchasingSegment: string | null;
	registrationNumber: string | null;
	statusId: number;
	termsOfSale: string;
	usesGoCardless: boolean;
	vatNumber: string | null;
	website: string | null;
}

export enum SubscribedStatus {
	SUBSCRIBED = 'subscribed',
	SUPPRESSED = 'suppressed',
	UNSUBSCRIBED = 'unsubscribed',
}

export interface IDealerContact {
	createdAt: string;
	dealer: {
		dealerStatus: IDealerStatus;
	};
	dealerId: number;
	email: string;
	emailSubscriptions: {
		loginActivatedAt: string;
		nextSaleAuction: SubscribedStatus | boolean;
		otherContactsTransactionalEmails: boolean;
		paymentConfirmation: boolean;
		saleAuction: boolean;
	};
	id: number;
	loginActivatedAt: string | null;
	name: string;
	otpPhone: string | null;
	phone: string | null;
	phoneConfirmedAt: string | null;
	position: string | null;
	primaryContact: boolean;
	updatedAt: string;
}

export type IDealerContactPermissionType = 'invoicing' | 'payments' | 'withdraw_funds' | 'kyc';

export type IDealerContactWithPermissions = Omit<IDealerContact, 'dealer' | 'dealerId' | 'updatedAt' | 'createdAt'> & {
	computedPermissions: IDealerContactPermissionType[];
};

export interface IDealerWithContactsAndPermissions extends IDealer {
	dealerContacts: IDealerContactWithPermissions[];
}

export interface IDealerWithKycDetails extends IDealer {
	kycDetails: IDealerKycDetailsWithAssociates;
}

export type IDealerContactPermissionsMap = Partial<Record<IDealerContactPermissionType, boolean>>;

export interface IDealerContactPermissionsUpdate {
	permissions: {
		allowance: boolean;
		name: IDealerContactPermissionType;
	}[];
}

export interface CreateNoteParam {
	content: string;
	dealerId?: number;
}

export interface GetStatusLogsParams {
	id: IDealer['id'];
	order?: string;
}

export interface IDealerStatusLogs {
	createdAt: string;
	dealerId: number;
	id: number;
	source: string;
	status: string;
	statusId: number;
	user: string;
	userId: number;
}

export interface IAddress {
	addressLine1?: string;
	addressLine2?: string;
	addressLine3?: string;
	city?: string;
	postCode?: string;
}

export interface IAssociateBase {
	dateOfBirth?: string | null;
	emailAddress?: string;
	fullName?: string;
	isDeleted?: boolean;
	isPrimaryContact?: boolean;
	mobilePhone?: string;
	ownership?: number;
	type: ASSOCIATE_TYPE;
}

export interface IAssociateResponse extends IAssociateBase {
	address?: IAddress;
}

export interface IAssociate extends IAssociateBase {
	isDraft?: boolean;
	residentialAddress?: IAddress;
}

export interface IAssociateTypes {
	accountManager: IAssociate[];
	director: IAssociate[];
	shareholder: IAssociate[];
}

interface IDealerKycDealershipDetails {
	companyRegistrationNumber: string;
	dealerId?: number;
	legalName: string;
	registeredAddress: IAddress;
	tradingAddress: IAddress;
	tradingName: string;
	type: string;
}

export interface IDealerKycCompanyDetails extends IDealerKycDealershipDetails {
	associates: IAssociateResponse[];
}

export interface IDealerKycDetailsWithAssociates extends IDealerKycDealershipDetails {
	associates: IAssociateTypes;
	removedAssociates?: IAssociate[];
}

export interface IDealerKycStatusDetails {
	kycStatus: string | null;
}

export interface IKycStatusLogRow extends IKycStatusLog {
	id: number;
}

export interface IKycStatusLog {
	date: string;
	source?: string;
	status: number;
	user: string;
}

export interface IDealerKycStatusLogs {
	statusLogs: IKycStatusLogResponse[];
}

export interface IKycStatusLogResponse {
	Date: string;
	Source?: string;
	Status: number;
	User: string;
}

export interface IKycStatus {
	id: string;
	name: string;
}

export interface IKycStatuses {
	statuses: string[];
}

export interface IDraftAssociate {
	index: number;
	type: ASSOCIATE_TYPE;
	values?: Partial<IAssociate>;
}

export type KycDetailsGetParams = {
	dealerId: number;
};

export type CreateAssociateParams = {
	isUpdated?: boolean;
	type: ASSOCIATE_TYPE;
	values?: IDealerKycDetailsWithAssociates;
};

export enum ASSOCIATE_TYPE {
	ACCOUNT_MANAGER = 'accountManager',
	DIRECTOR = 'director',
	SHAREHOLDER = 'shareholder',
}
