import './side-effects';

import {
	checkSellerEmailFx,
	getAddressesByPostcodeFx,
	getSellerFx,
	updateSellerEmailFx,
	updateSellerFx,
} from './effects';
import { reset } from './events';
import { $addresses, $seller } from './store';

export type { Address, Seller, AddressSuggestion, AddressLineProps, UpdateSellerEmail } from './model';

export const sellerService = {
	$addresses,
	$seller,
	checkSellerEmailFx,
	getAddressesByPostcodeFx,
	getSellerFx,
	reset,
	updateSellerEmailFx,
	updateSellerFx,
};
