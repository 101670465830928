import { notifyManyOnDoneFx } from '@core/utils/side-effects';

import * as effects from './effects';

// Notify on user changes
notifyManyOnDoneFx([
	[effects.createAgentFx.done, 'User created'],
	[effects.updateAgentFx.done, 'User updated'],
	[effects.deleteAgentFx.done, 'User deleted'],
]);
