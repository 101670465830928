import React from 'react';
import { useUnit } from 'effector-react';

import { PAGES } from '@core/constants';
import { contentService } from '@core/services/content';
import { userService } from '@core/services/user';

import { DashboardLink } from './DashboardLink';

export const DashboardLinks = () => {
	const [
		{
			canSeePrioritisedVehicles,
			canSeeStandardVehicles,
			canViewDealers,
			canViewUsers,
			canViewVehicles,
			is: { onlineSalesSpecialist },
			isLeadManager,
			isSeniorLeadManager,
		},
		tradeVehiclesView,
		enableSelfServe,
	] = useUnit([userService.$user, contentService.$tradeVehiclesPage, contentService.$enableSelfServe]);
	const canSeeLMView = (canSeePrioritisedVehicles || isLeadManager) && canViewVehicles;
	const canSeeVehicleView = canSeeStandardVehicles && canViewVehicles;

	return (
		<>
			{onlineSalesSpecialist && (
				<DashboardLink url={enableSelfServe ? PAGES.SELF_SERVE.PATH : PAGES.SELF_SERVE_VEHICLES.PATH}>
					Self-serve vehicles
				</DashboardLink>
			)}
			{tradeVehiclesView.enabled && <DashboardLink url={PAGES.TRADE_VEHICLES.PATH}>Trade vehicles</DashboardLink>}
			{canSeeLMView && (
				<DashboardLink url={PAGES.PRIORITISED_VEHICLES.PATH}>
					{isSeniorLeadManager ? 'Prioritised vehicles' : 'Your vehicles'}
				</DashboardLink>
			)}
			{canSeeVehicleView && <DashboardLink url={PAGES.VEHICLES.PATH}>All vehicles</DashboardLink>}
			{canViewDealers && <DashboardLink url={PAGES.DEALERS.PATH}>Dealers</DashboardLink>}
			{canViewUsers && <DashboardLink url={PAGES.USERS.PATH}>Users</DashboardLink>}
		</>
	);
};
