import React from 'react';
import { AppBar, Toolbar } from '@mui/material';

import { MotorwayLogo } from '@core/components/MotorwayLogo';

import { Profile } from '../Profile';

import { DashboardLinks } from './DashboardLinks';
import { DashboardSearch } from './DashboardSearch';

import styles from './DashboardAppBar.module.scss';

export const DashboardAppBar = ({ compact }: { compact?: boolean }) => (
	<AppBar className={styles.root} position="relative">
		<Toolbar classes={{ root: styles.toolbarRoot }} sx={{ height: compact ? 64 : 80 }}>
			<MotorwayLogo />
			<div className={styles.nav}>
				<DashboardSearch />
				<DashboardLinks />
				<Profile />
			</div>
		</Toolbar>
	</AppBar>
);
