import axios from 'axios';

import * as auth from '@core/services/auth/http';
import * as notifications from '@core/services/notifications/http';
import { captureException, fingerprintUrl } from '@core/utils/exceptions';

import { NOTIFICATION_STATUSES } from '../constants';

import { BEHAVIOUR, BEHAVIOUR_LIST, GLOBAL_ERROR_MESSAGE } from './error-config';
import { ERROR_MAP } from './error-mapping';

/**
 * Error parser - analyze and parse error according configuration
 */
const parseError = (error) => {
	const { config, message: networkMessage, response } = error;
	if (response?.status >= 500 && config) {
		const fingerprint = fingerprintUrl(`${config.baseURL}${config.url}`);
		captureException({
			error,
			fingerprint,
			title: `API Error: [${fingerprint}]:${response?.status}`,
		});
	}
	const {
		data: { message = networkMessage } = {},
		status = networkMessage === 'Network Error' ? 413 : 500,
		url = config?.url,
	} = response || {};

	if (networkMessage === 'Session expired') {
		return {
			behaviour: BEHAVIOUR.LOGOUT,
			message: networkMessage,
			status: 401,
		};
	}
	const helper = ({ codes, paths }) =>
		paths.some((path) => path.test(url)) && codes.some((code) => code.test(`${status}`));

	const { behaviour, message: errMsg, redirect } = BEHAVIOUR_LIST.find(helper) || {};

	const displayMsg = ERROR_MAP[errMsg || message] || message || errMsg;

	return {
		behaviour: behaviour || BEHAVIOUR.ERROR,
		message: displayMsg?.length < 150 ? displayMsg : GLOBAL_ERROR_MESSAGE,
		redirect,
		status,
	};
};

/**
 * Handle error and action according config
 */
export const handleError = (error, config) => {
	if (axios.isCancel(error)) {
		return null;
	}
	const { behaviour, message, redirect, status } = parseError(error);

	const actions = {
		[BEHAVIOUR.LOGOUT]: () => {
			auth.endSession();
			return { message, status };
		},
		[BEHAVIOUR.WARNING]: () => {
			notifications.push({
				message,
				status,
				type: NOTIFICATION_STATUSES.WARNING,
			});
			return { message, status };
		},
		[BEHAVIOUR.IGNORE]: () => ({ message, status }),
		[BEHAVIOUR.ERROR]: () => {
			notifications.push({
				config,
				message,
				redirect,
				status,
				type: NOTIFICATION_STATUSES.ERROR,
			});
			return { message, status };
		},
		[BEHAVIOUR.GLOBAL_ERROR]: () => {
			notifications.push({
				config,
				message,
				status,
				type: NOTIFICATION_STATUSES.GLOBAL_ERROR,
			});
			return { message, status };
		},
	};

	return actions[behaviour]();
};
