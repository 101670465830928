import React from 'react';
import cx from 'classnames';
import { ExpandMore } from '@mui/icons-material';
import { Box, TableCell, TableHead as MuiTableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import styles from './TableHead.module.scss';

export const TableHead = <D extends unknown>(props: any) => {
	const { columns, expandable, onRequestSort, order, orderBy, variant } = props;
	const createSortHandler = (property: keyof D) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};
	return (
		<MuiTableHead>
			<TableRow className={cx(styles.tableRowHeader, styles[variant])}>
				{expandable && variant !== 'slim' && <TableCell className={styles.expandTrigger} />}
				{columns.map((column) => (
					<TableCell
						key={column.key || column.field}
						align={column.align || 'left'}
						className={column.headerClassName}
						padding="normal"
						sortDirection={orderBy === column.field ? order : false}
						sx={{ width: column.width || 'initial' }}
					>
						{column.sortable !== false ? (
							<TableSortLabel
								IconComponent={ExpandMore}
								active={orderBy === column.field}
								className={styles.sortLabel}
								direction={orderBy === column.field ? order : 'desc'}
								onClick={createSortHandler(column.field)}
							>
								{column.headerName}
								{orderBy === column.field ? (
									<Box component="span" sx={visuallyHidden}>
										{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							column.headerName
						)}
					</TableCell>
				))}
				{expandable && variant === 'slim' && <TableCell sx={{ width: 66 }} />}
			</TableRow>
		</MuiTableHead>
	);
};
