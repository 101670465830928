import { trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import type { Effect } from 'effector';

import { GA4_TRACKING_ID } from '@core/app-state';

import type { GAEvent, SnowplowEvent } from './model';
import { domain } from './utils';

/**
 * Set GA user data
 */
export const setConfigFx: Effect<number | string, void> = domain.effect({
	handler: (id) => {
		if (GA4_TRACKING_ID) {
			window.gtag('config', GA4_TRACKING_ID, { user_id: id });
			window.gtag('set', 'user_properties', { userId: id });
		}
	},
});

/**
 * Generic GA event
 */
export const ga4EventFx: Effect<GAEvent, void> = domain.effect({
	handler: ({ data, name }) => {
		if (GA4_TRACKING_ID) {
			window.gtag('event', name, data);
		}
	},
});

/**
 * Generic Snowplow event
 */
export const snowplowEventFx: Effect<SnowplowEvent, void> = domain.effect({
	handler: ({ schema, version = '1-0-0', ...payload }) =>
		trackSelfDescribingEvent({
			event: {
				data: { ...payload },
				schema: `iglu:uk.co.motorway/${schema}/jsonschema/${version}`,
			},
		}),
});
