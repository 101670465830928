import './side-effects';
import './side-effects.archived';

import * as effects from './effects';
import { openArchiveDialog, reset } from './events';
import * as stores from './store';

export { cache } from './cache';
export { navigation } from './navigation';
export * from './model';
export * from './utils';
export * from './constants';

export const photosService = {
	...stores,
	...effects,
	openArchiveDialog,
	reset,
};
