export const allowedRedirections = [
	/\/vehicles.*/,
	/\/prioritised-vehicles.*/,
	/\/dealers.*/,
	/\/users.*/,
	/\/enquiries.*/,
];

/**
 * Parse url and detect redirect url
 */
export const parseRedirectUrl = (location = window.location || {}) => {
	const { href = '', origin = '', search = '' } = location;
	const searchParams = new URLSearchParams(search);
	const isAuthRedirect = searchParams.get('_authorized') === '1';
	const toUrlParam = searchParams.get('url');
	const path = !isAuthRedirect && !toUrlParam ? href.replace(origin, '') : '';
	const toPath = allowedRedirections.some((regexp) => regexp.test(path)) ? path : '';
	const isLoginPage = path.startsWith('/login');

	return { isAuthRedirect, isLoginPage, toPath, toUrlParam };
};

/**
 * Parse current url
 */
export const parseCurrentUrl = (location = window.location || {}) => {
	const { hash = '', pathname = '', search = '' } = location;
	return { hash, pathname, search: search.substring(1) };
};

/**
 * Add auto param to the image url if its missing
 */
export const imageAutoParam = (url: string = '') => {
	const isTwicPics = url?.includes('.twic.pics');
	if (!url || isTwicPics) {
		return url;
	}
	const [, params] = url.split('?') || [];
	const hasAuto = params?.includes('auto');
	return hasAuto ? url : `${url}${params ? '&auto' : '?auto'}`;
};
