import React, { Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { AppLoading } from '@core/components/AppLoading';
import { JSError } from '@core/components/JSError';
import { PAGES } from '@core/constants';
import { cancelRequests } from '@core/http';
import { Dashboard } from '@core/layouts/Dashboard';
import { StaticDashboard } from '@core/layouts/StaticDashboard';
import { PermissionRouteGuard, PrivateRoute, RoleRouteGuard } from '@core/router/PrivateRoute';
import { appService } from '@core/services/app';
import { PERMISSIONS, ROLES } from '@core/utils/permissions';
import { Home } from '@pages/Home';
import { SignIn } from '@pages/SignIn';

import { lazyRetry } from './lazyRetry';

const UsersPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "users-page" */
				/* webpackMode: "lazy" */
				'@pages/Users'
			),
		'@pages/Users',
	),
);

const SelfServeVehiclesPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "self-serve-vehicles-page" */
				/* webpackMode: "lazy" */
				'@pages/SelfServeVehicles'
			).then((module) => ({ default: module.SelfServeVehicles })),
		'@pages/SelfServeVehicles',
	),
);

const SelfServePage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "self-serve" */
				/* webpackMode: "lazy" */
				'@pages/SelfServe'
			).then((module) => ({ default: module.SelfServe })),
		'@pages/SelfServe',
	),
);

const UserPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "user-page" */
				/* webpackMode: "lazy" */
				'@pages/User'
			),
		'@pages/User',
	),
);
const DealersPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "dealers-page" */
				/* webpackMode: "lazy" */
				'@pages/Dealers'
			),
		'@pages/Dealers',
	),
);
const DealerCreatePage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "dealer-create-page" */
				/* webpackMode: "lazy" */
				'@pages/Dealer/DealerCreate'
			),
		'@pages/Dealer/DealerCreate',
	),
);
const DealerEditPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "dealer-edit-page" */
				/* webpackMode: "lazy" */
				'@pages/Dealer/DealerEdit'
			),
		'@pages/Dealer/DealerEdit',
	),
);
const VehiclesPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "vehicles-page" */
				/* webpackMode: "lazy" */
				'@pages/Vehicles'
			),
		'@pages/Vehicles',
	),
);
const PrioritiseVehiclesPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "prioritised-vehicles-page" */
				/* webpackMode: "lazy" */
				'@pages/PrioritisedVehicles'
			),
		'@pages/PrioritisedVehicles',
	),
);
const VehicleEditPage = React.lazy(() =>
	lazyRetry(
		() =>
			import(
				/* webpackChunkName: "vehicle-edit-page" */
				/* webpackMode: "lazy" */
				'@pages/Vehicle'
			),
		'@pages/Vehicle',
	),
);

const TradeVehiclesPage = React.lazy(() =>
	import(
		/* webpackChunkName: "trade-vehicles-page" */
		/* webpackMode: "lazy" */
		/* webpackPrefetch: true */
		/* webpackPreload: true */
		'@pages/TradeVehicles'
	).then((module) => ({ default: module.TradeVehiclesContainer })),
);

const appRouterChildren = [
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.USERS_VIEW]}>
				<Suspense fallback={<AppLoading />}>
					<UsersPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.USERS.ROUTE,
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.USERS_VIEW]}>
				<Suspense fallback={<AppLoading />}>
					<UserPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.USER_CREATE.ROUTE,
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.USERS_VIEW]}>
				<Suspense fallback={<AppLoading />}>
					<UserPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.USER.ROUTE,
	},
	{
		element: <Home />,
		path: '',
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.DEALERS_VIEW]}>
				<Suspense fallback={<AppLoading />}>
					<DealersPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.DEALERS.ROUTE,
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.DEALERS_VIEW, PERMISSIONS.DEALERS_ADD]}>
				<Suspense fallback={<AppLoading />}>
					<DealerCreatePage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.DEALER_CREATE.ROUTE,
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.DEALERS_VIEW]}>
				<Suspense fallback={<AppLoading />}>
					<DealerEditPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.DEALER.ROUTE,
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.VEHICLES_VIEW, PERMISSIONS.VEHICLE_LIST_STANDARD]}>
				<Suspense fallback={<AppLoading />}>
					<VehiclesPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.VEHICLES.ROUTE,
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.VEHICLES_VIEW]}>
				<Suspense fallback={<AppLoading />}>
					<VehicleEditPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.VEHICLE_EDIT.ROUTE,
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.VEHICLES_VIEW]}>
				<Suspense fallback={<AppLoading />}>
					<PrioritiseVehiclesPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.PRIORITISED_VEHICLES.ROUTE,
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.VEHICLES_VIEW]}>
				<Suspense fallback={<AppLoading />}>
					<VehicleEditPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.PRIORITISED_VEHICLE_EDIT.ROUTE,
	},
	{
		element: (
			<RoleRouteGuard roles={[ROLES.ONLINE_SALES_SPECIALIST]}>
				<Suspense fallback={<AppLoading />}>
					<SelfServeVehiclesPage />
				</Suspense>
			</RoleRouteGuard>
		),
		path: PAGES.SELF_SERVE_VEHICLES.ROUTE,
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.VEHICLES_VIEW]}>
				<Suspense fallback={<AppLoading />}>
					<TradeVehiclesPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.TRADE_VEHICLES.ROUTE,
	},
	{
		element: (
			<PermissionRouteGuard permissions={[PERMISSIONS.VEHICLES_VIEW]}>
				<Suspense fallback={<AppLoading />}>
					<VehicleEditPage />
				</Suspense>
			</PermissionRouteGuard>
		),
		path: PAGES.TRADE_VEHICLES_EDIT.ROUTE,
	},
];

const appRouterSelfServeChildren = [
	{
		element: (
			<RoleRouteGuard roles={[ROLES.ONLINE_SALES_SPECIALIST]}>
				<Suspense fallback={<AppLoading />}>
					<SelfServePage />
				</Suspense>
			</RoleRouteGuard>
		),
		path: PAGES.SELF_SERVE.ROUTE,
	},
];

export const APP_ROUTER = createBrowserRouter([
	{
		children: appRouterSelfServeChildren,
		element: <PrivateRoute element={<StaticDashboard />} />,
		errorElement: <JSError />,
		path: PAGES.SELF_SERVE.ROUTE,
	},
	{
		children: appRouterChildren,
		element: <PrivateRoute element={<Dashboard />} />,
		errorElement: <JSError />,
		path: '/',
	},
	{
		element: <SignIn />,
		path: PAGES.LOGIN.PATH,
	},
	{
		element: <PrivateRoute element={<Home />} />,
		path: '*',
	},
]);

let prevLocation;

APP_ROUTER.subscribe(({ location }) => {
	const { hash, pathname, search } = location;
	prevLocation?.pathname && !pathname.startsWith(prevLocation?.pathname) && cancelRequests();
	appService.setLocation({ hash, pathname, search: search.substring(1) });
	prevLocation = location;
});
