import { domain as globalDomain } from '@core/domain';
import { initials } from '@core/utils/formatter';

import type { IListVehicle } from '../vehicles/internal';

import type { IPrioritisedVehicle } from './model';

export const domain = globalDomain.domain('@prioritisedVehiclesService');

export const mapVehiclesToList = (vehicles: IListVehicle[]): IPrioritisedVehicle[] =>
	vehicles.map((vehicle) => {
		const notes = [...(vehicle.notes || [])].sort(
			(a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
		);
		return {
			...vehicle,
			closingTime: vehicle?.closingBid?.closingTime,
			leadManagerName: initials(vehicle.leadManager?.name ?? ''),
			make: vehicle.spec.make,
			mobile: vehicle.seller.mobile,
			name: vehicle.seller.name,
			notes,
			profilePicture: '',
			'state.name': vehicle.stateId,
		};
	});
