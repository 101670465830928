import React, { useEffect, useState } from 'react';

import { ISearchContext } from './Search.model';

const SearchContext = React.createContext<ISearchContext>({} as ISearchContext);
SearchContext.displayName = 'SearchContext';

const SearchContextProvider = ({ children, value: v }) => {
	const [state, setState] = useState(v);

	useEffect(() => {
		setState(v);
	}, [v]);

	return <SearchContext.Provider value={state}>{children}</SearchContext.Provider>;
};

export { SearchContextProvider, SearchContext };
