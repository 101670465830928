import { sample } from 'effector';

import * as app from '../app/internal';

import * as events from './events';

/**
 * Check initial auth state
 */
sample({
	clock: app.init,
	filter: () => window.location.search.includes('_authorized=1'),
	target: events.setSession,
});
