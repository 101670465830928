import type { Store } from 'effector';

import { persistStore } from '@core/utils/store';

import * as events from './events';
import { domain } from './utils';

/**
 * Create draft note store
 */
export const $draft: Store<string> = persistStore(domain.store(''), { key: 'draft.note', sync: true }).on(
	events.setDraft,
	(_, note) => note,
);
