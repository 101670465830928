import React, { useContext } from 'react';
import cx from 'classnames';

import type { ISearchVehicle } from '@core/services/vehicles';

import { MAX_RESULTS } from '../Search.helpers';
import { SearchContext } from '../SearchContext';

import { Message, NothingFound } from './SearchComponents';
import { SearchLoading } from './SearchLoading';
import { SearchTable } from './SearchTable';

import styles from './SearchCommon.module.scss';

const getView = (isLoading: boolean, vehicles: ISearchVehicle[] | null) => {
	if (isLoading) {
		return <SearchLoading />;
	}
	if (vehicles?.length === 0) {
		return <NothingFound />;
	}

	if (vehicles?.length) {
		return (
			<div>
				{vehicles?.length >= MAX_RESULTS - 1 ? (
					<Message>Couldn&apos;t display all results, please refine your criteria and try again</Message>
				) : null}
				<SearchTable vehicles={vehicles} />
			</div>
		);
	}
	return null;
};

const SearchResults = ({ isLoading, vehicles }) => {
	const { isOpen } = useContext(SearchContext);

	const fadeDirection = isOpen && isLoading && vehicles;

	return (
		<div className={cx(styles.resultsWrapper, styles[`resultsWrapper${fadeDirection}`])}>
			{getView(isLoading, vehicles)}
		</div>
	);
};

export default SearchResults;
