import type { Cache, CacheTTL } from './model';

const cache: Cache = {};
const cacheTTL: CacheTTL = {};

/**
 * API data cache mechanism
 */
export const apiCache = {
	/**
	 * Clear cached data
	 */
	clear() {
		for (const prop of Object.getOwnPropertyNames(cacheTTL)) {
			delete cache[prop];
			delete cacheTTL[prop];
		}
	},
	/**
	 * Return cached item if TTL is valid or delete
	 */
	get(url: string) {
		const valid = cacheTTL[url] > Date.now();
		if (!valid) {
			delete cache[url];
			delete cacheTTL[url];
		}
		return cache[url];
	},
	/**
	 * Remove cached item
	 */
	remove(url: string) {
		delete cache[url];
		delete cacheTTL[url];
	},
	/**
	 * Set item to the cache
	 */
	set(url: string, data: any, ttl: number) {
		cacheTTL[url] = Date.now() + ttl * 1000;
		cache[url] = data;
	},
};
