import React from 'react';
import cx from 'classnames';
import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog as MUIDialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { isString } from 'lodash-es';

import { Button } from '@THC/components/Button';

import type { DialogProps } from './Dialog.model';

import styles from './Dialog.module.scss';

export const Dialog = ({
	actions,
	actionsVertical,
	busy,
	cancelButtonProps,
	cancelIcon,
	cancelLabel,
	children,
	classNames,
	confirmButtonsProps,
	confirmIcon,
	confirmLabel,
	description,
	disabled,
	hideCancelButton,
	hideCloseButton,
	hideConfirmButton,
	onCancel,
	onClose,
	onConfirm,
	open,
	title,
	...rest
}: DialogProps) => {
	const buttons = [
		!hideCancelButton && (
			<Button
				key="dialog-cancel-button"
				fullWidth
				reverse
				data-test="dialog-cancel-button"
				icon={cancelIcon}
				label={cancelLabel}
				onClick={() => onCancel?.()}
				variant="secondary"
				{...cancelButtonProps}
			/>
		),
		!hideConfirmButton && (
			<Button
				key="dialog-confirm-button"
				fullWidth
				reverse
				className={classNames?.confirm}
				data-test="dialog-confirm-button"
				disabled={disabled || busy}
				icon={confirmIcon}
				label={confirmLabel}
				loading={busy}
				onClick={() => onConfirm?.()}
				variant="primary"
				{...confirmButtonsProps}
			/>
		),
	].filter((c) => c);

	return (
		<MUIDialog
			aria-describedby={description}
			aria-labelledby={isString(title) ? title : ''}
			data-test="dialog-box"
			onClose={onClose}
			open={open}
			scroll="body"
			{...rest}
		>
			{!hideCloseButton && (
				<IconButton
					aria-label="Close"
					className={cx(styles.closeButton, classNames?.icon)}
					data-test="dialog-close-button"
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			)}
			{title && <DialogTitle data-test="dialog-title">{title}</DialogTitle>}
			<DialogContent className={classNames?.content} data-test="dialog-content">
				{children}
			</DialogContent>
			{actions || (
				<DialogActions
					classes={{ root: cx(styles.actions, { [styles.vertical]: actionsVertical }, classNames?.actions) }}
				>
					{actionsVertical ? [...buttons].reverse() : buttons}
				</DialogActions>
			)}
		</MUIDialog>
	);
};

Dialog.defaultProps = {
	cancelLabel: 'Cancel',
	classNames: {},
	confirmButtonsProps: {},
	confirmLabel: 'Ok',
	hideCancelButton: false,
	hideCloseButton: false,
};
