import * as effects from './effects.automated';
import * as events from './events';
import type { CalculatePrepCostResponse, PrepCostResponse } from './model';
import { domain } from './utils';

/**
 * Auto prep cost store
 */
export const $prepCost = domain
	.store<PrepCostResponse['data']>([])
	.on(effects.getPrepCostFX.doneData, (_, { data }) => data)
	.reset([events.reset]);

/**
 * Calculated auto prep cost store
 */
export const $calculated = domain
	.store<CalculatePrepCostResponse['data']>([])
	.on(effects.calculatePrepCostFX.doneData, (_, { data }) => data)
	.reset([events.reset]);
