/* eslint-disable no-underscore-dangle */
import type { Effect } from 'effector';

import type { RequestConfig } from '@core/http';
import { HEADER_ENABLE_SEARCH_SERVICE, request } from '@core/http';
import type { ListResponse } from '@core/model';
import { pickAllowedParams } from '@core/utils/api';

import { ALLOWED_VEHICLES_LIST_PARAMS, SORT_PARAMS_MAP } from './constants';
import type { GetVehiclesParams, IListVehicle, ISearchVehicle } from './model';
import { domain } from './utils';

const mapSortParams = ({ _sort = '', ...rest }: Partial<GetVehiclesParams>) => ({
	...rest,
	_sort: SORT_PARAMS_MAP[_sort] || _sort,
});

/**
 * Get search vehicle info
 */
export const getSearchVehiclesFx: Effect<{ text: string }, ListResponse<ISearchVehicle>> = domain.effect({
	handler: ({ text }) =>
		request({
			headers: {
				...HEADER_ENABLE_SEARCH_SERVICE,
			},
			method: 'get',
			params: {
				_end: 10000,
				_order: 'ASC',
				_sort: 'updated_at',
				_start: 0,
				searchTerm: text,
			},
			url: '/dealership/vehicles/search',
		} as RequestConfig),
});

/**
 * Get vehicles
 */
export const getVehiclesFx: Effect<Partial<GetVehiclesParams>, ListResponse<IListVehicle>> = domain.effect({
	handler: (params) =>
		request({
			headers: {
				...HEADER_ENABLE_SEARCH_SERVICE,
			},
			method: 'get',
			params: mapSortParams(pickAllowedParams(params, ALLOWED_VEHICLES_LIST_PARAMS)),
			url: '/dealership/vehicles',
		} as RequestConfig),
});
