import { filter } from 'lodash-es';

import { SLUGS } from '@motorway/mw-enquiry-states';

import { BID_TYPES, TIME_TO_CLOSE_ALLOWED_STATES } from '@core/constants/vehiclesConsts';
import type { EnquiryBid, IVehicle, VehicleState } from '@core/services/vehicle';
import type { IListVehicle } from '@core/services/vehicles';
import { BIDS_PERMISSIONS, DOCUMENTS_PERMISSIONS, VEHICLES_PERMISSIONS } from '@core/utils/permissions';

export const hasUserPermissions = (permissions: string[], userPermissions: string[]) =>
	permissions.every((p) => userPermissions.includes(p));

export const getVehicleState = (vehicle: IVehicle | IListVehicle): VehicleState => vehicle?.state;

export const hasVehicleState = (vehicle: IVehicle | IListVehicle, slugs: string[] = []): boolean =>
	slugs.includes(getVehicleState(vehicle)?.slug);

export const hasVehicleCategory = (vehicle: IVehicle | IListVehicle, categories: string[] = []) =>
	categories.includes(getVehicleState(vehicle)?.category);

export const hasVehicleTimeToCloseAllowedState = (vehicle: IVehicle | IListVehicle) =>
	hasVehicleState(vehicle, TIME_TO_CLOSE_ALLOWED_STATES);

export const canSeeWhenSaleActive = (userPermissions: string[], vehicle: IListVehicle) =>
	hasVehicleState(vehicle, [SLUGS.SALE_ACTIVE])
		? hasUserPermissions([BIDS_PERMISSIONS.ALL_BIDS_DURING_SALE], userPermissions)
		: true;

export const hasBidType = (bid: EnquiryBid, type: string) => bid?.type === type;

export const getBidState = (bid: EnquiryBid) => bid?.state;

export const hasBidState = (bid: EnquiryBid, states: string[]) => states.includes(getBidState(bid));

export const hasExistingBidForDealer = (currentBid: EnquiryBid, bids: EnquiryBid[], statuses: string[] = []) => {
	let filteredBids: EnquiryBid[] = filter(bids, (bid: EnquiryBid) =>
		statuses.length ? statuses.includes(bid.state) : true,
	);
	filteredBids = filteredBids.filter((bid: EnquiryBid) => bid.id !== currentBid.id);
	return Boolean(filteredBids.find((bid) => bid?.dealerContact?.dealerId === currentBid?.dealerContact?.dealerId));
};

export const getBidTypeForNewVehicle = (vehicle: IVehicle) => {
	if (hasVehicleState(vehicle, [SLUGS.SALE_ACTIVE])) {
		return BID_TYPES.PROXY;
	}
	return BID_TYPES.OFFER;
};

export const canMoveToRestrictedStatuses = (userPermissions: string[]) =>
	hasUserPermissions([VEHICLES_PERMISSIONS.MODIFY_RESTRICTED_VEHICLE_STATUS], userPermissions);

export const hasDocumentReviewPermission = (userPermissions: string[]) =>
	hasUserPermissions([DOCUMENTS_PERMISSIONS.DOCUMENTS_REVIEW], userPermissions);

export const hasVehiclePaymentViewPermission = (userPermissions: string[]) =>
	hasUserPermissions([VEHICLES_PERMISSIONS.VEHICLE_PAYMENT_VIEW], userPermissions);

export const hasMarkAsManualPaymentPermission = (userPermissions: string[]) =>
	hasUserPermissions([VEHICLES_PERMISSIONS.MARK_AS_MANUAL_PAYMENT], userPermissions);

export const hasEditPriceAmendmentForPayments = (userPermissions: string[]) =>
	hasUserPermissions([VEHICLES_PERMISSIONS.EDIT_PRICE_AMENDMENT_FOR_PAYMENTS], userPermissions);
