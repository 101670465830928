import { lowerCase, startCase } from 'lodash-es';

import { domain as globalDomain } from '@core/domain';

import type { Address, AddressLineProps, AddressSuggestion } from './model';

export const domain = globalDomain.domain('@sellerService');

export const formatAddress = ({ address1, address2, address3, city } = {} as AddressLineProps) =>
	[address1, address2, address3, city].filter(Boolean).join(', ');

export const mapAddressSuggestions = (data: Address[]): AddressSuggestion[] =>
	data.map(({ id, line_1, line_2, line_3, post_town }) => {
		const address = {
			address1: line_1,
			address2: line_2,
			address3: line_3,
			city: startCase(lowerCase(post_town)),
			id,
		};
		return { ...address, name: formatAddress(address) };
	});
