import { find } from 'lodash-es';

import { NOTIFICATION_STATUSES } from '@core/constants';
import { domain as globalDomain } from '@core/domain';

import type { AppNotification } from './model';

export const domain = globalDomain.domain('@notificationService');

interface Options {
	maxWait?: number;
	queueStrategy?: (queue: any[], item: any) => any[];
}

/**
 * Debounce and keep historical arguments
 */
export const debounceQueued = <T>(wait: number, { maxWait, queueStrategy }: Options = {}) => {
	let timer: number | null = null;
	let maxTimer: number | null = null;
	let items: any[] = [];

	const processDebounce = (fn) => {
		timer = null;
		items.length && fn(items);
		items = [];
	};

	const processMaxWait = (fn) => {
		timer = null;
		maxTimer = null;
		items.length && fn(items);
		items = [];
	};

	return ({ fn, item }: { fn: any; item: T }) => {
		items = queueStrategy ? queueStrategy(items, item) : [...items, item];
		!timer && (timer = window.setTimeout(() => processDebounce(fn), wait));
		maxWait && !maxTimer && (maxTimer = window.setTimeout(() => processMaxWait(fn), maxWait));
	};
};

const ORDER = [
	NOTIFICATION_STATUSES.GLOBAL_ERROR,
	NOTIFICATION_STATUSES.ERROR,
	NOTIFICATION_STATUSES.WARNING,
	NOTIFICATION_STATUSES.SUCCESS,
	NOTIFICATION_STATUSES.INFO,
];

/**
 * Merge notifications into queue
 */
export const mergeNotifications = (queue: AppNotification[], item: AppNotification) => {
	const exist = find(queue, { type: item.type });
	return (
		exist
			? queue.map((it) => (it.type === exist.type ? { ...it, message: `${it.message}\n${item.message}` } : it))
			: [...queue, item]
	).sort((a, b) => ORDER.indexOf(a.type) - ORDER.indexOf(b.type));
};
