import * as store from './dealers.store';
import * as effects from './effects';

export * from './constants';

export * from './model';

export const dealersService = {
	...store,
	...effects,
};
