import { attachReduxDevTools } from '@effector/redux-devtools-adapter';
import { createDomain } from 'effector';

export const domain = createDomain('@app');

process.env.NODE_ENV !== 'production' &&
	attachReduxDevTools({
		name: 'Agent Dashboard',
		stateTab: false,
		trace: true,
	});
