import type { Store } from 'effector';
import { combine, restore } from 'effector';
import { sortBy } from 'lodash-es';

import { sortOrderFieldsPhotos } from '@core/constants/photoSections';
import { getImgIxUrlByMeta } from '@core/utils/formatter';
import { replaceFallbackUrls } from '@core/utils/imgIxToTP';
import type { PhotoDoc } from '@core/utils/photo-docs.model';

import * as app from '../app/internal';
import * as content from '../content/internal';

import * as effects from './effects';
import * as events from './events';
import { domain, getFirstMLStatus } from './utils';

const filterPhotos = (photo: PhotoDoc) => photo.status !== 'archived';

const mapPhotos = (photo: PhotoDoc) => ({
	...photo,
	legacyUrl: getImgIxUrlByMeta({ meta: photo.meta, url: photo.url }) || null,
	thumbnailUrl: photo?.presets?.find((it) => it.name === 'thumbnail')?.url || null,
});

const convertPhotosToMap = (photos: PhotoDoc[]) =>
	photos.reduce((acc, photo) => {
		acc[photo.kind] = (acc[photo.kind] || []).concat(photo);
		return acc;
	}, {});

/**
 * Create photos store
 */
export const $photosRaw = domain
	.store<PhotoDoc[]>([])
	.on(effects.getPhotosEx.doneData, (_, photos) => photos.filter(filterPhotos).map(mapPhotos))
	.on(effects.markAsServiceHistoryPhotoFx.done, (photos, payload) =>
		photos.map((photo) =>
			payload.params === photo.id ? { ...photo, autoAssessStatus: payload.result.status } : photo,
		),
	)
	.reset(app.reset, events.reset);

export const $photos = combine($photosRaw, content.$imgIxFallback, (photos, { enabled }) =>
	enabled ? replaceFallbackUrls(photos) : photos,
);

export const $archivedPhotos = domain
	.store<PhotoDoc[]>([])
	.on(effects.getPhotosEx.doneData, (_, photos) =>
		photos.filter((photo) => photo.status === 'archived' || photo.hasArchivedAlts).map(mapPhotos),
	)
	.reset(app.reset, events.reset);

export const $archivedModalPhotos = domain
	.store<PhotoDoc[]>([])
	.on(effects.getArchivedPhotosFx.doneData, (_, { data }) => data.map(mapPhotos))
	.reset(app.reset, events.reset);

export const $archivedPhotosMap: Store<Record<string, PhotoDoc[]>> = $archivedPhotos.map(convertPhotosToMap);

export const $archivedModalPhotosMap: Store<Record<string, PhotoDoc[]>> = $archivedModalPhotos.map(convertPhotosToMap);

export const $archivedPhotosTrigger = restore(events.openArchiveDialog, null);

export const $serviceHistoryWrongPhotos: Store<Record<PhotoDoc['id'], string>> = combine(
	$photos,
	content.$config,
	(photos, config) => {
		const serviceHistoryPhotos = photos.filter((photo) => photo.kind === 'docs_service_history');
		const statuses = config?.enquiry?.photoMLStatuses ?? {};
		return serviceHistoryPhotos.reduce((acc, photo) => {
			acc[photo.id] = photo.autoAssessStatus ? getFirstMLStatus(statuses, photo.autoAssessStatus) : null;
			return acc;
		}, {});
	},
);

export const $galleryPhotos = $photos.map((photos) =>
	sortBy(photos, (photo) => sortOrderFieldsPhotos.indexOf(photo.kind)),
);
