import React, { useState } from 'react';
import sample from 'lodash-es/sample';

import { Button } from '@THC/components/Button';

import { ReactComponent as OneLoginLogo } from '../assets/onelogin-logo.svg';
import { ReactComponent as WhiteChevronBackground } from '../assets/white-chevron-background.svg';
import { JOKES } from '../Jokes/jokes';
import { MotorwayChristmasLogo } from '../MotorwayXmasLogo';

import type { ChristmasLoginProps } from './ChristmasLogin.model';

import styles from './ChristmasLogin.module.scss';

export const ChristmasLogin = ({ oneLoginSignInUrl }: ChristmasLoginProps) => {
	const [path, setPath] = useState('question');
	const [joke] = useState(sample(JOKES));

	const logIn = () => {
		setPath('answer');
		setTimeout(() => {
			window.open(oneLoginSignInUrl, '_self');
		}, 3000);
	};

	return (
		<div className={styles.wrapper} data-test="christmas-login-page">
			<div className={styles.loginWrapper}>
				<div className={styles.whiteBackground}>
					<WhiteChevronBackground />
				</div>
				<div className={styles.login}>
					<div className={styles.logo}>
						<MotorwayChristmasLogo />
						<h1 className={styles.title}>Dashboard</h1>
					</div>
					<div className={styles.joke}>{joke?.[path]}</div>
					<div className={styles.buttonLogin}>
						<Button
							fullWidth
							data-test="oneLoginButton"
							icon="chevron"
							label={
								<div className={styles.buttonLabel}>
									Sign in with
									<div className={styles.oneLoginLogo}>
										<OneLoginLogo />
									</div>
								</div>
							}
							loading={path === 'answer'}
							onClick={logIn}
							size="large"
						/>
					</div>
				</div>
			</div>
			<div className={styles.yellowBackgound} />
		</div>
	);
};
