import { newTracker, trackPageView, trackSelfDescribingEvent } from '@snowplow/browser-tracker';

import { IS_PROD } from '@core/app-state';

export const initSnowplow = () => {
	const SNOWPLOW_COLLECTOR_URL = IS_PROD ? 'https://laptime.motorway.co.uk' : 'https://laptime.stage.motorway.co.uk';

	newTracker('sp1', SNOWPLOW_COLLECTOR_URL, {
		appId: `agent-website-${IS_PROD ? 'prod' : 'test'}`,
		cookieDomain: '.dashboard.motorway.co.uk',
		cookieSameSite: 'Lax',
		discoverRootDomain: false,
		onSessionUpdateCallback() {
			setTimeout(() => {
				trackSelfDescribingEvent({
					event: {
						data: {},
						schema: 'iglu:uk.co.motorway/new_session_start/jsonschema/1-0-0',
					},
				});
			}, 0);
		},
		plugins: [],
	});

	trackPageView();
};
