import type { ListResponse } from '@core/model';

import * as app from '../app/internal';

import * as effects from './effects';
import type { ISearchVehicle } from './model';
import { domain } from './utils';

export const $search = domain
	.store<ListResponse<ISearchVehicle>>({ list: [], total: 0 })
	.on(effects.getSearchVehiclesFx.done, (_, { params: { text }, result: { list, total } }) => ({
		list: list.map((item) => ({ ...item, searchTerm: text })),
		total,
	}))
	.reset(app.reset);
