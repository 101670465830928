import { PAGES } from '@core/constants';
import { createPageState } from '@core/utils/pageState';

import * as users from '../agents/internal';
import * as app from '../app/internal';

import * as effects from './effects';
import { domain as serviceDomain } from './utils';

const domain = serviceDomain.domain('@page/vehicles');

/**
 * User page stores/events
 */
export const { $active, $ready, init, reset } = createPageState({
	domain,
	effects: [effects.getVehicleStatusesFx, users.getAllAgentsFx, app.getRolesFx],
	pathNames: [
		PAGES.ENQUIRY_EDIT.PATTERN,
		PAGES.PRIORITISED_ENQUIRY_EDIT.PATTERN,
		PAGES.VEHICLE_EDIT.PATTERN,
		PAGES.PRIORITISED_VEHICLE_EDIT.PATTERN,
		PAGES.TRADE_VEHICLES_EDIT.PATTERN,
	],
});
