import { JSEND_PAGINATION_PARAMS } from '@core/constants';

export const ALLOWED_USERS_LIST_PARAMS = [
	...JSEND_PAGINATION_PARAMS,
	'isDeleted',
	'keyword',
	'permissionId',
	'roleId',
	'include',
	'organisation',
];
