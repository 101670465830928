import type { RumEvent } from '@datadog/browser-rum';
import { datadogRum } from '@datadog/browser-rum';

import { ALLOWED_DD_TRACE_URLS, ENVIRONMENT, RELEASE_VERSION } from '@core/app-state';

export const initDatadogRum = () => {
	if (ENVIRONMENT && ENVIRONMENT !== 'development') {
		datadogRum.init({
			allowedTracingUrls: ALLOWED_DD_TRACE_URLS,
			applicationId: '9a5544c3-d495-4efa-93a3-559db2a239ad',
			// eslint-disable-next-line
			beforeSend: (event: RumEvent) => {
				if (event.type === 'error' && event.error.message.includes('ResizeObserver')) {
					return false;
				}
				if (
					event.type === 'error' &&
					[
						"Cannot read properties of undefined (reading 'width')",
						"Cannot read properties of undefined (reading 'x')",
					].includes(event.error.message) &&
					event.error.stack?.includes('js/photo-editor-vendor.')
				) {
					return false;
				}
				return !(event.type === 'error' && ['Document is not focused.'].includes(event.error.message));
			},
			clientToken: 'pub6fffc21798bb0828bd8ed8402ca6b1bc',
			defaultPrivacyLevel: 'mask-user-input',
			env: ENVIRONMENT,
			service: 'agent-dashboard',
			sessionReplaySampleRate: 100,
			sessionSampleRate: 100,
			site: 'datadoghq.eu',
			trackLongTasks: true,
			trackResources: true,
			trackUserInteractions: true,
			version: RELEASE_VERSION,
		});
	}
};
