import type { AxiosError } from 'axios';
import type { Effect } from 'effector';

import type { RequestConfig } from '@core/http';
import { request } from '@core/http';
import type { ListResponse } from '@core/model';
import { pickAllowedParams } from '@core/utils/api';
import type { EnhancedEffect } from '@core/utils/effects';
import { enhancedEffect } from '@core/utils/effects';

import type { EnquiryBid } from '../vehicle';

import { ALLOWED_BIDS_PARAMS, DEFAULT_BIDS_PARAMS } from './constants';
import type {
	AddBidParams,
	AddBidResponse,
	EnquiryBidsParams,
	UpdateBidStateParams,
	UpdateBidStateResponse,
} from './model';
import { domain } from './utils';

/**
 * Get vehicle bids
 */
export const getBidsEx: EnhancedEffect<EnquiryBidsParams, ListResponse<EnquiryBid>, AxiosError> = enhancedEffect({
	domain,
	handler: (params) =>
		request({
			method: 'get',
			params: pickAllowedParams({ ...DEFAULT_BIDS_PARAMS, ...params }, ALLOWED_BIDS_PARAMS),
			url: '/dealership/bids',
		} as RequestConfig),
	name: 'getBidsFx',
});

/**
 * Update bid state
 */
export const updateBidStateFx: Effect<UpdateBidStateParams, UpdateBidStateResponse> = domain.effect({
	handler: ({ id, state }) =>
		request({
			method: 'post',
			url: `/dealership/bids/${id}/${state}`,
		} as RequestConfig),
});

/**
 * Add bid
 */
export const addBidFx: Effect<AddBidParams, AddBidResponse> = domain.effect({
	handler: (data) =>
		request({
			data,
			method: 'post',
			url: '/dealership/bids',
		} as RequestConfig),
});
