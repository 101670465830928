import { APP_REQUEST_HEADERS, PHOTO_UPLOADER_URL } from '@core/app-state';
import { RequestConfig } from '@core/http';
import { userService } from '@core/services/user';

export const objToFormData = (dataset) =>
	dataset.reduce((formData, args) => {
		formData.append(...args);
		return formData;
	}, new FormData());

type PhotoDocsUpdateRequestConfigParams = {
	file: any;
	modified: any;
	pageNumber?: number;
	photo: any;
	vehicleId: any;
};

export const photoDocsUpdateRequestConfig = ({
	file,
	modified,
	pageNumber,
	photo,
	vehicleId,
}: PhotoDocsUpdateRequestConfigParams) => {
	const photoType = modified ? 'modified' : 'default';
	const { auth_token } = userService.$info.getState();
	const formDataArray = [
		['vehicleId', vehicleId],
		['enquiryId', vehicleId],
		['kind', photo.name],
		['file', file],
		['name', `${vehicleId}-${photo.name}-${photoType}-${Date.now()}.${file.name.split('.').pop().toLowerCase()}`],
		['type', photoType],
		...(modified ? [['id', photo.id]] : []),
	];

	if (pageNumber) {
		formDataArray.push(['pageNumber', pageNumber]);
	}

	const data = objToFormData(formDataArray);

	return {
		baseURL: PHOTO_UPLOADER_URL,
		data,
		headers: {
			'Content-type': 'multipart/form-data',
			'request-id': [APP_REQUEST_HEADERS, vehicleId, photo.name, Date.now()].join('-'),
			'x-access-token': auth_token,
		},
		method: 'post',
	} as RequestConfig;
};
