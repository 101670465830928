import type { AxiosError } from 'axios';
import type { Effect } from 'effector';

import type { JSENDAPIResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';

import type { Agent, UpdateAgentPictureRequest } from './model';
import { domain } from './utils';

/**
 * Get agent by id
 */
export const getAgentFx: Effect<string, JSENDAPIResponse<Agent>> = domain.effect({
	handler: (userId) =>
		request({
			method: 'get',
			params: {
				include: 'assignments',
			},
			url: `agent/${userId}`,
		} as RequestConfig),
});

/**
 * Create agent
 */
export const createAgentFx: Effect<Partial<Agent>, Agent> = domain.effect({
	handler: (body) =>
		request({
			data: body,
			method: 'POST',
			url: '/agent/createAgent',
		} as RequestConfig),
});

/**
 * Update agent
 */
export const updateAgentFx: Effect<Partial<Agent>, JSENDAPIResponse<Agent>, AxiosError> = domain.effect({
	handler: (data) =>
		request({
			data,
			method: 'post',
			url: `/agent/${data.id}/update`,
		} as RequestConfig),
});

/**
 * Update agent profile picture
 */
export const updateAgentPictureFx: Effect<
	UpdateAgentPictureRequest,
	JSENDAPIResponse<Agent>,
	AxiosError
> = domain.effect({
	handler: ({ id, ...data }) =>
		request({
			data,
			method: 'post',
			url: `/agent/${id}/profilePicture/update`,
		} as RequestConfig),
});

/**
 * Delete agent by id
 */
export const deleteAgentFx: Effect<string, Agent> = domain.effect({
	handler: (userId) =>
		request({
			method: 'post',
			url: `/agent/${userId}/delete`,
		} as RequestConfig),
});
