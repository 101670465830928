import type { ILocation } from './model';
import { domain } from './utils';

// App init
export const init = domain.event();
// App reset
export const reset = domain.event();

// Set modal status open for the given key
export const openModal = domain.event<string>();
// Set modal status closed for the given key
export const closeModal = domain.event<string>();
// Close all modals
export const closeAllModals = domain.event();
// Reset redirection path
export const setRedirectLocation = domain.event();
// Reset last path
export const resetRedirectLocation = domain.event();
// Set current location
export const setLocation = domain.event<ILocation>();
// Reset filters
export const resetFilters = domain.event();
