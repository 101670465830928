import { domain as globalDomain } from '@core/domain';

export const domain = globalDomain.domain('@contentService');

export const flagEnabledAndUser = (userId: number, config, prop: string = 'users'): boolean => {
	const { enabled = false, [prop]: users = [] } = config || {};
	return enabled && users.includes(userId);
};

export const flagEnabledOrUser = (userId: number, config) => {
	const { enabled = false, users = [] } = config || {};
	return enabled || users.includes(userId);
};

export const flagEnabledIfOrUser = (userId: number, config, prop: string = 'users'): boolean => {
	const { enabled = false, [prop]: users = [] } = config || {};

	if (users?.length) {
		return enabled && users.includes(userId);
	}

	return enabled;
};

/**
 * Flag to control per user or role
 */
export const flagEnabledOrUserOrRole = (userId: number, roleName: string, config, prop: string = 'users'): boolean => {
	const { enabled = false, roles = [], [prop]: users = [] } = config || {};

	if (users?.length || roles?.length) {
		return enabled && (users?.includes(userId) || roles?.includes(roleName));
	}

	return enabled;
};
