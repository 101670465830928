import { getSearchVehiclesFx, getVehiclesFx } from './effects';
import { getVehicles } from './events';
import { $search } from './search.store';
import { $vehicles } from './vehicles.store';

export * from './constants';
export * from './model';

export const vehiclesService = {
	$search,
	$vehicles,
	getSearchVehiclesFx,
	getVehicles,
	getVehiclesFx,
};
