import React, { useState } from 'react';
import { Skeleton, Table as MuiTable, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';

import type { BaseRow, EnhancedTableProps, Order } from './Table.model';
import { TableHead } from './TableHead';
import { ExpandableRow, NoRows, Row } from './TableRows';

import styles from './Table.module.scss';

export function Table<D extends BaseRow>({
	className,
	columns,
	expandedState = {},
	expandedView,
	hideHeader = false,
	loader,
	loading,
	noOverlay,
	onChangeSortModel,
	onRowClick,
	rows,
	sortModel,
	variant = 'regular',
	...rest
}: EnhancedTableProps<D>) {
	const [order, setOrder] = useState<Order>(sortModel?.sort?.toLowerCase() as Order);
	const [orderBy, setOrderBy] = useState<keyof D>(sortModel?.field as keyof D);
	const [expandedRows, setExpandedRow] = useState(expandedState);

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof D) => {
		const isAsc = orderBy === property && order === 'asc';
		const nextOrder = isAsc ? 'desc' : 'asc';
		setOrder(nextOrder);
		setOrderBy(property);
		onChangeSortModel && rows.length && onChangeSortModel({ field: property, sort: nextOrder.toUpperCase() });
	};

	const RowComponent = expandedView ? ExpandableRow : Row;

	const triggerExpand = (id) => {
		setExpandedRow((state) => ({ ...state, [id]: !state[id] }));
	};

	const getRows = () => {
		const columnsLoadingCells = columns.map((column) => (
			<TableCell key={column.key || column.field}>
				<Skeleton animation="wave" />
			</TableCell>
		));
		if (!rows?.length && loading) {
			return <TableRow>{loader || columnsLoadingCells}</TableRow>;
		}
		if (rows?.length && loading) {
			return rows?.map((row) => <TableRow key={row.id}>{columnsLoadingCells}</TableRow>);
		}
		return rows?.length ? (
			rows.map((row, idx) => (
				<RowComponent<D>
					key={`${row.id}-${idx}`}
					columns={columns}
					expandedRows={expandedRows}
					expandedView={expandedView}
					onClick={onRowClick}
					row={row}
					triggerExpand={triggerExpand}
					variant={variant}
				/>
			))
		) : (
			<NoRows<D> columns={columns} noOverlay={noOverlay} />
		);
	};

	return (
		<TableContainer className={styles[variant]} sx={{ overflow: 'visible' }}>
			<MuiTable aria-labelledby="tableTitle" className={className} size="medium" sx={{ tableLayout: 'auto' }} {...rest}>
				{!hideHeader ? (
					<TableHead<D>
						columns={columns}
						expandable={!!expandedView}
						onRequestSort={handleRequestSort}
						order={order}
						orderBy={orderBy}
						variant={variant}
					/>
				) : null}
				<TableBody>{getRows()}</TableBody>
			</MuiTable>
		</TableContainer>
	);
}
