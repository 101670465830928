import { datadogRum } from '@datadog/browser-rum';
import { isString } from 'lodash-es';

export const TEAM = {
	THUNDERBIRDS_FE: 'thunderbirds-fe',
	TLDR_FE: 'tldr-fe',
} as const;

export const EXCLUDED_URLS = [
	{
		fingerprint: 'location/address/byPostcode/:postcode',
		reg: /location\/address\/byPostcode\/.+/,
	},
];

export const fingerprintUrl = (url: string) => {
	try {
		const { host, pathname } = new URL(url);
		const fingerprint = EXCLUDED_URLS.find(({ reg }) => reg?.test(pathname))?.fingerprint;
		return fingerprint || `${host}${pathname.replace(/(\/\d+)/g, '/:id')}`;
	} catch {
		/**/
	}
	return url;
};

interface CaptureExceptions extends Record<string, any> {
	context?: Record<string, any>;
	error: any;
	fingerprint?: string;
	isWarning?: boolean;
	tags?: Record<string, string>;
	team?: (typeof TEAM)[keyof typeof TEAM];
	title?: string;
}

export const captureException = ({
	context,
	error,
	fingerprint,
	isWarning,
	tags = {},
	team = TEAM.TLDR_FE,
	title,
	...rest
}: CaptureExceptions) => {
	let errorInstance = error;
	if (title) {
		errorInstance = new Error(title);
	} else if (isString(error)) {
		errorInstance = new Error(error);
	}
	datadogRum.addError(errorInstance, {
		error,
		fingerprint,
		isWarning,
		tags,
		team,
		title,
		...context,
		...rest,
	});
};
