import * as React from 'react';

const SearchIcon = (props) => (
	<svg height={24} viewBox="0 0 24 24" width={24} {...props}>
		<path
			className="motorway-svg-fill"
			d="M10.097 13.643a6 6 0 108.485-8.485 6 6 0 00-8.485 8.485zM14.339 1.9a7.5 7.5 0 11-4.462 13.528L3.43 21.875a.749.749 0 11-1.06-1.06l6.396-6.396A7.5 7.5 0 0114.339 1.9z"
			fillRule="evenodd"
		/>
	</svg>
);

export default SearchIcon;
