import type { FormApi } from 'final-form';

import type { PhotoDoc } from '@core/utils/photo-docs.model';

import type { IVehicle, RequestAdditionalDocs } from './model';
import { domain } from './utils';

export const addForm = domain.event<[string, FormApi]>();
export const removeForm = domain.event<string>();
export const setFormFieldError = domain.event<{ error: string; path: string }>();
export const updateFormErrors = domain.event<Record<string, string>>();
export const updateEnquiry = domain.event<Partial<IVehicle>>();
export const clearSubmitErrors = domain.event<string[]>();
export const resetSubmitErrors = domain.event();

export const addToRequestImages = domain.event<PhotoDoc['id']>();
export const removeFromRequestImages = domain.event<PhotoDoc['id']>();
export const addToRequestMissingKinds = domain.event<PhotoDoc['kind']>();
export const removeFromRequestMissingKinds = domain.event<PhotoDoc['kind']>();
export const requestAdditionalDocs = domain.event<Partial<RequestAdditionalDocs>>();
export const resetRequestResupply = domain.event();

export const submitVehicleForm = domain.event();
