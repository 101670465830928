export const TYPE_OF_DOCUMENTS = {
	ADDITIONAL_DOCUMENTS: 'additional_documents',
	BANK_DETAILS: 'bank_details',
	BANK_STATEMENT: 'bank_statement',
	DEATH_CERTIFICATE: 'death_certificate',
	DRIVING_LICENCE: 'driving_licence',
	FINANCE_SETTLEMENT_LETTER: 'finance_settlement_letter',
	LETTER_OF_CONSENT: 'letter_of_consent',
	PASSPORT: 'passport',
	PROOF_OF_PURCHASE: 'proof_of_purchase',
	SOLICITORS_LETTER: 'solicitors_letter',
	UTILITY_BILL: 'utility_bill',
	V5: 'v5',
	WILL: 'will',
} as const;

export const ADDITIONAL_DOCUMENTS_OPTIONS = [
	{
		id: 'bank_statement',
		kind: 'bank_statement',
		name: 'Bank statement',
	},
	{
		id: 'death_certificate',
		kind: 'death_certificate',
		name: 'Death certificate',
	},
	{
		id: 'utility_bill',
		kind: 'utility_bill',
		name: 'Proof of address',
	},
	{
		id: 'letter_of_consent',
		kind: 'letter_of_consent',
		name: 'Letter of authorisation',
	},
	{
		id: 'will',
		kind: 'will',
		name: 'Will',
	},
	{
		id: 'passport',
		kind: 'passport',
		name: 'Passport',
	},
	{
		id: 'solicitors_letter',
		kind: 'solicitors_letter',
		name: 'Solicitor’s letter',
	},
	{
		id: 'additional_documents',
		kind: 'additional_documents',
		name: 'Additional documents',
	},
] as const;
