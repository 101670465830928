import type { Store } from 'effector';
import { sample } from 'effector';

import { allFxDone } from '@core/utils/effects';
import type { CreatePageStateProps, PageState } from '@core/utils/model';

import * as app from '../services/app/internal';

/**
 * Creates default page state
 */
export const createPageState = ({ domain, effects, pathNames }: CreatePageStateProps) => {
	const init = domain.event<string | number | Record<string, unknown>>();
	const reset = domain.event();
	/**
	 * Detect if page is current/active
	 */
	const $active: Store<boolean> = app.$pathname.map((path) => pathNames.some((pathname) => pathname.test(path)));

	/**
	 * Page ready - when all effects are resolved,
	 * if page is active
	 */
	const $ready = allFxDone({
		domain,
		effects,
		filter: $active,
		name: '$ready',
		reset: [reset, app.reset],
	});

	/**
	 * Trigger API calls to get all required data for the current page,
	 * if page is active
	 */
	sample({
		clock: init,
		filter: $active,
		target: effects,
	});

	return { $active, $ready, domain, init, reset } as PageState;
};
