import type { Store } from 'effector';
import { combine } from 'effector';
import type { FormApi } from 'final-form';
import { has, intersection, omit, set } from 'lodash-es';

import { ROLES } from '@core/utils/permissions';

import * as users from '../agents/internal';
import * as app from '../app/internal';
import * as user from '../user/internal';

import {
	ALLOWED_QA_FAILED_STATUSES,
	NOTE_TEST_CREATION_TEXT,
	REQUEST_RETAKEN_ALLOWED_STATES,
	SALES_SPECIALIST_READONLY,
} from './constants';
import * as effects from './effects';
import * as events from './events';
import type { IConfirmProfileDetails, IMOTAdvisory, IVehicle, IVehicleNote, IVehicleStatus } from './model';
import * as page from './page';
import { domain } from './utils';

const stringifyEnquiryProp = (enquiry: IVehicle, prop: keyof IVehicle, fn?: (val: any) => any) =>
	has(enquiry, prop)
		? { [prop]: enquiry[prop] ? JSON.stringify(fn?.(enquiry[prop]) || enquiry[prop]) : enquiry[prop] }
		: {};

const stringifyEnquiryFields = (enquiry: IVehicle) => ({
	...enquiry,
	...stringifyEnquiryProp(enquiry, 'chargingCables'),
	...stringifyEnquiryProp(enquiry, 'profilingStatus'),
	...stringifyEnquiryProp(enquiry, 'qaFailedReasons', (val) => intersection(ALLOWED_QA_FAILED_STATUSES, val)),
});

/**
 * Create enquiry
 */
export const $enquiry = domain
	.store<IVehicle>({} as IVehicle)
	.on(effects.getEnquiryFx.doneData, (_, enquiry) => stringifyEnquiryFields(enquiry))
	.on(effects.updateVehicleFx.doneData, (state, enquiry) => ({
		...state,
		...stringifyEnquiryFields(enquiry),
	}))
	.on(effects.refreshPricingFx.doneData, (state, data) => ({
		...state,
		marketPrice: data.marketPrice,
		priceHigh: data.higherPrice,
		priceSource: data.priceSource,
		priceUpdatedAt: data.priceUpdatedAt,
	}))
	.on(events.updateEnquiry, (state, data) => ({ ...state, ...data }))
	.reset([app.reset, page.reset]);

/**
 * Create id store to be able to subscribe to enquiry change
 */
export const $enquiryId = $enquiry.map(({ id }) => id || 0);

/**
 * Get errors on move to QA - Ready
 */
export const $formErrors = domain
	.store<Record<string, string>>({})
	// @ts-ignore
	.on(events.setFormFieldError, (state, { error, path }) => set(window.structuredClone(state), path, error))
	.on(events.updateFormErrors, (_, errors) => errors)
	.reset([app.reset, page.reset]);

/**
 * Keep current forms
 */
export const $forms = domain
	.store<Record<string, FormApi>>({})
	.on(events.addForm, (state, [name, form]) => ({
		...state,
		[name]: form,
	}))
	.on(events.removeForm, (state, name) => ({ ...omit(state, name) }))
	.reset([page.reset, app.reset]);

/**
 * All possible vehicle statuses
 */
export const $vehicleStatuses = domain
	.store<IVehicleStatus[]>([])
	.on(effects.getVehicleStatusesFx.doneData, (_, vehicleStatuses) => vehicleStatuses.data)
	.reset([app.reset, page.reset]);

export const $vehicleStatusMap: Store<{ [key: number]: IVehicleStatus }> = $vehicleStatuses.map((vehicleStatuses) =>
	Object.fromEntries(vehicleStatuses.map((status) => [status.id, status])),
);
/**
 * MOT advisories
 */
export const $motAdvisories = domain
	.store<IMOTAdvisory[]>([])
	.on(effects.getMOTAdvisoriesFx.doneData, (_, result) => result.data)
	.reset([app.reset, page.reset]);

export const $profileConfirmationDetails = domain
	.store<IConfirmProfileDetails>({} as IConfirmProfileDetails)
	.on(
		effects.getEnquiryFx.doneData,
		(_, enquiry) => ({ ...(enquiry.profileConfirmationDetails || {}) }) as IConfirmProfileDetails,
	)
	.on(effects.confirmDetailsFx.doneData, (state, result) => ({ ...state, ...result.data }))
	.reset([app.reset, page.reset]);

export const $vehicleNotes = domain
	.store<IVehicleNote[]>([])
	.on(effects.getNotesFx.doneData, (_, result) => result.data)
	.reset([app.reset, page.reset]);

export const $notes = combine($vehicleNotes, users.$allAgentsMap, user.$user, (notes, agents, { role }) =>
	notes.map((note) => ({
		...note,
		author: agents[note.userId] || 'System',
		canSeeNewLine: role === ROLES.ONLINE_SALES_SPECIALIST,
	})),
);

export const $isSaleSpecialistEnabled = combine(
	[$enquiry, user.$user],
	([enquiry, { canEditLockedSalesSpecialist }]) =>
		canEditLockedSalesSpecialist || !SALES_SPECIALIST_READONLY.includes(enquiry?.state?.slug),
);

export const $isRequestPhotoEnabled = $enquiry.map((enquiry) =>
	REQUEST_RETAKEN_ALLOWED_STATES.includes(enquiry?.state?.slug),
);

export const $prepCostShouldReset = domain.createStore(false);

export const $isVisiblePrepCostsTestBanner = combine(
	$prepCostShouldReset,
	$vehicleNotes,
	(prepCostShouldReset, notes) =>
		Boolean(prepCostShouldReset && notes.find((note) => note.content.includes(NOTE_TEST_CREATION_TEXT))),
);
