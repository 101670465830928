import type { Effect } from 'effector/effector.cjs';

import { TIME_24_HOURS } from '@core/constants';
import type { RequestConfig } from '@core/http';
import { request } from '@core/http';

import type { Address, Seller, UpdateSellerEmail } from './model';
import { domain } from './utils';

/**
 * Get seller by id
 */
export const getSellerFx: Effect<string | number, Seller> = domain.effect({
	handler: (id) =>
		request({
			method: 'get',
			url: `/dealership/sellers/${id}`,
		} as RequestConfig),
});

/**
 * Update seller
 */
export const updateSellerFx: Effect<Record<string, any>, Partial<Seller>> = domain.effect({
	handler: ({ id, ...data }) =>
		request({
			data,
			method: 'post',
			url: `premium/sellers/${id}/update-details`,
		} as RequestConfig),
});

/**
 * Check if seller email exist
 */
export const checkSellerEmailFx: Effect<string, boolean | 'OK'> = domain.effect({
	handler: (email) =>
		request({
			method: 'get',
			url: `/dealership/sellers/${encodeURIComponent(email)}/exists`,
		} as RequestConfig),
});

/**
 * Get addresses by postcode
 */
export const getAddressesByPostcodeFx: Effect<string, Address[]> = domain.effect({
	handler: (postcode) =>
		request({
			cacheKey: postcode,
			cacheTTL: TIME_24_HOURS,
			method: 'get',
			url: `/location/address/byPostcode/${postcode}`,
		} as RequestConfig),
});

/**
 * Update seller email
 */
export const updateSellerEmailFx: Effect<UpdateSellerEmail, Partial<Seller>> = domain.effect({
	handler: ({ email, enquiryId }) =>
		request({
			data: {
				email,
			},
			method: 'post',
			url: `/dealership/sellers/${enquiryId}/update-email`,
		} as RequestConfig),
});
