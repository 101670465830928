import React from 'react';
import cx from 'classnames';
import { CircularProgress } from '@mui/material';

import type { AppLoadingModel } from './AppLoading.model';

import styles from './AppLoading.module.scss';

export const AppLoading = ({
	className,
	loadingPrimary = 'Loading...',
	loadingSecondary = 'The page is loading, just take a moment please',
}: AppLoadingModel) => (
	<div className={cx(styles.container, className)}>
		<div className={styles.message}>
			<CircularProgress className={styles.icon} color="primary" />
			<h1 data-test="loading-title">{loadingPrimary}</h1>
			<div data-test="loading-text">{loadingSecondary}.</div>
		</div>
	</div>
);
