import type { Store } from 'effector';
import { gt } from 'lodash-es';

import * as app from '../app/internal';

import * as effects from './effects';
import type { Agent, AgentListData } from './model';
import { domain } from './utils';

export const $agents = domain
	.store<AgentListData>({ data: [] } as AgentListData)
	.on(effects.getAgentsListFx.doneData, (_, res) => res)
	.reset(app.reset);

export const $allAgents = domain
	.store<Agent[]>([])
	.on(effects.getAllAgentsFx.doneData, (state, { data, meta }) =>
		gt(meta?.pageNumber, 1) ? [...state, ...data] : data,
	)
	.reset(app.reset);

export const $allAgentsMap: Store<{ [key: number]: string }> = $allAgents.map((allAgents) => {
	const allAgentsArray = allAgents.map((agent) => [agent.id, agent.name]);
	return Object.fromEntries(allAgentsArray);
});
