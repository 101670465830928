import type { AxiosError } from 'axios';
import type { Effect } from 'effector';

import type { RequestConfig } from '@core/http';
import { request } from '@core/http';

import type { UserInfo, UserStats } from './model';
import { domain } from './utils';

/**
 * Get user info
 */
export const getUserInfoFx: Effect<void, UserInfo, AxiosError> = domain.effect({
	handler: () =>
		request({
			method: 'get',
			url: '/dealership/user/info',
		} as RequestConfig),
});

/**
 * Get user stats
 */
export const getUserStatsFx: Effect<{ userId: number | string | number[] }, UserStats, AxiosError> = domain.effect({
	handler: ({ userId }) =>
		request({
			method: 'get',
			params: {
				leadManagerId: userId,
			},
			url: '/dealership/vehicles/lead-manager-stats',
		} as RequestConfig),
});

/**
 * User logout
 */
export const logoutFx: Effect<void, void, AxiosError> = domain.effect({
	handler: () =>
		request({
			method: 'post',
			url: '/dealership/user/logout',
		} as RequestConfig),
});
