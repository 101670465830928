import React, { useState } from 'react';
import { useUnit } from 'effector-react';

import { Search } from '@core/components/Search';
import { userService } from '@core/services/user';
import { vehiclesService } from '@core/services/vehicles';

export const DashboardSearch = () => {
	const [{ isLeadManager }, vehiclesData, loading] = useUnit([
		userService.$user,
		vehiclesService.$search,
		vehiclesService.getSearchVehiclesFx.pending,
	]);
	const [error, setError] = useState<any>(null);

	const search = async (text) => {
		try {
			setError(null);
			await vehiclesService.getSearchVehiclesFx({ text });
		} catch (e) {
			setError(e);
		}
	};
	return isLeadManager ? (
		<Search error={error} isLoading={loading} searchVehicles={search} vehicleData={vehiclesData} />
	) : null;
};
