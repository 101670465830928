import React from 'react';
import { Alert, IconButton, Snackbar } from '@mui/material';
import { useUnit } from 'effector-react';

import { automationService } from '@services/automation';
import { RotateIcon } from '@THC/components/Icons';

import styles from './VersionAlert.module.scss';

export const VersionAlert = () => {
	const hasNewVersion = useUnit(automationService.$hasNewVersion);

	return (
		<Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={hasNewVersion}>
			<Alert
				classes={{
					icon: styles.icon,
				}}
				severity="warning"
			>
				Dashboard has been updated, please refresh your browser.
				<IconButton aria-label="refresh" onClick={() => window.location.reload()}>
					<RotateIcon />
				</IconButton>
			</Alert>
		</Snackbar>
	);
};
