import type { Store } from 'effector';

import { SESSION_TTL } from '@core/constants';
import { persistStore } from '@core/utils/store';

import * as events from './events';
import { domain } from './utils';

/**
 * Create session store
 */
export const $expires: Store<number> = persistStore(domain.store(0), { key: 'session.expires', sync: true })
	.on(events.setSession, () => Date.now() + SESSION_TTL)
	.reset([events.endSession, events.reset]);

/**
 * Calculate session validity
 */
export const isLoggedIn = (): boolean => $expires.getState() > Date.now();

export const $isLoggedIn = $expires.map((expires) => expires > Date.now());
