import React from 'react';

import { LoadingIndicator } from '@THC/components/LoadingIndicator';

import styles from './SearchLoading.module.scss';

export const SearchLoading = () => (
	<div className={styles.wrapper} data-test="search-loading">
		<LoadingIndicator size="large" />
		<div className={styles.title}>Searching...</div>
	</div>
);
