import type { Effect } from 'effector';

import { TIME_24_HOURS } from '@core/constants';
import type { JSENDAPIListResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';
import { ALLOWED_USERS_LIST_PARAMS } from '@core/services/agents/constants';
import { pickAllowedParams } from '@core/utils/api';

import type { Agent, AgentList, AgentListParams, GetAgentsParams } from './model';
import { domain } from './utils';

/**
 * Get users list
 */
export const getAgentsListFx: Effect<Partial<AgentListParams>, JSENDAPIListResponse<AgentList[]>> = domain.effect({
	handler: (params = {}) =>
		request({
			headers: {
				'use-v3-jsend': 'true',
			},
			method: 'get',
			params: pickAllowedParams(params, ALLOWED_USERS_LIST_PARAMS),
			url: 'agent',
		} as RequestConfig),
});

/**
 * Get all agents
 */
export const getAllAgentsFx: Effect<GetAgentsParams, JSENDAPIListResponse<Agent[]>> = domain.effect({
	handler: (params) => {
		const { pageNumber } = params || {};
		return request({
			cacheTTL: TIME_24_HOURS,
			headers: {
				'use-v3-jsend': 'true',
			},
			method: 'GET',
			params: {
				...(pageNumber && { pageNumber }),
			},
			url: 'agent',
		});
	},
});
