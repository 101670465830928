import type { AxiosProgressEvent } from 'axios';
import type { Domain, Effect, Event, EventCallable, Store } from 'effector';
import { combine, sample } from 'effector';

import { domain as globalDomain } from '@core/domain';

/**
 * Return boolean store that tracks success effects execution
 */
export const allFxDone = ({
	domain = globalDomain,
	effects,
	filter = globalDomain.store(true),
	name = '$done',
	reset = [],
}: {
	domain?: Domain;
	effects: Effect<any, any>[];
	filter?: Store<boolean>;
	name?: string;
	reset?: Event<any>[];
}) => {
	const mapFx = (fx: Effect<any, any>) => {
		const fxFiltered = sample({ clock: fx.done, filter });
		return domain
			.store(false, { name })
			.on(fxFiltered, () => true)
			.reset(reset);
	};
	const stores = effects.map(mapFx);
	return combine(stores).map((data) => !data.includes(false));
};

interface EnhancedEffectArgs<Params, Done> {
	domain?: Domain;
	handler: (params: Params) => Done | Promise<Done>;
	name: string;
	setKey?: (params: Params) => string;
	sid?: string;
}

interface LoadingMap {
	default?: boolean;
}

interface ProgressMap {
	default?: number;
}

export interface EnhancedEffect<Params, Done, Fail> {
	calls: Store<number>;
	done: Event<{ params: Params; result: Done }>;
	doneData: Event<Done>;
	fail: Event<{ error: Fail; params: Params }>;
	failData: Event<Fail>;
	fx: Effect<Params, Done, Fail>;
	inFlight: Store<number>;
	loading: Store<LoadingMap>;
	pending: Store<boolean>;
	progress: Store<ProgressMap>;
	reset: EventCallable<void>;
	setProgress: EventCallable<AxiosProgressEvent>;
}

/**
 * Enhance effector effect with progress and loading per key
 */
export const enhancedEffect = <Params, Done, Fail>({
	domain = globalDomain,
	handler,
	name,
	setKey = () => 'default',
	sid,
}: EnhancedEffectArgs<Params, Done>): EnhancedEffect<Params, Done, Fail> => {
	const fx = domain.effect<Params, Done, Fail>({ handler, name, sid });
	const setProgress = domain.event<any>(`${name}.setProgress`);
	const reset = domain.event<void>(`${name}.reset`);
	const setMap = sample({ clock: setProgress, fn: (params, event) => ({ event, params }), source: fx });

	return {
		calls: domain
			.store<number>(0)
			.on(fx.done, (state) => state + 1)
			.reset(reset),
		done: fx.done,
		doneData: fx.doneData,
		fail: fx.fail,
		failData: fx.failData,
		fx,
		inFlight: fx.inFlight,
		loading: domain
			.store<LoadingMap>({}, { name: `${name}.loading` })
			.on(fx, (state, params) => ({ ...state, [setKey(params)]: true }))
			.on(fx.finally, (state, { params }) => ({ ...state, [setKey(params)]: false }))
			.reset(reset),
		pending: fx.pending,
		progress: domain
			.store<ProgressMap>({}, { name: `${name}.progress` })
			.on(setMap, (state, { event, params }) => ({
				...state,
				[setKey(params)]: Math.round((event.loaded * 100) / event.total),
			}))
			.on(fx, (state, params) => ({ ...state, [setKey(params)]: 0 }))
			.reset(reset),
		reset,
		setProgress,
	};
};
