import { sample } from 'effector';
import { eq, gt } from 'lodash-es';

import * as effects from './effects';
import { domain } from './utils';

/**
 * Request all agents pages
 */
sample({
	clock: effects.getAllAgentsFx.doneData,
	filter: ({ meta }) => gt(meta?.pages, 1) && eq(meta?.pageNumber, 1),
	fn: ({ meta }) => meta?.pages,
	target: domain.effect((pages) => {
		for (let pageNumber = 2; pageNumber < pages + 1; pageNumber++) {
			effects.getAllAgentsFx({ pageNumber });
		}
	}),
});
