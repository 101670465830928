import { keys } from 'lodash-es';

import { domain as globalDomain } from '@core/domain';

export const domain = globalDomain.domain('@photosService');

const descriptionFields = {
	'tyre_tread.*': 'tyreProblemsDesc',
	'wheels.*': 'scuffedAlloyDesc',
};

export const getDescriptionField = (kind?: string) =>
	descriptionFields[keys(descriptionFields).find((it) => kind?.match(new RegExp(it))) ?? ''] || '';

export const getFirstMLStatus = (statuses: Record<string, string>, list = '') => {
	const [status] = list.split(',');
	return statuses[status] || null;
};
