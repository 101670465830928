/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
// @ts-ignore
export const {
	allowed_ddtrace_urls: ALLOWED_DD_TRACE_URLS,
	app_request_header: APP_REQUEST_HEADERS,
	backend_url: BACKEND_URL,
	dealer_webapp_url: DEALER_WEBAPP_URL,
	ga4_tracking_id: GA4_TRACKING_ID,
	node_env: ENVIRONMENT,
	photo_uploader_url: PHOTO_UPLOADER_URL,
	photos_webapp_url: PHOTO_WEBAPP_URL,
	providers_versions_header: PROVIDERS_VERSION_HEADER,
	release_version: RELEASE_VERSION,
	vehicle_side_image_url: VEHICLE_SIDE_IMAGE_URL,
	version_request_header: VERSION_REQUEST_HEADERS,
	zendesk_url: ZENDESK_URL,
	// @ts-ignore
} = window?.__app_state__ || {};

export const IS_PROD = ENVIRONMENT === 'production';
export const IS_STAGE = ENVIRONMENT === 'staging';
