import { sample } from 'effector';

import * as app from '../app/internal';

import * as events from './events';

/**
 * Reset service on app reset
 */
sample({
	clock: app.reset,
	target: events.reset,
});
