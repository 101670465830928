import { reduce, set } from 'lodash-es';

import type { EnquiryError, FormEnquiryError } from './model';

const mapReplacedFields = new Map([['seller.address1', 'seller.postcode']]);

export class ErrorsMapper {
	getKey = (error: EnquiryError): string => {
		if (error.path === 'vehicleImages' || error.path === 'documents') {
			return error?.findCriteria?.kind || '';
		}
		return mapReplacedFields.has(error.path) ? (mapReplacedFields.get(error.path) as string) : error.path;
	};

	translateField = (str: string) => str.replace(/([A-Z])/g, ' $1');

	createValue = ({ errorMessage, errors = [], oneOf }: EnquiryError): string | undefined => {
		if (errors.includes('invalid_value')) {
			return errorMessage;
		}
		if (errors.includes('requiredOneOf')) {
			const message = reduce(
				oneOf,
				(result, field, index) => `${result} ${index !== 0 ? 'or' : ''} ${this.translateField(field)}`,
				'One of fields ',
			);
			return `${message} should be set.`;
		}
		if (errors.includes('required')) {
			return 'This field is required.';
		}
		if (errors.includes('required.damageMeta')) {
			return 'Damage data is required';
		}
		return undefined;
	};

	createErrorField = (error: EnquiryError): [string, string | undefined] => [
		this.getKey(error),
		this.createValue(error),
	];

	map = (errors: EnquiryError[]): FormEnquiryError =>
		reduce(
			errors,
			(result, error) => {
				const [key, value] = this.createErrorField(error);
				set(result, key, value);
				return result;
			},
			{},
		);

	keys = (errors: EnquiryError[]) => errors.map((error) => this.getKey(error));
}
