import { PhotoInstance } from '@core/containers/PhotoEditor';
import { DocumentSection } from '@core/services/documents/model';

export const SELLER_DOCUMENTS_KINDS = {
	BANK_DETAILS: 'bank_details',
	DRIVING_LICENCE: 'driving_licence',
	FINANCE_SETTLEMENT_LETTER: 'finance_settlement_letter',
	PROOF_OF_PURCHASE: 'proof_of_purchase',
	V5: 'v5',
} as const;

export const SELLER_ADDITIONAL_DOCUMENTS_KINDS = {
	ADDITIONAL_DOCUMENTS: 'additional_documents',
	BANK_STATEMENT: 'bank_statement',
	DEATH_CERTIFICATE: 'death_certificate',
	LETTER_OF_CONSENT: 'letter_of_consent',
	PASSPORT: 'passport',
	SOLICITORS_LETTER: 'solicitors_letter',
	UTILITY_BILL: 'utility_bill',
	WILL: 'will',
} as const;

export const SELLER_DOCUMENTS_DICTIONARY = {
	additional_documents: 'Additional documents',
	bank_details: 'Bank details',
	bank_statement: 'Bank statement',
	death_certificate: 'Death certificate',
	driving_licence: 'Driving licence',
	finance_settlement_letter: 'Finance settlement',
	letter_of_consent: 'Letter of authorisation',
	passport: 'Passport',
	proof_of_purchase: 'Proof of purchase',
	solicitors_letter: "Solicitor's letter",
	utility_bill: 'Proof of address',
	v5: 'V5C',
	will: 'Will',
} as const;

const sort = (a: PhotoInstance, b: PhotoInstance) => (a.pageNumber ?? 999) - (b.pageNumber ?? 999);

export const SELLER_DOCUMENTS = [
	{
		key: 'V5C',
		maxPhotos: 3,
		seed: { name: SELLER_DOCUMENTS_KINDS.V5 },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.v5,
	},
	{
		key: 'Driving licence',
		maxPhotos: 1,
		seed: { name: SELLER_DOCUMENTS_KINDS.DRIVING_LICENCE },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.driving_licence,
	},
	{
		key: 'Proof of purchase',
		seed: { name: SELLER_DOCUMENTS_KINDS.PROOF_OF_PURCHASE },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.proof_of_purchase,
	},
	{
		key: 'Finance settlement',
		seed: { name: SELLER_DOCUMENTS_KINDS.FINANCE_SETTLEMENT_LETTER },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.finance_settlement_letter,
	},
	{
		key: 'Bank details',
		seed: { name: SELLER_DOCUMENTS_KINDS.BANK_DETAILS },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.bank_details,
	},
] as DocumentSection[];

export const SELLER_ADDITIONAL_DOCUMENTS = [
	{
		key: 'SELLER_DOCUMENTS_UTILITY_BILL',
		seed: { name: SELLER_ADDITIONAL_DOCUMENTS_KINDS.UTILITY_BILL },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.utility_bill,
	},
	{
		key: 'SELLER_DOCUMENTS_LETTER_OF_CONSENT',
		seed: { name: SELLER_ADDITIONAL_DOCUMENTS_KINDS.LETTER_OF_CONSENT },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.letter_of_consent,
	},
	{
		key: 'SELLER_DOCUMENTS_DEATH_CERTIFICATE',
		seed: { name: SELLER_ADDITIONAL_DOCUMENTS_KINDS.DEATH_CERTIFICATE },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.death_certificate,
	},
	{
		key: 'SELLER_DOCUMENTS_SOLICITORS_LETTER',
		seed: { name: SELLER_ADDITIONAL_DOCUMENTS_KINDS.SOLICITORS_LETTER },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.solicitors_letter,
	},
	{
		key: 'SELLER_DOCUMENTS_WILL',
		seed: { name: SELLER_ADDITIONAL_DOCUMENTS_KINDS.WILL },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.will,
	},
	{
		key: 'SELLER_DOCUMENTS_BANK_STATEMENT',
		seed: { name: SELLER_ADDITIONAL_DOCUMENTS_KINDS.BANK_STATEMENT },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.bank_statement,
	},
	{
		key: 'SELLER_DOCUMENTS_PASSPORT',
		seed: { name: SELLER_ADDITIONAL_DOCUMENTS_KINDS.PASSPORT },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.passport,
	},
	{
		key: 'SELLER_DOCUMENTS_ADDITIONAL_DOCUMENTS',
		seed: { name: SELLER_ADDITIONAL_DOCUMENTS_KINDS.ADDITIONAL_DOCUMENTS },
		sort,
		subTitle: SELLER_DOCUMENTS_DICTIONARY.additional_documents,
	},
] as DocumentSection[];
