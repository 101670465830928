export const JOKES = [
	{
		answer: 'He pulled a cracker.',
		question: 'What did Santa do when he went speed dating?',
	},
	{
		answer: 'He had nobody to go with.',
		question: "Why couldn't the skeleton go to the Christmas party?",
	},
	{
		answer: 'A long jumper.',
		question: 'What athlete is warmest during winter?',
	},
	{
		answer: 'He spends all his time wrapping.',
		question: 'How is Drake like an elf?',
	},
	{
		answer: 'Beyon-sleigh!',
		question: "Who's Rudolph's favourite pop star?",
	},
	{
		answer: 'Fleece Navidad.',
		question: 'How do sheep say Merry Christmas to each other?',
	},
	{
		answer: 'Silent Night!',
		question: "What is every parent's favourite Christmas song?",
	},
	{
		answer: 'Because he went down in history.',
		question: 'Why did Rudolph have to attend summer school?',
	},
	{
		answer: 'They had a weigh in the manger.',
		question: 'How did Joseph and Mary weigh baby Jesus at birth?',
	},
	{
		answer: "It's Christmas, Eve!",
		question: 'What did Adam say on the day before Christmas?',
	},
	{
		answer: 'Because he was outstanding in his field.',
		question: 'Why did the scarecrow get a big Christmas bonus?',
	},
	{
		answer: 'Soy to the World.',
		question: "What is a vegan's favourite Christmas carol?",
	},
	{
		answer: 'Jingle bills.',
		question: 'Elves use what kind of money?',
	},
	{
		answer: 'He got 25 days!',
		question: 'What happened to the man who stole an Advent Calendar?',
	},
	{
		answer: 'Freeze a jolly good fellow!',
		question: "What do they sing at a snowman's birthday party?",
	},
	{
		answer: 'Frostbite.',
		question: 'What do you get when you cross a snowman with a vampire?',
	},
	{
		answer: 'Claus-trophobia!',
		question: 'What does Santa suffer from if he gets stuck in a chimney?',
	},
	{
		answer: 'Santa Pause.',
		question: 'What do you call Santa when he stops moving?',
	},
	{
		answer: 'Ice crispies.',
		question: 'What do snowmen eat for dessert?',
	},
	{
		answer: 'A rebel without a Claus!',
		question: "What do you call an elf that runs away from Santa's workshop?",
	},
	{
		answer: 'A meltdown.',
		question: 'What is it called when a snowman has a temper tantrum?',
	},
	{
		answer: 'They have plenty of elf-confidence.',
		question: 'Why are elves such great motivational speakers?',
	},
	{
		answer: 'She sleighs.',
		question: 'Why do reindeer like Beyoncé so much?',
	},
	{
		answer: 'Truth or deer.',
		question: 'What reindeer game do reindeer play at sleepovers?',
	},
	{
		answer: 'It must have reindeer.',
		question: 'What did Santa say when he stepped into a big puddle?',
	},
	{
		answer: 'A Pony sleigh station.',
		question: 'What does Rudolph want for Christmas?',
	},
	{
		answer: 'Santa Paws!',
		question: "What is Santa's dog's name?",
	},
	{
		answer: 'Star-bucks!',
		question: "Where do Santa's reindeer stop for coffee?",
	},
	{
		answer: 'Wrap!',
		question: "What's every elf's favourite type of music?",
	},
	{
		answer: "A broken drum — you can't beat it!",
		question: "What's the absolute best Christmas present?",
	},
	{
		answer: 'You get tinsel-it is.',
		question: 'What happens if you eat Christmas decorations?',
	},
	{
		answer: 'The elf-abet.',
		question: "What do Santa's elves learn in school?",
	},
	{
		answer: 'Rude-olph.',
		question: 'What do you call an obnoxious reindeer?',
	},
	{
		answer: "'Tis the season to be jelly.",
		question: 'What do grapes sing at Christmas?',
	},
	{
		answer: 'The Christmas alphabet has Noel.',
		question: "What's the difference between the Christmas alphabet and the ordinary alphabet?",
	},
	{
		answer: 'A cookie sheet!',
		question: 'What did the gingerbread man put on his bed?',
	},
	{
		answer: "The turkey—he's always stuffed.",
		question: 'Who is never hungry at Christmas?',
	},
	{
		answer: 'The North pool.',
		question: "Where do Santa's elves go swimming?",
	},
	{
		answer: 'A Holly Davidson!',
		question: 'What kind of motorcycle does Santa drive?',
	},
	{
		answer: 'So he can "ho ho ho"!',
		question: 'Why does Santa have three gardens?',
	},
	{
		answer: 'COOOOOOOAAAALLLLLLLL!',
		question: 'What did the soccer announcer who made the naughty list get from Santa Claus?',
	},
	{
		answer: 'Subordinate Clauses.',
		question: "What did the English teacher call Santa's helpers?",
	},
	{
		answer: 'Jack Frost.',
		question: "Which of Santa's friends is the most chill?",
	},
	{
		answer: '"Present!"',
		question: 'How do elves respond when Santa takes attendance?',
	},
	{
		answer: 'Because it soots him!',
		question: 'Why does Santa go down the chimney?',
	},
	{
		answer: 'They use Santa-tizer.',
		question: "How do the elves clean Santa's sleigh on the day after Christmas?",
	},
	{
		answer: 'You can always sense his presents.',
		question: "How do you know when Santa's around?",
	},
	{
		answer: 'Nothing, it was on the house!',
		question: 'How much did Santa pay for his sleigh?',
	},
	{
		answer: 'Saint-nickel-less.',
		question: 'What do you call a broke Santa Claus?',
	},
	{
		answer: 'A Christmas quacker.',
		question: 'What do you get if you cross Santa with a duck?',
	},
	{
		answer: 'He left his sleigh in a snow parking zone.',
		question: 'Why did Santa Claus get a parking ticket on Christmas Eve?',
	},
	{
		answer: 'Elfish Presley.',
		question: "Who's Santa's favourite singer?",
	},
	{
		answer: 'It started his own branch.',
		question: 'What did the Christmas tree do after its bank closed?',
	},
	{
		answer: 'They log-on.',
		question: 'How do Christmas trees get their email?',
	},
	{
		answer: 'It needed a root canal!',
		question: 'Why did the Christmas tree go to the dentist?',
	},
	{
		answer: 'May the forest be with you!',
		question: 'What did Luke Skywalker say after he planted a Christmas tree farm?',
	},
	{
		answer: 'They signed a peace tree-ty!',
		question: 'How did the two rival Christmas trees get along?',
	},
	{
		answer: 'A treeangle!',
		question: "What was the Christmas tree's favourite shape?",
	},
	{
		answer: 'Orna-mints!',
		question: "What is a Christmas tree's favourite candy?",
	},
	{
		answer: "Because the present's beneath them.",
		question: 'Why are Christmas trees so fond of the past?',
	},
	{
		answer: 'Christmas chopping!',
		question: 'What do you call cutting down a Christmas tree?',
	},
	{
		answer: 'They have too many needles.',
		question: 'Why are Christmas trees so bad at knitting?',
	},
	{
		answer: 'Spruce Springsteen.',
		question: "Who is a Christmas tree's favourite singer?",
	},
	{
		answer: 'Nice gnawing you!',
		question: 'What did the beaver say to the Christmas Tree?',
	},
	{
		answer: 'Lighten up!',
		question: 'What did one Christmas tree say to another?',
	},
	{
		answer: 'Sep-timber!',
		question: 'What month does a Christmas tree hate the most?',
	},
	{
		answer: 'Dewey know how long it is until Santa gets here?',
		question: "Knock, knock. Who's there? Dewey. Dewey who?",
	},
	{
		answer: 'Wayne in a manger…',
		question: "Knock, knock! Who's there? Wayne. Wayne who?",
	},
	{
		answer: 'Noah good Christmas joke?',
		question: "Knock, knock! Who's there? Noah. Noah who?",
	},
	{
		answer: 'Mary Christmas!',
		question: "Knock, knock! Who's there? Mary. Mary who?",
	},
	{
		answer: 'Christmas is here!',
		question: "Knock, knock! Who's there? Chris. Chris who?",
	},
	{
		answer: "Olive Christmastime, don't you?",
		question: "Knock, knock! Who's there? Olive. Olive who?",
	},
	{
		answer: 'Honda first day of Christmas my true love sent to me…',
		question: "Knock, knock. Who's there? Honda. Honda who?",
	},
	{
		answer: 'Dexter halls with boughs of holly…',
		question: "Knock, knock! Who's there? Dexter. Dexter who?",
	},
	{
		answer: 'Santa Christmas card to you, did you get it?',
		question: "Knock, knock! Who's there? Santa. Santa who?",
	},
	{
		answer: 'Elf me wrap this present for Santa!',
		question: "Knock, knock! Who's there? Elf. Elf who?",
	},
	{
		answer: 'Holly-days are here again!',
		question: "Knock, knock! Who's there? Holly. Holly who?",
	},
	{
		answer: '',
		question: "Knock, knock! Who's there? Interrupting Santa. Inter– Ho ho ho! Merry Christmas!",
	},
	{
		answer: 'Anna partridge in a pear tree.',
		question: "Knock, knock! Who's there? Anna. Anna who?",
	},
	{
		answer: 'Avery merry Christmas to you!',
		question: "Knock, knock! Who's there? Avery. Avery who?",
	},
	{
		answer: "Snow time to waste. It's almost Christmas!",
		question: "Knock, knock! Who's there? Snow. Snow who?",
	},
	{
		answer: 'Coal me if you hear Santa coming.',
		question: "Knock, knock! Who's there? Coal. Coal who?",
	},
	{
		answer: 'Donut open til Christmas!',
		question: "Knock, knock! Who's there? Donut. Donut who?",
	},
	{
		answer: 'Justin time for Christmas cookies!',
		question: "Knock, knock! Who's there? Justin. Justin who?",
	},
	{
		answer: 'Gladys Christmas!',
		question: "Knock, knock! Who's there? Gladys. Gladys who?",
	},
	{
		answer: 'Your Santa impression needs a little work!',
		question: "Knock, knock! Who's there? Ho Ho. Ho Ho who?",
	},
];
