import { domain as globalDomain } from '@core/domain';

export const domain = globalDomain.domain('@photosService');

export const extractSVGParts = (svgString: string, url: string, side: [number, number]) => {
	const parser = new DOMParser();
	const doc = parser.parseFromString(svgString, 'text/html');
	const svg = doc.querySelector('svg');
	const [, origWidth, origHeight] = /.*\.(\d+)x(\d+)\.svg/.exec(url) || [null, ...side];

	return {
		height: parseFloat(svg?.getAttribute('height') ?? ''),
		html: doc.querySelector('svg')?.innerHTML ?? '',
		origHeight: parseFloat(`${origHeight}`),
		origWidth: parseFloat(`${origWidth}`),
		url,
		viewBox: svg?.getAttribute('viewBox') ?? '',
		width: parseFloat(svg?.getAttribute('width') ?? ''),
	};
};
