import React from 'react';

export const ArrowUpIcon = (props) => (
	<svg fill="none" height="20" viewBox="0 0 36 20" width="36" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			clipRule="evenodd"
			d="M2.44365 19.7989L18 4.24258L33.5563 19.7989L35.6738 17.6728L18 -5.69224e-05L0.323662 17.6763L2.44365 19.7989Z"
			fill="#1D1D1B"
			fillRule="evenodd"
		/>
	</svg>
);
