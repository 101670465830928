import { omit } from 'lodash-es';

import { OPERABLE_DEALER_STATUSES } from '@core/constants';
import { domain as globalDomain } from '@core/domain';
import { formatDate, getISOString, parseDate, sortDates } from '@core/utils/dateHelpers';

import { DEFAULT_ASSOCIATE } from './constants';
import type {
	IAssociate,
	IAssociateResponse,
	IAssociateTypes,
	IDealerKycCompanyDetails,
	IDealerKycDetailsWithAssociates,
	IDealerKycStatusLogs,
	IDealerWithContactsAndPermissions,
	IDraftAssociate,
	IKycStatusLog,
} from './model';
import { ASSOCIATE_TYPE } from './model';

export const domain = globalDomain.domain('@dealerService');

export const mapDealer = (dealer: IDealerWithContactsAndPermissions): IDealerWithContactsAndPermissions => ({
	...dealer,
	isOperable: OPERABLE_DEALER_STATUSES.includes(dealer?.dealerStatus?.slug),
});

export const addEmptyAssociate = ({
	associateType,
	isUpdated,
	state,
	updatedValues,
}: {
	associateType: ASSOCIATE_TYPE;
	isUpdated?: boolean;
	state: IDealerKycDetailsWithAssociates;
	updatedValues?: IDealerKycDetailsWithAssociates;
}): IDealerKycDetailsWithAssociates => {
	const draftAssociate = { ...getDefaultAssociate(associateType), isDraft: true, isPrimaryContact: false };

	if (isUpdated) {
		const kycDetailsUpdated = { ...state, ...updatedValues };
		kycDetailsUpdated.associates[associateType].push(draftAssociate);

		return kycDetailsUpdated;
	}

	const associates = {
		...state.associates,
		[associateType]: [...state.associates[associateType], draftAssociate],
	};

	return { ...state, associates };
};

export const removeDraftAssociate = (
	kycDetails: IDealerKycDetailsWithAssociates,
	{ index, type }: IDraftAssociate,
): IDealerKycDetailsWithAssociates => {
	const kycDetailsWithDraftAssociate = { ...kycDetails };
	kycDetailsWithDraftAssociate.associates[type]?.splice(index, 1);

	return kycDetailsWithDraftAssociate;
};

export const removeAssociate = (
	kycDetails: IDealerKycDetailsWithAssociates,
	{ index, type }: IDraftAssociate,
): IDealerKycDetailsWithAssociates => {
	const kycDetailsWithRemovedAssociate = { ...kycDetails };
	const removedAssociates = kycDetailsWithRemovedAssociate?.removedAssociates ?? [];
	removedAssociates?.push({
		...kycDetailsWithRemovedAssociate.associates[type][index],
	});

	kycDetailsWithRemovedAssociate.associates[type]?.splice(index, 1);

	return {
		...kycDetailsWithRemovedAssociate,
		removedAssociates,
	};
};

export const mapDealerKycDetails = (kycDetails: IDealerKycCompanyDetails): IDealerKycDetailsWithAssociates => {
	const mappedAssociates =
		kycDetails?.associates
			.map((associate) => ({
				...associate,
				dateOfBirth: getISOString(parseDate(associate.dateOfBirth, 'dd-MM-yyyy')),
				residentialAddress: associate.address,
			}))
			?.sort((associate1, associate2) => Number(associate2.isPrimaryContact) - Number(associate1.isPrimaryContact)) ??
		[];

	const setPrimaryContact = (associatesByType: IAssociate[]) => {
		if (associatesByType.length > 1 && associatesByType.every((associate) => !associate.isPrimaryContact)) {
			associatesByType[0].isPrimaryContact = true;
		}

		return associatesByType;
	};

	const getAssociateByType = (type: ASSOCIATE_TYPE): IAssociate[] =>
		mappedAssociates.some((associate) => associate.type === type)
			? setPrimaryContact(mappedAssociates.filter((associate) => associate.type === type))
			: [getDefaultAssociate(type)];

	const associatesByType = {
		[ASSOCIATE_TYPE.ACCOUNT_MANAGER]: getAssociateByType(ASSOCIATE_TYPE.ACCOUNT_MANAGER),
		[ASSOCIATE_TYPE.DIRECTOR]: getAssociateByType(ASSOCIATE_TYPE.DIRECTOR),
		[ASSOCIATE_TYPE.SHAREHOLDER]: getAssociateByType(ASSOCIATE_TYPE.SHAREHOLDER),
	};

	return omit(
		{
			...kycDetails,
			associates: associatesByType as IAssociateTypes,
		},
		'kycStatus',
	);
};

const mapAssociate = (associate: IAssociate, type?: ASSOCIATE_TYPE): IAssociateResponse => ({
	...omit(associate, ['residentialAddress', 'isDraft']),
	...(associate.residentialAddress && { address: associate.residentialAddress }),
	...(associate.dateOfBirth && { dateOfBirth: formatDate(getISOString(associate.dateOfBirth), 'dd-MM-yyyy') }),
	...(type && { type }),
});

const mapRemovedAssociateAssociate = (associate: IAssociate): IAssociateResponse => ({
	...mapAssociate(associate),
	isDeleted: true,
});

export const parseKycCompanyDetails = (
	kycDetails: Partial<IDealerKycDetailsWithAssociates>,
): Partial<IDealerKycCompanyDetails> => {
	const associates = [
		...(kycDetails?.associates?.accountManager?.map((associate) =>
			mapAssociate(associate, ASSOCIATE_TYPE.ACCOUNT_MANAGER),
		) || []),
		...(kycDetails?.associates?.director?.map((associate) => mapAssociate(associate, ASSOCIATE_TYPE.DIRECTOR)) || []),
		...(kycDetails?.associates?.shareholder?.map((associate) => mapAssociate(associate, ASSOCIATE_TYPE.SHAREHOLDER)) ||
			[]),
		...(kycDetails?.removedAssociates?.map(mapRemovedAssociateAssociate) || []),
	];

	return omit(
		{
			...kycDetails,
			associates,
		},
		['dealerId', 'removedAssociates', 'kycStatus'],
	);
};
export const getDefaultAssociate = (type: ASSOCIATE_TYPE): IAssociate =>
	({
		...DEFAULT_ASSOCIATE,
		type,
	}) as IAssociate;

export const hasDraftAssociates = (associates: IAssociateTypes): boolean =>
	[...associates.director, ...associates.shareholder, ...associates.accountManager]?.some(
		(associate) => associate.isDraft,
	);

export const mapKycStatusLogs = (logs: IDealerKycStatusLogs): IKycStatusLog[] =>
	logs?.statusLogs
		?.map(({ Date: date, Source: source, Status: status, User: user }) => ({
			date,
			source,
			status,
			user,
		}))
		.sort((log1, log2) => sortDates({ date1: new Date(log1.date), date2: new Date(log2.date), order: 'asc' })) ?? [];
