import type { Canceler, CancelToken } from 'axios';
import axios from 'axios';

let cancel: Canceler;

/**
 * Refresh cancel token
 */
const refreshCancelToken = (): CancelToken =>
	new axios.CancelToken((executor) => {
		cancel = executor;
	});

let cancelToken: CancelToken = refreshCancelToken();

export const cancelRequests = () => {
	cancel();
	cancelToken = refreshCancelToken();
};

export const applyCancelToken = (config) => ({ ...config, cancelToken });
