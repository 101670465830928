import { PAGINATION_PARAMS } from '@core/constants';

export const ALLOWED_DEALER_LIST_PARAMS = [...PAGINATION_PARAMS, 'statusId', 'keyword', 'accountManagerId'];

export const ALLOWED_DEALERS_SEARCH_PARAMS = [...PAGINATION_PARAMS, 'q', 'biddingEnabled'];

export const DEFAULT_DEALER_LIST_PARAMS = {
	_end: 25,
	_order: 'DESC',
	_sort: 'createdAt',
	_start: 0,
};
