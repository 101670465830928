export enum DAMAGE_AI_DETECT_FEEDBACK {
	ADD_PROFILE = 'accepted',
	NOT_DAMAGE = 'rejected',
	PENDING = 'pending',
	SELLER_REPORTED = 'declared',
}

export enum DAMAGE_TYPES {
	ALLOY_SCUFF = 'damage_alloy_scuffs',
	DENT = 'damage_dents',
	MISSING_TRIM = 'damage_missing_trims',
	PAINTWORK = 'damage_paintwork',
	SCRATCH = 'damage_scratches',
	TYRES = 'damage_tyres',
	WARNING_LIGHT = 'damage_warning_lights',
	WINDSCREEN = 'damage_windscreen',
}
