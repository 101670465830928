import { format, isValid, parse, parseISO } from 'date-fns';
import { isDate } from 'lodash-es';

/**
 * get Date
 */
export const getDate = (value: Date | string): Date | null => {
	if (isDate(value)) {
		return isValid(value) ? value : null;
	}
	return value ? parseISO(value) : null;
};

export const formatDate = (value: Date | string | null = null, formatString = 'yyyy-MM-dd'): string | null => {
	if (!value) {
		return null;
	}
	const date = getDate(value);

	return date ? format(date, formatString) : date;
};

export const getISOString = (value) => (value ? getDate(value)?.toISOString() : null);

export const parseDate = (value: string | null = null, formatString: string = 'yyyy-MM-dd'): Date | null => {
	if (!value) {
		return null;
	}
	const date = parse(value, formatString, new Date());

	return isValid(date) ? date : null;
};

export const sortDates = ({ date1, date2, order }: { date1?: Date; date2?: Date; order?: 'asc' | 'desc' }): number => {
	if (!date1 || !date2 || !order || !isValid(date1) || !isValid(date2)) {
		return 0;
	}

	return order === 'desc' ? date1.getTime() - date2.getTime() : date2.getTime() - date1.getTime();
};
