import './side-effects';

import * as stores from './agents.store';
import * as effects from './effects';

export * from './model';

export const agentsService = {
	...effects,
	...stores,
} as const;
