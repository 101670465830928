import React, { useEffect } from 'react';
import type { RouteProps } from 'react-router';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUnit } from 'effector-react';

import { AppLoading } from '@core/components/AppLoading';
import { PAGES } from '@core/constants';
import { appService } from '@core/services/app';
import { authService } from '@core/services/auth';
import { contentService } from '@core/services/content';
import { notificationService } from '@core/services/notifications';
import { userService } from '@core/services/user';
import type { Permissions, ROLES } from '@core/utils/permissions';

interface PermissionRouteGuardProps {
	children: React.ReactElement;
	permissions: Permissions;
}

interface RoleRouteGuardProps {
	children: React.ReactElement;
	roles: ROLES[];
}

export const PrivateRoute = ({ element }: RouteProps) => {
	const [{ id: userId }, isLoggedIn, redirectionUrl, notification, busy] = useUnit([
		userService.$info,
		authService.$isLoggedIn,
		appService.$redirectLocation,
		notificationService.$notificationForRedirect,
		userService.getUserInfoFx.pending,
	]);
	const location = useLocation();
	const navigation = useNavigate();
	const { pathname } = location;

	useEffect(() => {
		if (redirectionUrl && isLoggedIn) {
			appService.resetRedirectLocation();
			if (pathname !== redirectionUrl) {
				navigation(redirectionUrl);
			}
		}
	}, [isLoggedIn, navigation, pathname, redirectionUrl, userId]);

	useEffect(() => {
		if (isLoggedIn && !userId && !busy) {
			userService.getUserInfoFx();
		}
	}, [busy, isLoggedIn, userId]);

	useEffect(() => {
		if (notification && notification.redirect) {
			const { redirect } = notification;
			notificationService.resetAfterRedirect();
			navigation(redirect);
		}
	}, [navigation, notification]);

	if (busy && isLoggedIn) {
		return <AppLoading />;
	}

	return isLoggedIn ? (
		<>{element}</>
	) : (
		<Navigate
			replace
			state={{
				from: location,
			}}
			to={PAGES.LOGIN.ROUTE}
		/>
	);
};

export const PermissionRouteGuard = ({ children, permissions }: PermissionRouteGuardProps) => {
	const [currentPermissions, tradeVehiclesPage] = useUnit([
		userService.$permissions,
		contentService.$tradeVehiclesPage,
	]);
	const params = useParams();
	const location = useLocation();
	const isForbiddenPage = Boolean(permissions && !permissions.every((permit) => currentPermissions.includes(permit)));

	const isCorrectUrl =
		!params.id ||
		Boolean(
			params.id &&
				Object.entries(PAGES).some(([NAME, PAGE]) => {
					if (NAME === 'HOME') {
						return false;
					}
					return PAGE.PATTERN.test(location.pathname);
				}),
		);

	const isTradeVehiclesPage = location.pathname === PAGES.TRADE_VEHICLES.PATH;
	const isRouteAllowed = Boolean((isTradeVehiclesPage && tradeVehiclesPage.enabled) || !isTradeVehiclesPage);
	return isForbiddenPage || !isCorrectUrl || !isRouteAllowed ? <Navigate to="/" /> : children;
};

export const RoleRouteGuard = ({ children, roles }: RoleRouteGuardProps) => {
	const [{ role: currentRole }] = useUnit([userService.$user]);

	const isForbiddenPage = Boolean(roles && !roles.includes(currentRole));

	return isForbiddenPage ? <Navigate to="/" /> : children;
};
