import { sample } from 'effector';
import { has } from 'lodash-es';

import { isNumeric } from '@core/utils/dataHelpers';
import { notifyManyOnDoneFx, notifyOnDoneFx } from '@core/utils/side-effects';

import * as vehicle from '../vehicle/internal';

import * as effects from './effects';
import { getPhotosEx } from './effects';
import { reset } from './events';
import { getDescriptionField } from './utils';

/**
 * Update enquiry damage description on image delete
 */
sample({
	clock: effects.deletePhotoFx.done,
	filter: (enquiry, { params: { isDamaged, kind } }) => {
		const field = getDescriptionField(kind);
		const value = enquiry[field];
		const valid = isNumeric(value) && value > 0;
		return !!field && !!isDamaged && valid;
	},
	fn: (enquiry, { params: { kind } }) => {
		const field = getDescriptionField(kind);
		return {
			[field]: `${Number(enquiry[field]) - 1}`,
		};
	},
	source: vehicle.$enquiry,
	target: vehicle.updateEnquiry,
});

/**
 * Update enquiry scuffed alloy description on image archive
 */
sample({
	clock: effects.archivePhotoFx.done,
	filter: ({ scuffedAlloyDesc }, { params: { isDamaged } }) => !Number.isNaN(scuffedAlloyDesc) && !!isDamaged,
	fn: ({ scuffedAlloyDesc }) => ({
		scuffedAlloyDesc: `${Number(scuffedAlloyDesc) - 1}`,
	}),
	source: vehicle.$enquiry,
	target: vehicle.updateEnquiry,
});

sample({
	clock: vehicle.page.reset,
	target: [reset, getPhotosEx.reset],
});

/**
 * Notify on done
 */
notifyManyOnDoneFx([
	[effects.deletePhotoFx.done, 'Photo has been deleted successfully'],
	[effects.archivePhotoFx.done, 'Photo has been archived successfully'],
	[effects.uploadPhotoEx.done, 'Photo has been uploaded successfully'],
	[effects.restorePhotoFx.done, 'Photo has been restored successfully'],
	[effects.markAsServiceHistoryPhotoFx.done, 'Photo has been marked as service history successfully'],
]);

/**
 * Notify on location update/delete
 */
notifyOnDoneFx(effects.updatePhotoDamageLocationFx.done, ({ params }) => {
	const { damageMeta } = params;
	return has(params, 'damageMeta') ? `Damage location has been ${damageMeta ? 'updated' : 'deleted'} successfully` : '';
});
