import React from 'react';

import type { SidebarProps } from './Profile.model';
import { ProfileInfo } from './ProfileInfo';
import { StatsInfo } from './StatsInfo';

import styles from './Sidebar.module.scss';

export const Sidebar = ({
	isLeadManager,
	loading,
	name,
	profilePicture,
	stats: [primaryStats, secondaryStats],
}: SidebarProps) => (
	<div>
		<ProfileInfo name={name} src={profilePicture} />
		{isLeadManager && (
			<>
				<StatsInfo color="primary" loading={loading} stats={primaryStats} title="Personal stats" />
				<div className={styles.divider} />
			</>
		)}
		<StatsInfo color="secondary" loading={loading} stats={secondaryStats} title="Company stats" />
	</div>
);
