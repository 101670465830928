import { DEFAULT_ADDITIONAL_DOCS } from './constants';
import * as effects from './effects';
import * as events from './events';
import type { RequestAdditionalDocs, RequestImagesId, RequestMissingKinds } from './model';
import { domain } from './utils';

export const $requestImagesId = domain
	.store<RequestImagesId>([])
	.on(events.removeFromRequestImages, (state, id) => state.filter((img) => id !== img))
	.on(events.addToRequestImages, (state, id) => [...state, id])
	.on(effects.requestImagesFx.doneData, () => [])
	.on(events.resetRequestResupply, () => []);

export const $requestMissingKinds = domain
	.store<RequestMissingKinds>([])
	.on(events.addToRequestMissingKinds, (state, kind) => [...state, kind])
	.on(events.removeFromRequestMissingKinds, (state, kind) => state.filter((img) => img !== kind))
	.on(effects.requestImagesFx.doneData, () => [])
	.on(events.resetRequestResupply, () => []);

export const $requestAdditionalDocs = domain
	.store<RequestAdditionalDocs>(DEFAULT_ADDITIONAL_DOCS)
	.on(events.requestAdditionalDocs, (state, doc) => ({ ...state, ...doc }))
	.on(effects.requestImagesFx.doneData, () => DEFAULT_ADDITIONAL_DOCS)
	.on(events.resetRequestResupply, () => DEFAULT_ADDITIONAL_DOCS);
