import type { Unit } from 'effector';
import { sample } from 'effector';

import { NOTIFICATION_STATUSES } from '@core/constants';

import * as notifications from '../services/notifications/internal';

export const notifyManyOnFx = (type) => (data: [clock: Unit<any>, message: string][]) => {
	data.forEach(([clock, message]) =>
		sample({
			clock,
			fn: () => ({ message, type }),
			target: notifications.push,
		}),
	);
};

export const notifyManyOnDoneFx = notifyManyOnFx(NOTIFICATION_STATUSES.SUCCESS);
export const notifyManyOnFailFx = notifyManyOnFx(NOTIFICATION_STATUSES.WARNING);

export const notifyOnFx =
	(type) =>
	<T>(clock: Unit<T>, fn: (params: T) => string) => {
		sample({
			clock,
			filter: (params) => Boolean(fn(params)),
			fn: (params) => ({ message: fn(params), type }),
			target: notifications.push,
		});
	};

export const notifyOnDoneFx = notifyOnFx(NOTIFICATION_STATUSES.SUCCESS);
export const notifyOnFailFx = notifyOnFx(NOTIFICATION_STATUSES.WARNING);
export const notifyOnErrorFx = notifyOnFx(NOTIFICATION_STATUSES.ERROR);
