import * as app from '../app/internal';

import * as effects from './effects';
import * as events from './events';
import type { IDamageImage } from './model';
import { domain } from './utils';

export const $damageImages = domain
	.store<IDamageImage[]>([])
	.on(effects.getDamageImagesFx.doneData, (_, result) => result.data)
	.on(events.updateDamageImages, (state, payload) =>
		state.map(({ id, ...rest }) => ({ id, ...rest, ...(id === payload.id ? { ...payload } : {}) })),
	)
	.reset(app.reset);
