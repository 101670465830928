import React from 'react';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { Box } from '@mui/material';

export const UnderMaintenance = ({ text, title = 'Under Maintenance' }) => (
	<Box
		sx={{
			'& h2': {
				alignItems: 'center',
				color: 'darkOrange',
				display: 'flex',
				fontSize: '3rem',
			},
			'& span': {
				alignItems: 'center',
				color: '#1b1b1d',
				display: 'flex',
				fontSize: '2rem',
			},
			'& svg': {
				color: 'darkOrange',
				height: '16rem',
				width: '16rem',
			},
			alignItems: 'center',
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'center',
		}}
	>
		<EngineeringIcon />
		<h2 data-test="under-maintenance-title">{title}</h2>
		{text && <span data-test="under-maintenance-text">{text}</span>}
	</Box>
);
