import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUnit } from 'effector-react';

import { PAGES } from '@core/constants';
import { userService } from '@core/services/user';
import { NoPermissions } from '@pages/NoPermissions';

export const Home = () => {
	const { canSeePrioritisedVehicles, canSeeStandardVehicles, canViewDealers, canViewUsers, canViewVehicles } = useUnit(
		userService.$user,
	);

	if (canSeeStandardVehicles && canViewVehicles) {
		return <Navigate to={PAGES.VEHICLES.ROUTE} />;
	}
	if (canSeePrioritisedVehicles && canViewVehicles) {
		return <Navigate to={PAGES.PRIORITISED_VEHICLES.ROUTE} />;
	}
	if (canViewDealers) {
		return <Navigate to={PAGES.DEALERS.ROUTE} />;
	}
	if (canViewUsers) {
		return <Navigate to={PAGES.USERS.ROUTE} />;
	}
	return <NoPermissions />;
};
