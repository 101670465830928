import type { Store } from 'effector';
import { combine } from 'effector';

import { PAGES } from '@core/constants';
import type { ListResponse } from '@core/model';
import { createKeyValueUnit } from '@core/utils/store';
import type { IDealerStatus } from '@services/app';
import type { IDealerNote } from '@services/vehicle';

import * as users from '../agents/internal';
import * as app from '../app/internal';

import { DEFAULT_KYC_DETAILS, KYC_STATUS_LOG_SOURCE } from './constants';
import * as effects from './effects';
import * as events from './events';
import type {
	IDealerKycDetailsWithAssociates,
	IDealerKycStatusDetails,
	IDealerStatusLogs,
	IDealerWithContactsAndPermissions,
	IKycStatus,
	IKycStatusLog,
	IKycStatusLogRow,
} from './model';
import {
	addEmptyAssociate,
	domain,
	hasDraftAssociates,
	mapDealer,
	mapDealerKycDetails,
	mapKycStatusLogs,
	removeAssociate,
	removeDraftAssociate,
} from './utils';

/**
 * Dealer key/value store
 */
export const { $active: $dealer, $map: $dealersMap } = createKeyValueUnit<IDealerWithContactsAndPermissions>({
	$activeKey: app.$location.map(({ pathname }) => (pathname.match(PAGES.DEALER.PATTERN) || [])[1] || ''),
	domain,
	name: '@app/@dealerService/$dealer',
	reset: app.reset,
	upsert: effects.getDealerFx.doneData.map(mapDealer),
});

/**
 * Dealer statuses
 */
export const $dealerStatuses = domain
	.store<ListResponse<IDealerStatus>>({ list: [], total: null })
	.on(effects.getDealerStatusesFx.doneData, (_, data) => data)
	.reset(app.reset);

/**
 * Allowed dealer statuses
 */
export const $allowedDealerStatuses = combine($dealer, $dealerStatuses, (dealer, { list = [] }) => {
	const { dealerStatus } = dealer || {};
	const { allowedNextStatuses = [] } = list.find(({ slug }) => dealerStatus?.slug === slug) || ({} as IDealerStatus);
	const allowed = [dealerStatus?.slug, ...allowedNextStatuses];
	return allowedNextStatuses && dealerStatus ? list.filter(({ slug }) => allowed.includes(slug)) : list;
});

/**
 * Dealer activation SMS history
 */
export const $dealerActivationSMSHistory = domain
	.store<Record<string, boolean>>({})
	.on(effects.sendDealerActivationSMSFx.done, (state, { params }) => ({ ...state, [params]: true }))
	.reset(app.reset);

const $dealerNotes = domain
	.store<ListResponse<IDealerNote>>({ list: [], total: null } as any)
	.on(effects.getNotesFx.doneData, (_, notes) => notes)
	.reset(app.reset);

export const $notes = combine($dealerNotes, users.$allAgentsMap, (dealerNotes, agents) =>
	(dealerNotes?.list ?? []).map((note) => ({ ...note, author: agents[note.userId] || 'System' })),
);

/**
 * All dealer statuses
 */
export const $allDealerStatuses = domain
	.store<ListResponse<IDealerStatus>>({ list: [], total: null } as any)
	.on(effects.getAllDealerStatusesFx.doneData, (_, data) => data)
	.reset(app.reset);

/**
 * Dealer status logs
 */
const $dealerStatusLogs = domain
	.store<IDealerStatusLogs[]>([])
	.on(effects.getStatusLogsFx.doneData, (_, { data }) => data)
	.reset(app.reset);

export const $statusLogs = combine(
	$dealerStatusLogs,
	users.$allAgentsMap,
	$allDealerStatuses,
	(dealerStatusLogs, agents, { list }) =>
		dealerStatusLogs.map((log) => ({
			...log,
			status: list.find((status) => status.id === log.statusId)?.name ?? '-',
			user: agents[log.userId] ?? 'System',
		})),
);

/**
 * Dealer KYC Details
 */
export const $dealerKycDetails = domain
	.store<IDealerKycDetailsWithAssociates>(DEFAULT_KYC_DETAILS)
	.on(events.createEmptyAssociate, (state, { isUpdated, type, values }) =>
		addEmptyAssociate({ associateType: type, isUpdated, state, updatedValues: values }),
	)
	.on(events.removeAssociate, (state, draftAssociate) => removeAssociate(state, draftAssociate))
	.on(events.removeDraftAssociate, (state, draftAssociate) => removeDraftAssociate(state, draftAssociate))
	.on(effects.getDealerKycDetailsFx.doneData, (_, { data }) => mapDealerKycDetails(data))
	.on(effects.updateKycDetailsFx.doneData, (_, { data }) => mapDealerKycDetails(data))
	.reset([app.reset, events.kycReset]);

/**
 * Dealer KYC Status
 */
export const $dealerKycStatus = domain
	.store<IDealerKycStatusDetails>({ kycStatus: null })
	.on(effects.getDealerKycStatusFx.doneData, (_state, data) => data.data)
	.reset(app.reset);

/**
 * KYC Statuses
 */
export const $dealerKycStatusesList = domain
	.store<IKycStatus[]>([])
	.on(effects.getKycStatusesFx.doneData, (_, { data }) =>
		data.statuses.map((status: string) => ({
			id: status,
			name: status,
		})),
	)
	.reset(app.reset);

export const $hasDraftAssociates = $dealerKycDetails.map((data) => hasDraftAssociates(data.associates ?? {}));

/**
 * KYC Status Logs
 */
export const $dealerKycStatusLogs = domain
	.store<IKycStatusLog[]>([])
	.on(effects.getDealerKycStatusLogsFx.doneData, (_, { data }) => mapKycStatusLogs(data))
	.reset(app.reset);

/**
 * KYC Status Logs with User
 */
export const $kycStatusLogsWithUser: Store<IKycStatusLogRow[]> = combine(
	$dealerKycStatusLogs,
	users.$allAgentsMap,
	$dealer,
	(kycStatusLogs, agents, dealer) =>
		kycStatusLogs.map((log, index) => ({
			...log,
			id: index,
			user:
				(log.source === KYC_STATUS_LOG_SOURCE.DASHBOARD
					? agents[log.user]
					: dealer?.dealerContacts?.find((contact) => contact.id === Number(log.user))?.name) ?? log.user,
		})),
);
