import type { TableParams } from '@core/components/Table';
import { formatDate } from '@core/utils/dateHelpers';

export const renderDate = (params: TableParams) => formatDate(params.value, 'dd/MM/yyyy');

export const renderDateWithTime = (params: TableParams) => formatDate(params.value, 'dd/MM/yyyy HH:mm');

export const renderDateWithTimeOnLine = (params: TableParams) =>
	`${formatDate(params.value, 'dd/MM/yyyy')}\n${formatDate(params.value, 'HH:mm')}`;

export const renderTableDateWithTime = (params: TableParams) => formatDate(params.value, 'dd/MM/yy, HH:mm');

export const renderTableDate = (params: TableParams) => formatDate(params.value, 'dd/MM/yy');

export const renderTableTime = (params: TableParams) => formatDate(params.value, 'HH:mm:ss');
