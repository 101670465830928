export const DISPLAY_PERMISSIONS = [
	'users_management',
	'users_edit_lead_assignment',
	'users_edit_profile_picture',
	'users_view',
	'grading_edit',
	'vehicles_update_documentation_fields',
	'vehicles_update_specification_fields',
	'vehicles_reassign_sold',
	'assign_sales_specialist_in_restricted_state',
	'modify_restricted_vehicle_status',
	'see_all_bids_during_sales',
	'delivery_edit',
	'dealers_management',
	'dealers_add',
	'dealers_delete',
	'dealers_contact_add',
	'dealers_modify_status',
	'dealers_fee_override',
	'dealers_view',
	'dealers_kyc_read',
	'dealers_kyc_write',
	'vehicles_update',
	'senior_lead_manager_view',
	'notes_delete',
	'vehicle_list_standard',
	'vehicle_list_prioritised',
	'vehicles_override_qa_validation_check',
	'documents_review',
	'documents_access',
	'vehicles_view',
	'vehicle_payment_view',
	'edit_locked_sales_specialist',
	'edit_reserve_prep_cost_fields',
	'mark_as_manual_payment',
	'edit_price_amendment_for_payments',
	'access_dealer_contact_permissions_for_payments',
	'anytime_refresh_pricing',
	'view_edit_dealer_otp_number',
];
