import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useUnit } from 'effector-react';

import { contentService } from '@core/services/content';

import styles from './MotorwayLogo.module.scss';

export const MotorwayLogo = () => {
	const christmasTheme = useUnit(contentService.$christmasTheme);

	return (
		<div className={styles.wrapper}>
			<Link className={styles.link} to="/">
				<div className={christmasTheme ? styles.xlogo : styles.logo} data-test="logo" />
				<Typography className={styles.logoText} data-test="logoText" variant="h3">
					Dashboard
				</Typography>
			</Link>
		</div>
	);
};
