import type { AxiosResponse } from 'axios';
import type { Effect } from 'effector';

import type { JSENDAPIListResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';
import { axiosInstance } from '@core/http/axios';

import type { Content } from '../content/internal';

import { UNDER_MAINTENANCE_ID } from './constants';
import type { UnderMaintenance } from './model';
import { domain } from './utils';

/**
 * Get under maintenance content
 */
export const getUnderMaintenanceFx: Effect<void, JSENDAPIListResponse<[Content<UnderMaintenance>]>> = domain.effect({
	handler: () =>
		request({
			isPublic: true,
			method: 'get',
			params: {
				names: UNDER_MAINTENANCE_ID,
			},
			url: '/content/contentItems/public',
		} as RequestConfig),
});

/**
 * Get version info
 */
export const getVersionFx: Effect<void, AxiosResponse<string>> = domain.effect({
	handler: () => axiosInstance.get(`${window.location.origin}/version.txt`),
});
