import React, { Children } from 'react';
import cx from 'classnames';
import { Grid, Typography } from '@mui/material';

import type { StatsInfoSectionProps } from './Profile.model';

import styles from './StatsInfoSection.module.scss';

export const StatsInfoSection = ({ children, color, title }: StatsInfoSectionProps) => (
	<div data-test={`stats-info-${color}`}>
		<Typography gutterBottom className={styles.title} variant="h5">
			{title}
		</Typography>
		<Grid
			container
			className={cx({
				[styles.primary]: color === 'primary',
				[styles.secondary]: color === 'secondary',
			})}
			spacing={2}
		>
			{Children.map(children, (child, i) => (
				<Grid
					item
					className={cx(i === 0 ? styles.lowCard : styles.bigCard, styles.card)}
					data-test="stats-info-info-item"
					xs={i === 0 ? 12 : 6}
				>
					{child}
				</Grid>
			))}
		</Grid>
	</div>
);
