import React from 'react';
import { Outlet } from 'react-router';
import cx from 'classnames';

import { VersionAlert } from '@containers/VersionAlert';

import { DashboardAppBar } from '../Dashboard/DashboardAppBar';

import styles from './StaticDashboard.module.scss';

export const StaticDashboard = () => (
	<div className={cx(styles.root, 'motorway-theme')}>
		<VersionAlert />
		<DashboardAppBar compact />
		<div className={styles.container}>
			<Outlet />
		</div>
	</div>
);
