import { sample } from 'effector';

import * as app from '../app/internal';
import * as vehicle from '../vehicle/internal';

import { ARCHIVED_IMAGES_MODAL_NAME } from './constants';
import * as effects from './effects';
import * as events from './events';
import * as store from './store';
import { domain } from './utils';

const closeArchivedModal = domain.createEffect(() => {
	app.closeModal(ARCHIVED_IMAGES_MODAL_NAME);
	events.openArchiveDialog(null);
});

/**
 * Get photos after restoring images
 */
sample({
	clock: effects.restorePhotoFx.done,
	source: vehicle.$enquiryId,
	target: [effects.getPhotosEx.fx, closeArchivedModal],
});

/**
 * open close modal for archived images
 */
sample({
	clock: store.$archivedPhotosTrigger,
	target: domain.createEffect((photo) => {
		photo ? app.openModal(ARCHIVED_IMAGES_MODAL_NAME) : app.closeModal(ARCHIVED_IMAGES_MODAL_NAME);
	}),
});

/**
 * get photos after modal is closed
 */
sample({
	clock: store.$archivedPhotosTrigger,
	filter: (photo) => !photo,
	source: vehicle.$enquiryId,
	target: effects.getPhotosEx.fx,
});
