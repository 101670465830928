import { combine } from 'effector';

import * as documentsService from '@services/documents/internal';
import * as vehicleService from '@services/vehicle/internal';

export const $financeData = combine(vehicleService.$enquiry, documentsService.$documentsMatch, (enquiry, documents) => {
	const financeCompanyFromHPI = enquiry.limitations?.financeAgreements[0]?.company || null;
	const financeCompanyFromDoc =
		documents.finance_settlement_letter?.fields?.find((field) => field.fieldName === 'companyName')?.value || '';

	const agreementNumberFromHPI = enquiry.limitations?.financeAgreements[0]?.reference || null;
	const agreementNumberFromDoc = documents.finance_settlement_letter?.fields?.find(
		(field) => field.fieldName === 'agreementNumber',
	)?.value;

	// Get details from HPI or if it doesn't exist, get from OCR of Finance Doc
	return {
		agreementNumber: agreementNumberFromHPI || agreementNumberFromDoc || null,
		financeCompany: financeCompanyFromHPI || financeCompanyFromDoc || null,
		financeCompanyFromDoc,
	};
});
