import type { PhotoInstance } from '@containers/PhotoEditor';

import { TYPE_OF_DOCUMENTS } from '../documents';

import { ImageActionType, SnowplowEventCategory } from './constants';

const isDocumentKind = (kind?: string) => Object.values(TYPE_OF_DOCUMENTS).includes(kind as any);

export const sendPhotoActionEventCategory = (photo: Partial<PhotoInstance>) =>
	isDocumentKind(photo.name) ? SnowplowEventCategory.DOCUMENTS : SnowplowEventCategory.IMAGES;

export const sendPhotoActionEventName = (action: ImageActionType, photo: Partial<PhotoInstance>) =>
	`ad_${isDocumentKind(photo.name) ? 'document' : 'image'}_${
		action === ImageActionType.ARCHIVE && !isDocumentKind(photo.name) ? 'archive' : action
	}`;
