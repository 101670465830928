import React, { cloneElement } from 'react';
import cx from 'classnames';
import { TableCell, TableRow } from '@mui/material';
import { isFunction } from 'lodash-es';

import { ArrowDownIcon } from '@core/components/Field/icons/ArrowDownIcon';
import { ArrowUpIcon } from '@core/components/Field/icons/ArrowUpIcon';
import { Button } from '@THC/components/Button';
import { ArrowDownIcon as THCArrowDownIcon, ArrowUpIcon as THCArrowUpIcon } from '@THC/components/Icons';

import { getValue } from './Table.helpers';
import type { BaseRow, ExpandableRowProps, NoRowsProps, RowProps } from './Table.model';

import styles from './TableRows.module.scss';

export const Row = <D extends BaseRow>({ columns, onClick, row, variant = 'regular' }: RowProps<D>) => (
	<TableRow
		hover
		className={cx(styles.row, styles[variant])}
		onClick={onClick ? () => onClick(row) : undefined}
		tabIndex={-1}
	>
		{columns.map(({ cellClassName, field, key, ...column }) => (
			<TableCell
				key={key || field}
				align="left"
				className={cx(styles.cell, isFunction(cellClassName) ? cellClassName({ field, row }) : cellClassName)}
			>
				{getValue(field, column, row)}
			</TableCell>
		))}
	</TableRow>
);

export const ExpandableRow = <D extends BaseRow>({
	columns,
	expandedRows,
	expandedView,
	row,
	triggerExpand,
	variant = 'regular',
}: ExpandableRowProps<D>) => (
	<>
		<TableRow
			hover
			className={cx(styles.row, { [styles.expanded]: expandedRows[row.id] }, styles[variant])}
			data-test="data-grid-expanded-view"
			tabIndex={-1}
		>
			{variant !== 'slim' && (
				<TableCell className={styles.expandTrigger}>
					{!!expandedView && (
						<button
							className={styles.trigger}
							data-test={`expand-row-action-${row.id}`}
							onClick={() => triggerExpand(row.id)}
							type="button"
						>
							{expandedRows[row.id] ? (
								<ArrowUpIcon className={styles.expandIcon} />
							) : (
								<ArrowDownIcon className={styles.expandIcon} />
							)}
						</button>
					)}
				</TableCell>
			)}
			{columns.map(({ cellClassName, clickable = false, field, key, renderCell }) => (
				<TableCell
					key={key || field}
					align="left"
					className={cx(styles.cell, cellClassName)}
					onClick={variant === 'slim' && clickable ? () => triggerExpand(row.id) : undefined}
					sx={{ cursor: variant === 'slim' ? 'pointer' : 'default' }}
				>
					{renderCell ? renderCell({ field, row, value: row[field] }) : row[field]}
				</TableCell>
			))}
			{variant === 'slim' && (
				<TableCell sx={{ padding: 0 }}>
					{!!expandedView && (
						<Button
							fullWidth
							className={styles.trigger}
							data-test={`expand-row-action-${row.id}`}
							icon={expandedRows[row.id] ? THCArrowUpIcon : THCArrowDownIcon}
							label={expandedRows[row.id] ? 'Hide row' : 'Show row'}
							onClick={() => triggerExpand(row.id)}
							showLabel={false}
							type="button"
							variant="secondary"
						/>
					)}
				</TableCell>
			)}
		</TableRow>
		{expandedRows[row.id] && (
			<TableRow className={variant === 'slim' ? styles.slimInfoRow : styles.infoRow}>
				<TableCell classes={{ root: styles.infoCell }} colSpan={(columns?.length || 0) + 1}>
					{expandedRows[row.id] && expandedView && (
						<div className={styles.infoWrapper}>
							{cloneElement(expandedView, {
								row,
							})}
						</div>
					)}
				</TableCell>
			</TableRow>
		)}
	</>
);

export const NoRows = <D extends BaseRow>({ columns, noOverlay }: NoRowsProps<D>) => (
	<TableRow tabIndex={-1}>
		<TableCell align="center" className={styles.noRowsCell} colSpan={columns.length + 1}>
			{noOverlay}
		</TableCell>
	</TableRow>
);
