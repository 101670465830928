export enum VEHICLE_SECTIONS {
	BIDS_AND_OFFERS = 'Bids & offers',
	CONDITION_AND_DAMAGE = 'Condition & damage',
	DELIVERY = 'Delivery',
	DOCUMENTATION = 'Documentation',
	NOTES = 'Notes',
	PHOTOS = 'Photos',
	PRICING_AND_STATUS = 'Pricing & status',
	SELLER_DETAILS = 'Seller details',
	SELLER_DOCUMENTS = 'Seller documents',
	SERVICE_HISTORY = 'Service history',
	SPECIFICATION = 'Specification',
	STATUS_LOGS = 'Status logs',
	VEHICLE_HISTORY = 'Vehicle history',
	VEHICLE_PAYMENT = 'Payments',
	WHEELS_AND_TYRES = 'Wheels & tyres',
}

export const VEHICLE_SECTIONS_ORDER = [
	VEHICLE_SECTIONS.SELLER_DETAILS,
	VEHICLE_SECTIONS.DELIVERY,
	VEHICLE_SECTIONS.PRICING_AND_STATUS,
	VEHICLE_SECTIONS.BIDS_AND_OFFERS,
	VEHICLE_SECTIONS.NOTES,
	VEHICLE_SECTIONS.SPECIFICATION,
	VEHICLE_SECTIONS.DOCUMENTATION,
	VEHICLE_SECTIONS.SERVICE_HISTORY,
	VEHICLE_SECTIONS.VEHICLE_HISTORY,
	VEHICLE_SECTIONS.PHOTOS,
	VEHICLE_SECTIONS.CONDITION_AND_DAMAGE,
	VEHICLE_SECTIONS.WHEELS_AND_TYRES,
	VEHICLE_SECTIONS.SELLER_DOCUMENTS,
	VEHICLE_SECTIONS.VEHICLE_PAYMENT,
	VEHICLE_SECTIONS.STATUS_LOGS,
];
