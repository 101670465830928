import { GLOBAL_LOADING } from '@core/constants';

import * as app from '../app/internal';

import * as events from './events';
import type { ILoadingState } from './model';
import { domain } from './utils';

/**
 * Create loading state store
 */
export const $loadingMap = domain
	.store<ILoadingState>({ [GLOBAL_LOADING]: 0 })
	.on(events.start, (state, key = GLOBAL_LOADING) =>
		key
			? {
					...state,
					[key]: state[key] !== undefined ? state[key] + 1 : 1,
				}
			: undefined,
	)
	.on(events.stop, (state, key = GLOBAL_LOADING) =>
		key
			? {
					...state,
					[key]: state[key] - 1,
				}
			: undefined,
	)
	.reset([events.reset, app.reset]);

export const $loading = $loadingMap.map((loadings) => Object.values(loadings).some((it) => !!it));
