import type { UnitValue } from 'effector';
import { attach } from 'effector';

import './side-effects';

import * as effects from './effects';
import * as store from './store';

export * from './model';

export type User = UnitValue<typeof store.$user>;

export const userService = {
	...effects,
	...store,
	getCurrentUserStatsFx: attach({
		effect: effects.getUserStatsFx,
		mapParams: (_, { id: userId }) => ({ userId }),
		name: '@app/@userService/getUserStatsWithDataFx',
		source: store.$info,
	}),
};
