import * as app from '../app/internal';

import { getDealersListFx } from './effects';
import type { DealersListResponse } from './model';
import { domain } from './utils';

export const $dealers = domain
	.store<DealersListResponse>({ list: [], total: null })
	.on(getDealersListFx.doneData, (_, dealers) => dealers)
	.reset(app.reset);
