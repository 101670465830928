import type { Store, StoreWritable } from 'effector';
import { isEqual } from 'lodash-es';
import qs from 'query-string';

import { QUERY_STRING_CONFIG } from '@core/constants';
import { persistStore } from '@core/utils/store';
import { parseCurrentUrl, parseRedirectUrl } from '@core/utils/url';

import * as auth from '../auth/internal';

import * as events from './events';
import type { IFilters, ILocation } from './model';
import { domain } from './utils';

/**
 * Create redirect location store
 */
export const $redirectLocation: Store<string> = persistStore(domain.store(null), {
	key: 'redirectTo',
})
	.on(events.setRedirectLocation, (state) => {
		const { isAuthRedirect, isLoginPage, toPath, toUrlParam } = parseRedirectUrl();
		return !isAuthRedirect ? toUrlParam || (!isLoginPage && toPath) || state : state;
	})
	.on(auth.endSession, (state) => {
		const { isLoginPage, toPath } = parseRedirectUrl();
		return !isLoginPage ? toPath || null : state;
	})
	.reset([events.resetRedirectLocation, events.reset]);

events.setRedirectLocation();

/**
 * Create location store
 */
export const $location = domain
	.store({ hash: '', pathname: '', search: '' } as ILocation)
	.on(events.setLocation, (state, location) => location || state)
	.reset([events.reset]);

/**
 * Separate pathname
 */
export const $pathname = $location.map(({ pathname }) => pathname || '');

// Set initial location
events.setLocation(parseCurrentUrl());

/**
 * Create search store
 */
export const $search: StoreWritable<IFilters> = domain
	.store(
		{},
		{
			updateFilter: (curr, prev) => !isEqual(curr, prev),
		},
	)
	.on($location, (_, { search }) =>
		qs.parse(search, {
			arrayFormat: QUERY_STRING_CONFIG.arrayFormat,
			parseBooleans: true,
			parseNumbers: true,
		}),
	)
	.reset([events.resetFilters, events.reset]);
