import { enquiryStates, SLUGS } from '@motorway/mw-enquiry-states';

import { PAGINATION_PARAMS } from '@core/constants';

export const ALLOWED_NOTES_PARAMS = [...PAGINATION_PARAMS, 'dealerId'];

export const DEFAULT_NOTES_PARAMS = {
	_end: 50,
	_order: 'DESC',
	_sort: 'createdAt',
	_start: 0,
};

export const ALLOWED_LOGS_PARAMS = [...PAGINATION_PARAMS];

export const DEFAULT_VEHICLE_STATUSES_PARAMS = {
	_end: 999,
	_order: 'ASC',
	_sort: 'name',
	_start: 0,
};

export const DEFAULT_LOGS_PARAMS = {
	_end: 25,
	_order: 'DESC',
	_sort: 'createdAt',
	_start: 0,
};

export const CONFIRMATION_DETAILS = {
	contactDetails: true,
	initialise: true,
	price: true,
	profileDetails: true,
	serviceHistory: true,
	vehicleCondition: true,
	vehicleDetails: true,
	vehiclePhotos: true,
};

export const CONFIRM_MODAL_OVERRIDE_VALIDATION = 'CONFIRM_MODAL_OVERRIDE_VALIDATION';
export const CONFIRM_MODAL_BENEFICIARY_WARNING = 'CONFIRM_MODAL_BENEFICIARY_WARNING';

// we need to prevent to use old values from the server
// @ts-ignore
export const ALLOWED_QA_FAILED_STATUSES = enquiryStates[SLUGS.QA_FAILED].reasons
	.map((reason) => reason.options.map((option) => option.id))
	.flat();

export const DEFAULT_ADDITIONAL_DOCS = {
	convertibleRoofDown: false,
	damageAndCondition: false,
	serviceHistory: false,
};
export const FORM_NAME = 'FORM_NAME';

export const SALES_SPECIALIST_READONLY = [
	SLUGS.QA_READY,
	SLUGS.QA_FAILED,
	SLUGS.QA_ON_HOLD,
	SLUGS.READY_FOR_SALE,
	SLUGS.FOR_SALE,
	SLUGS.SALE_ACTIVE,
	SLUGS.UNDER_OFFER,
	SLUGS.UNSOLD_NO_BIDS,
	SLUGS.UNSOLD_BELOW_RESERVE_BID,
	SLUGS.SOLD_WAIT,
	SLUGS.SOLD_CONFIRMED,
	SLUGS.SOLD_PURCHASE_CONFIRMED,
	SLUGS.SOLD_DOCUMENTS_REVIEWED,
	SLUGS.SOLD_INVOICE_RAISED,
	SLUGS.SOLD_INVOICE_PAID,
	SLUGS.NOT_AS_DESCRIBED,
	SLUGS.UNCONTACTABLE,
	SLUGS.SOLD_ELSEWHERE,
	SLUGS.OTHER_SELLER_ISSUES,
	SLUGS.NOT_COLLECTED_IN_TIME,
	SLUGS.DEALER_WITHDREW_BID,
	SLUGS.OTHER_DEALER_ISSUES,
	SLUGS.UNDER_REVIEW,
];

export const REQUEST_RETAKEN_ALLOWED_STATES = [SLUGS.PART_DETAILS, SLUGS.FULL_DETAILS, SLUGS.FULL_DETAILS_WAIT];

export const NOTE_TEST_CREATION_TEXT = 'Vehicle entered no prep test';
