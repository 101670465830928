import { sample } from 'effector';

import { notifyOnDoneFx, notifyOnFailFx } from '@core/utils/side-effects';
import * as documentService from '@services/documents/internal';
import * as enquiry from '@services/vehicle/internal';

import * as effects from './effects';

sample({
	clock: [effects.getFinanceSettlementData.done],
	source: enquiry.$enquiryId,
	target: [documentService.getDocumentsEx.fx],
});

notifyOnDoneFx(effects.getFinanceSettlementData.done, () => 'Successfully retrieved finance settlement documentation');
notifyOnFailFx(effects.getFinanceSettlementData.fail, () => 'Unable to retrieve finance settlement documentation');
