import './side-effects';

import * as store from './dealer.store';
import * as effects from './effects';
import * as events from './events';

export * from './model';

export * from './constants';
export * from './model';

export const dealerService = {
	...effects,
	...events,
	...store,
};
