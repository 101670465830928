import React from 'react';

import { BagSpeedIcon, PaperStackIcon, PriceTagIcon } from '@THC/components/Icons';

import type { StatsInfoProps } from './Profile.model';
import { StatsCard } from './StatsCard';
import { StatsInfoSection } from './StatsInfoSection';

const formatPluralisation = (number, text = 'vehicle') => (number === 1 ? text : `${text}s`);

export const StatsInfo = ({ color, loading, stats: [readyForSale, forSale, sold], title }: StatsInfoProps) => (
	<StatsInfoSection color={color} title={title}>
		<StatsCard
			icon={<PaperStackIcon />}
			loading={loading}
			text={`${formatPluralisation(readyForSale)} moved to ready for sale today!`}
			title={readyForSale}
		/>
		<StatsCard
			icon={<PriceTagIcon />}
			loading={loading}
			text={`${formatPluralisation(forSale)} are currently for sale`}
			title={forSale}
		/>
		<StatsCard
			icon={<BagSpeedIcon />}
			loading={loading}
			text={`${formatPluralisation(sold)} sold today`}
			title={sold}
		/>
	</StatsInfoSection>
);
