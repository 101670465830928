import React from 'react';
import cx from 'classnames';
import { Backdrop, Portal } from '@mui/material';

import SearchContent from './parts/SearchContent';
import SearchInput from './parts/SearchInput';
import { UNIVERSAL_SEARCH_INPUT_BOX_ID, useSearchLogic } from './Search.helpers';
import { SearchContextProvider } from './SearchContext';

import styles from './Search.module.scss';

const Search = ({ error, isLoading, searchVehicles, vehicleData }) => {
	const searchLogic = useSearchLogic(isLoading, vehicleData?.total, searchVehicles);

	const { close, isBackdropOpen, isReducedMotion } = searchLogic;

	const backdropClassName = cx(styles.backdrop, {
		[styles.backdropVisible]: isBackdropOpen,
		[styles.backdropReducedMotion]: isReducedMotion,
	});

	const onClick = () => close();

	return (
		<SearchContextProvider value={searchLogic}>
			<SearchInput />
			<Portal container={document.querySelector('.motorway-theme')}>
				<Backdrop className={backdropClassName} onClick={onClick} open={isBackdropOpen} />
			</Portal>
			<Portal container={document.querySelector(`#${UNIVERSAL_SEARCH_INPUT_BOX_ID}`)}>
				<SearchContent error={error} isLoading={isLoading} vehicleData={vehicleData} />
			</Portal>
		</SearchContextProvider>
	);
};

export default Search;
