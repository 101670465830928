export const VRMColorClassName = {
	default: 'vrmColorForVehicle',
	trade: 'vrmColorForTradeVehicle',
} as const;

export interface VRMFieldProps {
	children: string;
	colorClassName?: (typeof VRMColorClassName)[keyof typeof VRMColorClassName];
	id: number;
}
