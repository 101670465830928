import { sample } from 'effector';

import * as app from '../app/internal';

import * as effects from './effects';

sample({
	clock: app.init,
	target: effects.getContentFx,
});
