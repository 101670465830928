import { sample } from 'effector';
import { debounce, delay } from 'patronum';

import { SLUGS } from '@motorway/mw-enquiry-states';

import { IS_PROD, IS_STAGE } from '@core/app-state';
import { TIME_1_MIN, TIME_10_MIN } from '@core/constants';

import * as app from '../app/internal';
import * as bids from '../bids/internal';
import * as vehicle from '../vehicle/internal';

import * as effects from './effects';

/**
 * Init version and maintenance checks
 */
sample({
	clock: app.init,
	filter: () => IS_PROD || IS_STAGE || !!window?.Cypress,
	target: [effects.getVersionFx, effects.getUnderMaintenanceFx],
});

/**
 * Check version every 10 min
 */
delay({
	source: effects.getVersionFx.finally,
	target: effects.getVersionFx,
	timeout: TIME_10_MIN,
});

/**
 * Check maintenance flag every minute
 */
delay({
	source: effects.getUnderMaintenanceFx.finally,
	target: effects.getUnderMaintenanceFx,
	timeout: TIME_1_MIN,
});

/**
 * Store with state update ready state
 */
const $updateBids = vehicle.$enquiry.map(({ state }) => [SLUGS.FOR_SALE, SLUGS.SALE_ACTIVE].includes(state?.slug));

/**
 * Update bids every minute for the given states
 */
sample({
	clock: debounce({
		source: sample({
			clock: bids.getBidsEx.fx.finally,
			filter: $updateBids,
		}),
		timeout: TIME_1_MIN,
	}),
	filter: $updateBids,
	fn: ({ params }) => params,
	target: bids.getBidsEx.fx,
});
