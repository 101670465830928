import type { ListResponse } from '@core/model';

import * as app from '../app/internal';

import * as effects from './effects';
import type { IPrioritisedVehicle } from './model';
import { domain, mapVehiclesToList } from './utils';

export const $prioritisedVehicles = domain
	.store<ListResponse<IPrioritisedVehicle>>({ list: [], total: null })
	.on(effects.getVehiclesFx.doneData, (_, vehicles) => ({
		list: mapVehiclesToList(vehicles.list),
		total: vehicles.total,
	}))
	.reset(app.reset);
