import { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash-es';

import { setCSSVariable } from '@core/utils/hooks';

import {
	BODY_SCROLL_VARIABLE,
	MAX_RESULTS_HEIGHT,
	NO_RESULTS_HEIGHT,
	RESULT_CELL_HEIGHT,
	TOO_MANY_RESULTS_HEIGHT,
	UNIVERSAL_SEARCH_RESULTS_MAX_HEIGHT_VARIABLE,
} from './Search.constants';

export const MAX_RESULTS = 25;
export const UNIVERSAL_SEARCH_INPUT_BOX_ID = 'mw-universal-search';

export const useSearchLogic = (isLoading: boolean, total: number, searchVehicles) => {
	const [isOpen, setOpen] = useState(false);
	const [isBackdropOpen, setIsBackdropOpen] = useState(false);
	const [isReducedMotion, setReducedMotion] = useState(false);
	const [inputState, setInputState] = useState({ isMinimumLength: false, value: '' });
	const [searchState, setSearchState] = useState({ searchTerm: null });

	const debouncedSearchVehicles = useRef(
		debounce((text) => {
			text && searchVehicles(text);
		}, 500),
	).current;

	useEffect(() => {
		inputState.isMinimumLength && debouncedSearchVehicles(inputState.value);
		return () => {
			debouncedSearchVehicles.cancel();
		};
	}, [debouncedSearchVehicles, inputState]);

	const close = useCallback(({ reducedMotion = false } = {}) => {
		setReducedMotion(reducedMotion);
		setOpen(false);
		setIsBackdropOpen(false);
	}, []);

	const onChange = ({ target: { value } }) => {
		const trimmed = value.trim();
		const isNumber = /^\d+$/.test(trimmed);
		const isMinimumLength = isNumber ? trimmed.length > 4 : trimmed.length > 1;
		isMinimumLength && setSearchState({ searchTerm: trimmed });
		setInputState({ isMinimumLength, value: trimmed });
	};

	const onFocus = useCallback(() => {
		setReducedMotion(false);
		setOpen(true);
		setIsBackdropOpen(true);
	}, []);

	const isEmptyInput = !inputState.value;

	const onBlur = useCallback(() => {
		if (isEmptyInput) {
			close();
		}
	}, [close, isEmptyInput]);

	useEffect(() => {
		setCSSVariable(BODY_SCROLL_VARIABLE, isBackdropOpen ? 'hidden' : 'initial');
		return () => {
			setCSSVariable(BODY_SCROLL_VARIABLE, 'initial');
		};
	}, [isBackdropOpen]);

	// set results container height and let css transitions do the magic 🎉
	useEffect(() => {
		let value = NO_RESULTS_HEIGHT;

		if (total) {
			// last table row doesn't have bottom border, this is why we subtract 2px
			const resultsTotalHeight = total * RESULT_CELL_HEIGHT + (total > MAX_RESULTS ? TOO_MANY_RESULTS_HEIGHT : 0) - 2;

			value = resultsTotalHeight > MAX_RESULTS_HEIGHT ? MAX_RESULTS_HEIGHT : resultsTotalHeight;
		}

		if (isLoading) {
			value = Math.max(NO_RESULTS_HEIGHT, value);
		}

		setCSSVariable(UNIVERSAL_SEARCH_RESULTS_MAX_HEIGHT_VARIABLE, `${value}px`);
	}, [total, isLoading, inputState.isMinimumLength]);

	return {
		close,
		inputState,
		isBackdropOpen,
		isOpen,
		isReducedMotion,
		onBlur,
		onChange,
		onFocus,
		searchState,
		setOpen,
	};
};
