import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import type { VRMFieldProps } from '@core/components/VRMField/VRMField.module';

import styles from './VRMField.module.scss';

export const VRMField = ({ children, colorClassName, id }: VRMFieldProps) => (
	<Link className={styles.wrapper} data-test={'vrm-link'} to={`/vehicles/${id}`}>
		<span className={cx(styles.vrm, styles[`${colorClassName}`])}>{children}</span>
	</Link>
);
