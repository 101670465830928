import { reset, start, stop } from './events';
import { $loading, $loadingMap } from './store';

export type { ILoadingState } from './model';

export const loadingService = {
	$loading,
	$loadingMap,
	reset,
	start,
	stop,
};
