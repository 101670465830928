import * as events from './events';
import type { IModalsState } from './model';
import { domain } from './utils';

/**
 * Create modals store
 */
export const $modals = domain
	.store<IModalsState>({})
	.on(events.openModal, (state, name) => ({ ...state, [name]: true }))
	.on(events.closeModal, (state, name) => ({ ...state, [name]: false }))
	.reset([events.closeAllModals, events.reset]);
