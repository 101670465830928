import { trackPageView } from '@snowplow/browser-tracker';
import { sample } from 'effector';
import { register } from 'swiper/element/bundle';

import * as auth from '../auth/internal';

import * as effects from './effects';
import * as events from './events';
import * as locationStore from './location.store';
import { domain, getPageMixPanelEvent } from './utils';

sample({
	clock: events.reset,
	target: auth.reset,
});

/**
 * Add tracking event to the page visits
 */
sample({
	clock: locationStore.$location,
	filter: ({ pathname }) => !!getPageMixPanelEvent(pathname),
	fn: ({ pathname }) => getPageMixPanelEvent(pathname),
	source: locationStore.$location,
	target: effects.sendLogEventFx,
});

sample({
	clock: locationStore.$location,
	target: domain.effect(() => {
		requestAnimationFrame(() => trackPageView());
	}),
});

sample({
	clock: events.init,
	target: domain.effect(() => {
		register();
	}),
});
