import './side-effects';

import * as effects from './effects';
import * as events from './events';
import * as store from './store';

export * from './model';

export const selfServeService = {
	...events,
	...effects,
	...store,
} as const;
