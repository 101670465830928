import * as effects from './effects';
import * as stores from './store';

export * from './constants';
export * from './model';

export const prioritisedVehiclesService = {
	...stores,
	...effects,
};
