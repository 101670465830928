import React, { memo, useContext } from 'react';
import cx from 'classnames';
import { Input, InputAdornment } from '@mui/material';

import { UNIVERSAL_SEARCH_INPUT_BOX_ID } from '../Search.helpers';
import { SearchContext } from '../SearchContext';

import SearchIcon from './SearchIcon';

import styles from './SearchInput.module.scss';

const SearchInput = () => {
	const { inputState, onBlur, onChange, onFocus } = useContext(SearchContext);
	const isEmptyInput = !inputState.value;

	return (
		<div className={styles.inputBox} id={UNIVERSAL_SEARCH_INPUT_BOX_ID}>
			<Input
				className={cx({
					[styles.focused]: !isEmptyInput,
				})}
				classes={{
					focused: styles.focused,
					inputTypeSearch: styles.inputTypeSearch,
					root: styles.root,
				}}
				data-test="header-search-field"
				endAdornment={
					<span data-fake-placeholder className={styles.fakePlaceholder}>
						Search all vehicles
					</span>
				}
				onBlur={onBlur}
				onChange={onChange}
				onFocus={onFocus}
				placeholder="Search all vehicles"
				startAdornment={
					<InputAdornment className={styles.adornment} position="start">
						<SearchIcon />
					</InputAdornment>
				}
				type="search"
			/>
		</div>
	);
};

export default memo(SearchInput);
