import type { BadgeProps } from '@THC/components/Badge';

import type { PaymentStatusType } from './model';

export const PAYMENT_STATUS = {
	LIVE: 'live',
	MANUAL: 'manual',
	READY: 'ready',
	SUCCESS: 'success',
} as const;

export const BADGE_VARIANT: Record<PaymentStatusType, BadgeProps> = {
	live: {
		text: 'Live',
		variant: 'warning',
	},
	manual: {
		text: 'Manual',
		variant: 'default',
	},
	ready: {
		text: 'Ready',
		variant: 'primary',
	},
	success: {
		text: 'Success',
		variant: 'success',
	},
} as const;
