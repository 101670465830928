import * as effects from './effects';
import * as effectsAutomated from './effects.automated';
import * as events from './events';
import type { FormsStore, PrepCostConfig } from './model';
import { domain } from './utils';

/**
 * Form values store
 */
export const $forms = domain
	.store<FormsStore>({ vehicle: {} })
	.on(events.setFormValues, (state, { form, values }) => ({
		...state,
		[form]: { ...state[form], ...values },
	}))
	.reset([effectsAutomated.updatePrepCostFX.done, events.reset]);

/**
 * PrepCost Config
 */
export const $prepCostConfig = domain
	.store<PrepCostConfig>({} as PrepCostConfig)
	.on(effects.getPrepCostConfigFx.doneData, (_, { data: [{ content } = { content: {} as PrepCostConfig }] }) => content)
	.reset([events.reset]);
