export const ORIGINAL_SIZES = {
	hatchback: {
		back: [584, 464],
		driver_side: [1266, 464],
		front: [735, 464],
		passenger_side: [1266, 464],
		top: [900, 464],
	},
	saloon: {
		back: [705, 500],
		driver_side: [1200, 383],
		front: [785, 500],
		passenger_side: [1200, 383],
		top: [1068, 500],
	},
	suv: {
		back: [579, 500],
		driver_side: [1160, 442],
		front: [687, 500],
		passenger_side: [1200, 458],
		top: [1014, 500],
	},
} as const;
