import type { ReactNode } from 'react';
import React from 'react';

import styles from './ToolBar.module.scss';

export const ToolBar = ({ children }: { children: ReactNode }) => (
	<div className={styles.container} data-test="tool-bar">
		{children}
	</div>
);
