import type { ListResponse } from '@core/model';

import * as app from '../app/internal';

import * as effects from './effects';
import type { IListVehicle } from './model';
import { domain } from './utils';

export const $vehicles = domain
	.store<ListResponse<IListVehicle>>({ list: [], total: null })
	.on(effects.getVehiclesFx.doneData, (_, vehicles) => vehicles)
	.reset(app.reset);
