import { get, isFunction } from 'lodash-es';

export const getValue = (field: string, column, row) => {
	const { renderCell, valueFormatter, valueGetter } = column;
	const value = isFunction(valueGetter) ? valueGetter({ row }) : get(row, field);

	if (isFunction(renderCell)) {
		return renderCell({ field, row, value });
	}
	return isFunction(valueFormatter) ? valueFormatter({ field, row, value }) : value;
};
