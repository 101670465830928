import type { StringifyOptions } from 'query-string';

// session
export const SESSION_TTL = 60 * 60 * 1000; // 60 minutes
export const TIME_24_HOURS = 60 * 60 * 1000 * 24; // 24 hours
export const TIME_10_MIN = 10 * 60 * 1000; // 10 minutes
export const TIME_1_MIN = 60 * 1000; // 1 minute

// loading types
export const GLOBAL_LOADING = 'GLOBAL';

// notifications
export enum NOTIFICATION_STATUSES {
	DEFAULT = 'default',
	ERROR = 'error',
	GLOBAL_ERROR = 'global_error',
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
}

// errors
export const NOTIFICATION_TIMEOUT = 4 * 1000; // seconds

// query-string
export const QUERY_STRING_CONFIG: StringifyOptions = {
	arrayFormat: 'none',
	skipEmptyString: true,
};

export const PAGINATION_PARAMS = ['_end', '_order', '_sort', '_start'];

export const JSEND_PAGINATION_PARAMS = ['pageNumber', 'itemsPerPage', 'sort', 'order'];

// routes/pages/urls
export const PAGES = {
	DEALER: {
		NAME: 'Dealer',
		PATTERN: /\/dealers\/(\d+)\/?$/,
		ROUTE: '/dealers/:id',
	},
	DEALER_CREATE: {
		NAME: 'Create Dealer',
		PATH: '/dealers/create',
		PATTERN: /\/dealers\/create$/,
		ROUTE: '/dealers/create',
	},
	DEALERS: {
		NAME: 'Dealers',
		PATH: '/dealers',
		PATTERN: /\/dealers.*/,
		ROUTE: '/dealers',
	},
	ENQUIRY_EDIT: {
		NAME: 'Vehicle',
		PATTERN: /\/enquiries\/(\d+)/,
		ROUTE: '/enquiries/:id(\\d+)',
	},
	HOME: {
		PATH: '/',
		PATTERN: /\//,
		ROUTE: '/',
	},
	LOGIN: {
		NAME: 'Login',
		PATH: '/login',
		PATTERN: /\/login.*/,
		ROUTE: '/login',
	},
	PRIORITISED_ENQUIRY_EDIT: {
		NAME: 'Vehicle',
		PATTERN: /\/prioritised-enquiries\/(\d+)\/?$/,
		ROUTE: '/prioritised-enquiries/:id',
	},
	PRIORITISED_VEHICLE_EDIT: {
		NAME: 'Vehicle',
		PATTERN: /\/prioritised-vehicles\/(\d+)\/?$/,
		ROUTE: '/prioritised-vehicles/:id',
	},
	PRIORITISED_VEHICLES: {
		NAME: 'Prioritised Vehicles',
		PATH: '/prioritised-vehicles',
		PATTERN: /\/prioritised-vehicles.*/,
		ROUTE: '/prioritised-vehicles',
	},
	SELF_SERVE: {
		NAME: 'Self Serve Vehicles',
		PATH: '/self-serve',
		PATTERN: /\/self-serve/,
		ROUTE: '/self-serve',
	},
	SELF_SERVE_VEHICLES: {
		NAME: 'Self Serve Vehicles',
		PATH: '/self-serve-vehicles',
		PATTERN: /\/self-serve-vehicles$/,
		ROUTE: '/self-serve-vehicles',
	},
	TRADE_VEHICLES: {
		NAME: 'Trade Vehicles',
		PATH: '/trade-vehicles',
		PATTERN: /\/trade-vehicles.*/,
		ROUTE: '/trade-vehicles',
	},
	TRADE_VEHICLES_EDIT: {
		NAME: 'Vehicle',
		PATTERN: /\/trade-vehicles\/(\d+)\/?$/,
		ROUTE: '/trade-vehicles/:id',
	},
	USER: {
		NAME: 'User',
		PATTERN: /\/users\/(\d+)\/?$/,
		ROUTE: '/users/:id',
	},
	USER_CREATE: {
		NAME: 'Create User',
		PATH: '/users/create',
		PATTERN: /\/users\/create$/,
		ROUTE: '/users/create',
	},
	USERS: {
		NAME: 'Users',
		PATH: '/users',
		PATTERN: /\/users.*/,
		ROUTE: '/users',
	},
	VEHICLE_EDIT: {
		NAME: 'Vehicle',
		PATTERN: /\/vehicles\/(\d+)\/?$/,
		ROUTE: '/vehicles/:id',
	},
	VEHICLES: {
		NAME: 'Vehicle Listing',
		PATH: '/vehicles',
		PATTERN: /\/vehicles$/,
		ROUTE: '/vehicles',
	},
};

// dealer
export enum DEALER_STATUSES {
	ACTIVE = 'active',
	APPROVED = 'approved',
	APPROVED_PREVIEW = 'approved_preview',
	BLOCKED = 'blocked',
	BLOCKED_PENDING = 'blocked_pending',
	DELETED = 'deleted',
	DORMANT = 'dormant',
	FROZEN = 'frozen', // to be removed, also amend tests after new statuses will be added to the BE
	FROZEN_DEBT = 'frozen_debt',
	FROZEN_ENFORCEMENT = 'frozen_enforcement',
	FROZEN_PREVIEW = 'frozen_preview',
	INACTIVE = 'inactive',
	INCOMPLETE = 'incomplete',
	PENDING = 'pending',
	PROSPECT = 'prospect',
	REJECTED = 'rejected',
	UNRESPONSIVE = 'unresponsive',
}

export const OPERABLE_DEALER_STATUSES = [
	DEALER_STATUSES.APPROVED,
	DEALER_STATUSES.APPROVED_PREVIEW,
	DEALER_STATUSES.ACTIVE,
	DEALER_STATUSES.INACTIVE,
	DEALER_STATUSES.FROZEN,
	DEALER_STATUSES.FROZEN_DEBT,
	DEALER_STATUSES.FROZEN_ENFORCEMENT,
	DEALER_STATUSES.FROZEN_PREVIEW,
	DEALER_STATUSES.PROSPECT,
];

export const ALLOW_EMPTY_AM_STATUSES = [
	DEALER_STATUSES.INCOMPLETE,
	DEALER_STATUSES.PENDING,
	DEALER_STATUSES.UNRESPONSIVE,
];
