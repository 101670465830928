import React from 'react';
import { useUnit } from 'effector-react';

import { userService } from '@core/services/user';

import { Sidebar } from './Sidebar';

export const SidebarContainer = () => {
	const [
		loading,
		{ isLeadManager },
		{
			companyStats: {
				totalVehiclesCurrentlyForSale,
				totalVehiclesMovedToReadyForSaleToday,
				totalVehiclesMovedToSoldToday,
			},
			personalStats: {
				totalVehiclesCurrentlyForSaleForLM,
				totalVehiclesMovedToReadyForSaleTodayForLM,
				totalVehiclesMovedToSoldTodayForLM,
			},
		},
		{ name, profilePicture },
	] = useUnit([userService.getCurrentUserStatsFx.pending, userService.$user, userService.$stats, userService.$info]);

	return (
		<Sidebar
			{...{
				isLeadManager,
				loading,
				name,
				profilePicture,
				stats: [
					[
						totalVehiclesMovedToReadyForSaleTodayForLM,
						totalVehiclesCurrentlyForSaleForLM,
						totalVehiclesMovedToSoldTodayForLM,
					],
					[totalVehiclesMovedToReadyForSaleToday, totalVehiclesCurrentlyForSale, totalVehiclesMovedToSoldToday],
				],
			}}
		/>
	);
};
