import { sample, scopeBind } from 'effector';

import { toast } from '@motorway/mw-highway-code/pre-alpha';

import { NOTIFICATION_STATUSES } from '@core/constants';

import * as events from './events';
import type { AppNotification } from './model';
import { debounceQueued, domain, mergeNotifications } from './utils';

sample({
	clock: events.notify,
	target: domain.effect((notifications) =>
		notifications.forEach((notif) =>
			toast({
				content: notif.message,
				heading: notif.heading,
				variant: notif.type === NOTIFICATION_STATUSES.GLOBAL_ERROR ? NOTIFICATION_STATUSES.ERROR : notif.type,
			}),
		),
	),
});

sample({
	clock: events.instantPush,
	target: domain.effect((notif) => toast({ content: notif.message, heading: notif.heading, variant: notif.type })),
});

const queue = debounceQueued<AppNotification>(1000, {
	maxWait: 2000,
	queueStrategy: mergeNotifications,
});

/**
 * Debounce, merge and queue notification
 */
events.push.watch((item) => queue({ fn: scopeBind(events.notify, { safe: true }), item }));
