import { SLUGS } from '@motorway/mw-enquiry-states';

import { PAGINATION_PARAMS } from '@core/constants';

import { IS_TRADE_FIELD_NAME } from '../vehicles';

export const SORT_VEHICLES_PROFILE_PARAMS = { _order: 'DESC', _sort: 'leadScore' };

export const DEFAULT_PRIORITISED_VEHICLES_LIST_PARAMS = {
	_end: 25,
	_include: ['notes', 'closingBid'],
	...SORT_VEHICLES_PROFILE_PARAMS,
	_start: 0,
	filterToProfile: true,
	[IS_TRADE_FIELD_NAME]: false, // TODO: remove this field once BE add permission control for trade vehicles
	state: undefined,
};

export const TO_CLOSE_CATEGORY = [
	SLUGS.FOR_SALE,
	SLUGS.SALE_ACTIVE,
	SLUGS.UNDER_OFFER,
	SLUGS.UNSOLD_NO_BIDS,
	SLUGS.UNSOLD_BELOW_RESERVE_BID,
];

export const ALLOWED_VEHICLES_LIST_PARAMS = [
	...PAGINATION_PARAMS,
	'filterToProfile',
	'_include',
	'state',
	'leadManagerId',
	'keyword',
	IS_TRADE_FIELD_NAME, // TODO: remove this field once BE add permission control for trade vehicles
];

export const SORT_VEHICLE_CLOSE_PARAMS = {
	_order: 'ASC,DESC',
	_sort: 'state.priorityViewOrder,updatedAt',
};

export const FILTER_TO_CLOSE_PARAMS = {
	...SORT_VEHICLE_CLOSE_PARAMS,
	_end: 25,
	_include: ['notes', 'closingBid'],
	_start: 0,
	filterToProfile: false,
	[IS_TRADE_FIELD_NAME]: false, // TODO: remove this field once BE add permission control for trade vehicles
	state: TO_CLOSE_CATEGORY,
};
