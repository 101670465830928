import './side-effects';

import * as effects from './effects';
import * as store from './store';

export * from './model';

export const financeSettlementService = {
	...effects,
	...store,
};
