import { sample } from 'effector';

import { notifyManyOnDoneFx } from '@core/utils/side-effects';

import * as app from '../app/internal';

import * as store from './dealer.store';
import * as effect from './effects';

// Notify on success
notifyManyOnDoneFx([
	[effect.createDealerFx.done, 'Dealer created'],
	[effect.deleteDealerFx.done, 'Dealer deleted'],
	[effect.createDealerContactFx.done, 'Contact created'],
	[effect.updateDealerContactFx.done, 'Contact updated'],
	[effect.deleteDealerContactFx.done, 'Contact deleted'],
	[effect.sendDealerActivationSMSFx.done, 'Activation SMS triggered'],
	[effect.createNoteFx.done, 'Note successfully added'],
]);

// Update dealer contacts on changes and clean up modals
sample({
	clock: [effect.deleteDealerContactFx.done],
	fn: ({ id }) => id,
	source: store.$dealer,
	target: [effect.getDealerFx, app.closeAllModals],
});

// Get dealer statuses
sample({
	fn: ({ statusId }) => ({ fromStatusId: statusId }),
	source: store.$dealer,
	target: effect.getDealerStatusesFx,
});

// Update dealer on phone confirmed
sample({
	clock: effect.confirmDealerOtpPhoneNumberFx.done,
	fn: ({ params }) => params,
	source: effect.getDealerFx.done,
	target: effect.getDealerFx,
});

// Get dealer kyc status on update completed
sample({
	clock: effect.updateDealerKycStatusFx.done,
	fn: ({ params }) => params,
	source: effect.getDealerKycStatusFx.done,
	target: effect.getDealerKycStatusFx,
});
