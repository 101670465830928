import type { Effect } from 'effector';

import type { JSENDAPIResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';

import type {
	CreateManualPaymentPayload,
	CreateManualPaymentResponse,
	CreatePostSaleOfferPayload,
	HistoryLog,
	Payment,
	PostSaleOffer,
	UpdatePostSaleOfferDescPayload,
} from './model';
import { domain } from './utils';

export const getPostSaleOffersFx: Effect<number, JSENDAPIResponse<PostSaleOffer[]>> = domain.effect({
	handler: async (enquiryId) => {
		const res: JSENDAPIResponse<PostSaleOffer[]> = await request({
			method: 'get',
			params: { enquiryId },
			url: '/payments/postSaleOffers',
		} as RequestConfig);
		return res;
	},
});

export const createPostSaleOffersFx: Effect<
	CreatePostSaleOfferPayload,
	JSENDAPIResponse<PostSaleOffer>
> = domain.effect({
	handler: ({ amount, enquiryId }) =>
		request({
			data: { amount, enquiryId },
			method: 'post',
			url: '/payments/postSaleOffers/create',
		} as RequestConfig),
});

export const getPaymentsFx: Effect<number, JSENDAPIResponse<Payment[]>> = domain.effect({
	handler: async (enquiryId) =>
		request({
			method: 'get',
			params: { enquiryId },
			url: '/payments/payments/statuses',
		} as RequestConfig),
});

export const createManualPaymentFx: Effect<
	CreateManualPaymentPayload,
	JSENDAPIResponse<CreateManualPaymentResponse>
> = domain.effect({
	handler: (payload) =>
		request({
			data: payload,
			method: 'post',
			url: '/payments/payments/manual/create',
		} as RequestConfig),
});

export const sendNewOfferSMSFx: Effect<number, undefined> = domain.effect({
	handler: async (enquiryId) =>
		request({
			data: { enquiryId },
			method: 'post',
			url: '/payments/notifications/seller/sendNewOfferSMS',
		} as RequestConfig),
});

export const getPaymentsHistoryLogFx: Effect<number, JSENDAPIResponse<HistoryLog[]>> = domain.effect({
	handler: async (enquiryId) =>
		request({
			method: 'get',
			params: { enquiryId },
			url: '/payments-audit/auditLogs',
		} as RequestConfig),
});

export const generateOTPFx: Effect<any, any> = domain.effect({
	handler: ({ dealerContactId }) =>
		request({
			data: { dealerContactId, source: 'confirmPhoneNumber' },
			method: 'post',
			url: '/dealer-otp/otp/request',
		} as RequestConfig),
});

export const updateLatestOfferDescFx: Effect<
	UpdatePostSaleOfferDescPayload,
	JSENDAPIResponse<PostSaleOffer>
> = domain.effect({
	handler: ({ damageDescription, enquiryId }) =>
		request({
			data: { damageDescription, enquiryId },
			method: 'post',
			url: '/payments/postSaleOffers/update',
		} as RequestConfig),
});
