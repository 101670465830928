import type { Effect } from 'effector';

import { TIME_24_HOURS } from '@core/constants';
import type { JSENDAPIListResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';

import { CONTENT_CONFIG_NAMES } from './constants';
import type { PrepCostConfigResponse } from './model';
import { domain } from './utils';

/**
 * Get all manual prep cost items
 */
export const getPrepCostConfigFx: Effect<void, JSENDAPIListResponse<PrepCostConfigResponse[]>> = domain.effect({
	handler: () =>
		request({
			cacheTTL: TIME_24_HOURS,
			method: 'get',
			params: {
				names: CONTENT_CONFIG_NAMES.GENERIC_CONFIG,
			},
			url: '/content/contentItems/public',
		} as RequestConfig),
});
