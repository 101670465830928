import React from 'react';
import DoDisturbAltRoundedIcon from '@mui/icons-material/DoDisturbAltRounded';
import { Box } from '@mui/material';

export const NoPermissions = () => (
	<Box
		sx={{
			'& h2': {
				alignItems: 'center',
				color: 'red',
				display: 'flex',
				fontSize: '3rem',
			},
			'& svg': {
				height: '3rem',
				marginRight: 2,
				width: '3rem',
			},
			alignItems: 'center',
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'center',
		}}
	>
		<h2 data-test="no-permissions">
			<DoDisturbAltRoundedIcon /> Permission denied
		</h2>
	</Box>
);
