import React, { forwardRef, useEffect, useState } from 'react';
import { Avatar, Slide } from '@mui/material';
import type { TransitionProps } from '@mui/material/transitions';
import { useUnit } from 'effector-react';

import { Dialog } from '@core/components/Dialog';
import { userService } from '@core/services/user';
import { initials } from '@core/utils/formatter';

import { SidebarContainer } from './Sidebar.container';

import styles from './Profile.module.scss';

const Transition = forwardRef(
	(
		{
			children,
			...rest
		}: TransitionProps & {
			children: React.ReactElement<any, any>;
		},
		ref: React.Ref<unknown>,
	) => (
		<Slide ref={ref} direction="left" {...rest}>
			{children}
		</Slide>
	),
);

export const Profile = () => {
	const [isOpen, setOpen] = useState(false);
	const { name, profilePicture } = useUnit(userService.$info);

	useEffect(() => {
		isOpen && userService.getCurrentUserStatsFx({});
	}, [isOpen]);

	return (
		<>
			<button className={styles.root} data-test="user-profile-link" onClick={() => setOpen(true)} type="button">
				<Avatar src={profilePicture}>{initials(name)}</Avatar>
				<span className={styles.avatarName}>{name}</span>
			</button>
			<Dialog
				hideCancelButton
				hideConfirmButton
				keepMounted
				PaperProps={{
					classes: {
						root: styles.rootModal,
					},
				}}
				TransitionComponent={Transition}
				classes={{
					scrollBody: styles.scrollBody,
				}}
				onClose={() => setOpen(false)}
				open={Boolean(isOpen)}
			>
				<SidebarContainer />
			</Dialog>
		</>
	);
};
