import { datadogRum } from '@datadog/browser-rum';
import { setUserId } from '@snowplow/browser-tracker';
import { sample } from 'effector';

import * as agent from '../agent/internal';
import * as ga from '../analytics/internal';
import * as app from '../app/internal';

import * as effects from './effects';
import type { UserInfo } from './model';
import * as store from './store';
import { domain } from './utils';

/**
 * Trigger get info on app init if no user info
 */
sample({
	clock: app.init,
	filter: ({ id }) => !id,
	source: store.$info,
	target: effects.getUserInfoFx,
});

/**
 * Configure GA on user info update
 */
sample({
	clock: effects.getUserInfoFx.doneData,
	fn: ({ id }) => id,
	target: ga.setConfigFx,
});

/**
 * Snowplow - User ID
 */
sample({
	clock: effects.getUserInfoFx.doneData,
	fn: ({ id }) => id,
	target: domain.effect((id) => {
		if (id) {
			setUserId(`${id}`);
		}
	}),
});

/**
 * Update user info on user update
 */
sample({
	clock: agent.updateAgentFx.doneData,
	filter: (current, updated) => current.id === updated?.data?.id,
	source: store.$info,
	target: effects.getUserInfoFx,
});

// Reset app on logout
sample({
	clock: effects.logoutFx.finally,
	target: [app.reset],
});

/**
 * Configure Datadog on user info update
 */
sample({
	clock: effects.getUserInfoFx.doneData,
	target: domain.effect((info: UserInfo) => {
		const { computedPermissions, id, name, role } = info;
		datadogRum.setUser({
			id: String(id),
			name,
			permissions: computedPermissions,
			role: role.name,
		});
	}),
});
