import './side-effects';

import { endSession, setSession } from './events';
import { $expires, $isLoggedIn, isLoggedIn } from './store';

export const authService = {
	$expires,
	$isLoggedIn,
	endSession,
	isLoggedIn,
	setSession,
};
