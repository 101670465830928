import { sample } from 'effector';

import * as vehicle from '../vehicle/internal';

import * as effects from './effects';

/**
 * Trigger get bids after bid state update
 */
sample({
	clock: effects.updateBidStateFx.done,
	fn: ({ params }) => params,
	source: effects.getBidsEx.done,
	target: effects.getBidsEx.fx,
});

/**
 * Update enquiry state on bid update
 */
sample({
	clock: effects.updateBidStateFx.doneData,
	fn: (list, { vehicleStatus }) => ({
		state: list.find(({ id }) => id === vehicleStatus),
		stateId: vehicleStatus,
	}),
	source: vehicle.$vehicleStatuses,
	target: vehicle.updateEnquiry,
});
