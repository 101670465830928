import React, { useContext } from 'react';
import cx from 'classnames';
import { Card, CardContent } from '@mui/material';
import { isString } from 'lodash-es';

import { SearchContext } from '../SearchContext';

import { getViewInfo } from './parts.helpers';
import { Message } from './SearchComponents';
import SearchResults from './SearchResults';

import styles from './SearchCommon.module.scss';

const SearchContent = ({ error, isLoading, vehicleData }) => {
	const {
		inputState: { isMinimumLength, value },
		isOpen,
		isReducedMotion,
	} = useContext(SearchContext);

	const { isFallbackView } = getViewInfo(isLoading, vehicleData?.list);

	// this is there to hide results box if we don't have any meaningful view
	// when user clicks input haven't send any search yet
	const isNotTouched = !vehicleData && !isLoading;

	// this is there to hide results box if we don't have results view
	// and user has less than minimum specified characters in input
	const hasFallbackViewAndNotMuchInInputField = !isMinimumLength || (!isMinimumLength && isFallbackView);

	// delay slide up/down transition if we have results, but there's nothing in input
	// we need this to allow input transition to run first :)
	const delaySlide = vehicleData?.total && isString(value) && value === '';

	return (
		<div className={styles.resultsContainer}>
			<div
				className={cx(styles.resultsBox, {
					[styles.resultsBoxEmpty]: !vehicleData?.total,
					[styles.resultsBoxHidden]: !isOpen || isNotTouched || hasFallbackViewAndNotMuchInInputField,
					[styles.resultsBoxSlideDelay]: delaySlide,
					[styles.resultsBoxReducedMotion]: isReducedMotion,
				})}
			>
				<Card>
					<CardContent>
						{error ? (
							<Message>
								<span data-test="search-field-error">
									An error has occurred processing your request, please try to search again
								</span>
							</Message>
						) : (
							<SearchResults isLoading={isLoading} vehicles={vehicleData?.list} />
						)}
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

export default SearchContent;
