import type { Effect } from 'effector';
import { sample } from 'effector';

import { captureException } from '@core/utils/exceptions';
import { imageAutoParam } from '@core/utils/url';

// @ts-ignore
import Worker from './cache.worker';
import { domain } from './utils';

const worker = Worker();

const getBlobUrlFx: Effect<string, string> = domain.effect({
	handler: (url) =>
		new Promise((resolve, reject) => {
			const imageSrc = imageAutoParam(url);
			const retrieveBlobUrl = ({ data: { blobUrl, error, fingerprint, url: resUrl } }) => {
				if (resUrl === imageSrc) {
					blobUrl && resolve(blobUrl);
					if (error) {
						captureException({
							context: {
								error,
								url: resUrl,
							},
							error,
							fingerprint,
							tags: {
								...(error?.responseText ? { responseText: error?.responseText } : {}),
							},
							title: `Error on image loading: [${fingerprint}] ${error.status ? error.status : ''}`,
						});
						reject(error);
					}
					worker.removeEventListener('message', retrieveBlobUrl);
				}
			};
			worker.onerror = reject;
			worker.addEventListener('message', retrieveBlobUrl);
			worker.postMessage({ url: imageSrc });
			setTimeout(() => {
				worker.removeEventListener('message', retrieveBlobUrl);
				reject();
			}, 60000);
		}),
});

const defineUrl = domain.event<string>();
const set = domain.event<{ blobUrl: string; url: string }>();
const reset = domain.event();

const initialState = {};
const $cache = domain
	.store<Record<string, string>>(initialState)
	.on(getBlobUrlFx, (cache, url) => ({ ...cache, [url]: url }))
	.on(getBlobUrlFx.done, (cache, { params, result }) => ({
		...cache,
		[params]: result,
	}))
	.on(set, (cache, { blobUrl, url }) => ({ ...cache, [url]: blobUrl }))
	.reset(reset);

sample({
	clock: defineUrl,
	filter: (cache, url) => !!url && !cache[url],
	fn: (_, url) => url,
	source: $cache,
	target: getBlobUrlFx,
});

export const cache = {
	$: $cache,
	addUrls: (urls: any[]) =>
		urls.filter(Boolean).forEach((url, index) => setTimeout(() => defineUrl(url), index * 1000)),
	cacheBlob: ({ blob, url }: { blob: Blob; url: string }) => set({ blobUrl: URL.createObjectURL(blob), url }),
	reset,
};
