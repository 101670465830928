import React from 'react';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import cx from 'classnames';

import { VersionAlert } from '@containers/VersionAlert';

import { DashboardAppBar } from './DashboardAppBar';
import { DashboardFooter } from './DashboardFooter';

import styles from './Dashboard.module.scss';

export const Dashboard = () => {
	const location = useLocation();
	const path = location.pathname.split('/');
	const pillarBox = path.length > 2;

	return (
		<div className={styles.root}>
			<VersionAlert />
			<div className={cx('motorway-theme', styles.themeWrapper)}>
				<DashboardAppBar />
				<main className={styles.contentWithSidebar}>
					<div className={cx(styles.content, { [styles.list]: !pillarBox })}>
						<Outlet />
					</div>
				</main>
			</div>
			<DashboardFooter />
		</div>
	);
};
