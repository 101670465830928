import { combine } from 'effector';

import { SLUGS } from '@motorway/mw-enquiry-states';

import { BIDDING_ALLOWED_STATES } from '@core/constants/vehiclesConsts';
import type { ListResponse } from '@core/model';
import { ROLES } from '@core/utils/permissions';
import { getBidTypeForNewVehicle, hasVehicleState } from '@core/utils/vehicleCheckers';

import * as app from '../app/internal';
import * as user from '../user/internal';
import type { EnquiryBid } from '../vehicle/internal';
import * as enquiry from '../vehicle/internal';

import * as effects from './effects';
import { domain, isProxyNewBid } from './utils';

/**
 * Bids store
 */
export const $allBids = domain
	.store<ListResponse<EnquiryBid>>({ list: [], total: null })
	.on(effects.getBidsEx.doneData, (_, bids) => bids)
	.reset([enquiry.$enquiryId, app.reset]);

/**
 * Bid store - filtered
 */
export const $bids = combine(
	[$allBids, user.$user, enquiry.$enquiry],
	([
		bids,
		{
			info: { id },
			role,
		},
		vehicle,
	]) => {
		if (
			[SLUGS.SALE_ACTIVE].includes(vehicle?.state?.slug) &&
			[ROLES.ACCOUNT_MANAGER, ROLES.DEALER_EXPERIENCE_SPECIALIST].includes(role)
		) {
			const bidsList = bids.list.filter((bid) => bid.accountManager.id === id);
			return { list: bidsList, total: bidsList.length };
		}

		return bids;
	},
);

/**
 * Bid status store
 */
export const $bidStates = combine(enquiry.$enquiry, user.$user, (vehicle, { canSeeAllBidsOnSale }) => ({
	allowedState: BIDDING_ALLOWED_STATES.includes(vehicle?.state?.slug || ''),
	bidType: getBidTypeForNewVehicle(vehicle),
	canSeeWhenSaleActive: hasVehicleState(vehicle, [SLUGS.SALE_ACTIVE]) ? canSeeAllBidsOnSale : true,
	isProxyBid: isProxyNewBid(vehicle),
	saleActive: hasVehicleState(vehicle, [SLUGS.SALE_ACTIVE]),
}));
