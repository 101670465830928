import './side-effects';
import './page-activity';

import * as commonStore from './common.store';
import * as effects from './effects';
import * as events from './events';
import * as locationStore from './location.store';
import { $modals } from './modals.store';
import * as tab from './tab';
import * as ui from './ui';

export * from './model';

export * as currentPage from './page';

export const appService = {
	$modals,
	...locationStore,
	...commonStore,
	...effects,
	...events,
	...tab,
	...ui,
};
