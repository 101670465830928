import { sample } from 'effector';

import { captureException, TEAM } from '@core/utils/exceptions';
import { notifyManyOnDoneFx } from '@core/utils/side-effects';

import * as enquiry from '../vehicle/internal';

import * as effects from './effects';

sample({
	clock: [effects.getDocumentsEx.done, effects.updateDocumentMetaFx.done],
	filter: (source) => Boolean(source),
	source: enquiry.$enquiryId,
	target: effects.getStateDocumentsEx.fx,
});

// TODO: remove when switching to new UI, only used in legacy UI
sample({
	clock: effects.updateDocumentMetaFx.done,
	fn: ({ params }) => params.enquiryId,
	target: effects.getStateDocumentsEx.fx,
});

effects.updateDocumentMetaFx.fail.watch(({ error, params }) => {
	captureException({
		error,
		params,
		team: TEAM.THUNDERBIRDS_FE,
		title: 'Failed to update documents meta',
	});
});

notifyManyOnDoneFx([
	[effects.deleteDocumentFx.done, 'Document has been deleted successfully'],
	[effects.uploadDocumentEx.done, 'Document has been uploaded successfully'],
]);
