import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { useUnit } from 'effector-react';

import { APP_ROUTER } from '@core/router/routerConfig';
import { appService } from '@core/services/app';
import { automationService } from '@core/services/automation';
import { theme as mui5theme } from '@core/theme';
import { UnderMaintenance } from '@pages/UnderMaintenance';
import { Toaster } from '@THC/components/Toast';

appService.init();

export const Root = () => {
	const { enabled, text, title } = useUnit(automationService.$underMaintenance);
	return !enabled ? (
		<ThemeProvider theme={mui5theme}>
			<RouterProvider router={APP_ROUTER} />
			<Toaster data-test="notification-message" />
		</ThemeProvider>
	) : (
		<UnderMaintenance text={text} title={title} />
	);
};
