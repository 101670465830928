import React, { useCallback, useEffect } from 'react';
import { useRouteError } from 'react-router';
import { datadogRum } from '@datadog/browser-rum';

import { NOTIFICATION_STATUSES } from '@core/constants';
import { notificationService } from '@core/services/notifications';
import { copyToClipboard } from '@core/utils/dataHelpers';
import { Button } from '@THC/components/Button';

import styles from './JSError.module.scss';

const Logo = () => (
	<svg height="33" viewBox="0 0 160 33" width="160" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M154.72 8.27c0 1.5 1.18 2.72 2.64 2.72A2.68 2.68 0 00160 8.27c0-1.5-1.18-2.72-2.64-2.72a2.68 2.68 0 00-2.64 2.72zm-8.42-2.49h-5.57l7.62 13.61L140.73 33h5.57l7.62-13.6-7.62-13.62zm-10.84 12.4c0 1.64-1.26 2.7-3.24 2.7-1.67 0-2.81-.9-2.81-2.2 0-1.1.76-1.72 2.62-2.15 1.18-.28 2.13-.36 3.43-.43v2.07zm4.79.11v-6.07c0-2.95-.46-3.85-1.9-5.02-1.3-1.1-3.12-1.65-5.44-1.65-2.72 0-4.92.6-7.33 2.03l2.1 3.73a11 11 0 015.11-1.57c1.18 0 2.1.44 2.44 1.18.19.43.19.28.19 1.57-4.11.27-6.77.82-8.4 1.8a5.05 5.05 0 00-2.36 4.4c0 3.48 3.08 6.19 7.07 6.19 2.09 0 3.42-.63 4.45-2.04.95 1.3 2.16 1.96 3.5 1.96h.4l1.99-3.57c-1.56-.35-1.82-.82-1.82-2.94zm-78.03 1.3c-.9.23-1.31.27-1.92.27-1.79 0-2.28-.63-2.28-2.9V9.82h4.2V5.78h-4.2V0h-4.83v5.78h-2.82v4.04h2.82v9.26c0 2.12.15 2.86.72 3.65.76 1.05 2.32 1.64 4.45 1.64 1.42 0 2.7-.17 3.86-.54v-4.24zm6.05-4.38c0 3.07 2.05 5.22 4.78 5.22 2.74 0 4.8-2.15 4.8-5.22 0-3.06-2.06-5.2-4.8-5.2-2.73 0-4.78 2.14-4.78 5.2zm14.27 0c0 5.54-4.03 9.67-9.49 9.67-5.43 0-9.44-4.13-9.44-9.67 0-5.53 4-9.66 9.44-9.66 5.46 0 9.5 4.13 9.5 9.66zm-47.25 0c0 3.07 2.05 5.22 4.78 5.22 2.74 0 4.8-2.15 4.8-5.22 0-3.06-2.06-5.2-4.8-5.2-2.73 0-4.78 2.14-4.78 5.2zm14.27 0c0 5.54-4.03 9.67-9.49 9.67-5.43 0-9.44-4.13-9.44-9.67 0-5.53 4-9.66 9.44-9.66 5.46 0 9.49 4.13 9.49 9.66zm69.64 9.12h-5.62l-2.85-11.17-2.59 11.17h-5.86l-4.9-18.55h4.9l3 12.55 2.86-12.55h5.13l3.08 12.55 2.85-12.55h4.99l-4.99 18.55zm-34.37 0V5.78h4.49l.26 2.08c1.49-1.6 2.74-2.31 4.23-2.31.53 0 .98.04 1.78.23v4.67c-.3-.04-.57-.04-.64-.04-1.75 0-2.9.35-3.88 1.22-1.22 1.02-1.49 1.88-1.49 4.47v8.23h-4.75zm-61.67 0V14.3c0-2.94-1-4.27-3.16-4.27-1.22 0-2.32.59-2.97 1.6-.53.87-.72 1.77-.72 3.46v9.25h-4.75V14.8c0-1.6-.2-2.62-.57-3.33a3.08 3.08 0 00-2.66-1.41c-2.28 0-3.62 1.64-3.62 4.55v9.72H0V5.78h4.45l.26 1.53c1.75-1.3 3-1.76 4.72-1.76 2.24 0 3.88.82 5.7 2.82 2.06-2 3.77-2.82 5.94-2.82 2.4 0 4.4.98 5.66 2.78.95 1.38 1.22 2.47 1.22 5.18v10.82h-4.8z"
			fill="#1D1D1B"
		/>
	</svg>
);

export const JSError = () => {
	const routerError: any = useRouteError();

	useEffect(() => {
		if (routerError) {
			datadogRum.addError(routerError);
		}
	}, [routerError]);

	const copyTextToClipboard = useCallback((text) => {
		copyToClipboard(text).then(() => {
			notificationService.push({
				message: 'Copying to clipboard was successful',
				type: NOTIFICATION_STATUSES.SUCCESS,
			});
		});
	}, []);

	return (
		<div className={styles.errorBoundary}>
			<div>
				<div className={styles.errorBoundaryLogo}>
					<a href="/">
						<Logo />
					</a>
				</div>
				<h2 className={styles.title}>Whoops, something has gone wrong</h2>
				<div className={styles.button}>
					<Button
						as="button"
						label="Copy error"
						onClick={() => copyTextToClipboard(routerError.stack)}
						variant="secondary"
					/>
					<textarea
						readOnly
						className={styles.textarea}
						defaultValue={`${window.location.href}\n${routerError.message}\n${routerError.stack}`}
					/>
				</div>
			</div>
		</div>
	);
};
