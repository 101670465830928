import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { renderTableDate } from '@core/components/GridRenderers/renderDate';
import { renderSearchHighligth } from '@core/components/GridRenderers/renderSearchHighligth';
import { renderVRM } from '@core/components/GridRenderers/renderVRM';
import type { TableColumn } from '@core/components/Table';
import { Table } from '@core/components/Table';
import type { ISearchVehicle } from '@core/services/vehicles';

import { SearchContext } from '../SearchContext';

export const columns: TableColumn<ISearchVehicle>[] = [
	{
		cellClassName: 'col-pad-l-3',
		field: 'updatedAt',
		renderCell: renderTableDate,
		sortable: false,
	},
	{
		cellClassName: 'col-pad-r-1',
		field: 'prettyVrm',
		renderCell: renderVRM,
		sortable: false,
	},
	{
		field: 'vehicle.make.name',
		sortable: false,
	},
	{
		field: 'seller.name',
		renderCell: renderSearchHighligth,
		sortable: false,
	},
	{
		field: 'seller.mobile',
		renderCell: renderSearchHighligth,
		sortable: false,
	},
];

export const SearchTable = ({ vehicles }) => {
	const { close } = useContext(SearchContext);
	const navigate = useNavigate();

	const handleOnRowClick = (row: ISearchVehicle) => {
		close({ reducedMotion: true });
		navigate(`/vehicles/${row.id}`);
	};

	return (
		<Table
			hideHeader
			columns={columns}
			data-test="search-table-result"
			noOverlay="No results to display"
			onRowClick={handleOnRowClick}
			rows={vehicles}
		/>
	);
};
