import type { AppNotification } from './model';
import { domain } from './utils';

// Push notification
export const notify = domain.event<AppNotification[]>();
// Debounced push
export const push = domain.event<AppNotification>();
// Direct push
export const instantPush = domain.event<AppNotification>();
// Reset notifications
export const reset = domain.event();
// Clear redirect notification
export const resetAfterRedirect = domain.event();
