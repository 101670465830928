import type { Store } from 'effector';

import { RELEASE_VERSION } from '@core/app-state';
import { persistStore } from '@core/utils/store';

import { UNDER_MAINTENANCE } from './constants';
import * as effects from './effects';
import type { UnderMaintenance } from './model';
import { domain } from './utils';

/**
 * Under maintenance store
 */
export const $underMaintenance: Store<UnderMaintenance['agentDashboard']> = persistStore(
	domain.store(UNDER_MAINTENANCE),
	{
		key: 'agent.maintenance',
		sync: true,
	},
).on(effects.getUnderMaintenanceFx.doneData, (_, { data: [config] = [] }) => config?.content?.agentDashboard || {});

/**
 * Has new version store
 */
export const $hasNewVersion = domain.store<boolean>(false).on(effects.getVersionFx.doneData, (_, { data }) => {
	const [, version] = data?.match(/RELEASE_VERSION=(.*)/) || [];
	return Boolean(RELEASE_VERSION && version !== RELEASE_VERSION);
});
