import type { AxiosInstance } from 'axios';
import axios from 'axios';

import { HEADER_NAMES } from '@motorway/motorway-api-client/lib/utilities/const';

import { APP_REQUEST_HEADERS, BACKEND_URL, PROVIDERS_VERSION_HEADER, VERSION_REQUEST_HEADERS } from '@core/app-state';

/**
 * Create initial axios instance
 */
export const axiosInstance: AxiosInstance = axios.create({
	baseURL: BACKEND_URL,
	headers: {
		'Content-Type': 'application/json',
		[HEADER_NAMES.VERSION]: VERSION_REQUEST_HEADERS,
		[HEADER_NAMES.APP]: APP_REQUEST_HEADERS,
		...(PROVIDERS_VERSION_HEADER && { [HEADER_NAMES.PROVIDERS_VERSIONS]: PROVIDERS_VERSION_HEADER }),
	},
	withCredentials: true,
});
