import { sample } from 'effector';

import * as user from '../user/internal';
import * as vehicle from '../vehicle/internal';

import * as effects from './effects';
import * as events from './events';

/**
 * Generic GA event
 */
sample({
	clock: events.sendGA4Event,
	fn: (info, { data, name }) => ({ data: { ...data, userId: info?.id }, name }),
	source: user.$info,
	target: effects.ga4EventFx,
});

/**
 * Event with enquiry id and state
 */
sample({
	clock: events.sendGA4EnquiryEvent,
	filter: ({ enquiry }) => !!enquiry?.id,
	fn: ({ enquiry, userInfo }, { data, name }) => ({
		data: { enquiryId: enquiry.id, statusId: enquiry.state.slug, userId: userInfo.id, ...data },
		name,
	}),
	source: { enquiry: vehicle.$enquiry, userInfo: user.$info },
	target: effects.ga4EventFx,
});

/**
 * Time on page event
 */
sample({
	clock: events.sendGA4TimeOnPageEvent,
	fn: ({ id, state }, page_name) => ({
		data: {
			page_name,
			value: 1,
			...(page_name === 'Vehicle' && { enquiryId: id, statusId: state?.slug }),
		},
		name: 'Time on Page',
	}),
	source: vehicle.$enquiry,
	target: effects.ga4EventFx,
});

/**
 * GA4 - Photo action event
 */
sample({
	clock: events.sendPhotoActionEvent,
	fn: ({ action, meta, photo }) => ({
		data: {
			image_type: `${photo.name} - ${photo.modifiedUrl ? 'modified' : 'default'}`,
		},
		name: `${meta.prefix(photo.url?.toLowerCase()?.includes('.pdf'))} ${action}`,
	}),
	target: events.sendGA4EnquiryEvent,
});

/**
 * GA4 - Open gallery event
 */
sample({
	clock: events.sendGalleryOpenEvent,
	fn: () => ({
		name: 'Gallery opened',
	}),
	target: events.sendGA4EnquiryEvent,
});

/**
 * GA4 - Gallery navigation event
 */
sample({
	clock: events.sendGalleryClickEvent,
	fn: ({ direction }) => ({
		name: `Gallery ${direction} click`,
	}),
	target: events.sendGA4EnquiryEvent,
});

/**
 * GA4 - QA validation warning event
 */
sample({
	clock: events.sendQAValidationWarningEvent,
	fn: ({ numberOfErrors }) => ({
		data: {
			number_of_errors: numberOfErrors,
		},
		name: 'QA Validation Warning',
	}),
	target: events.sendGA4EnquiryEvent,
});

/**
 * GA4 - QA validation overridden event
 */
sample({
	clock: events.sendQAValidationOverriddenEvent,
	fn: () => ({
		name: 'QA Validation Overridden',
	}),
	target: events.sendGA4EnquiryEvent,
});

/**
 * GA4 - Request resupply modal open event
 */
sample({
	clock: events.sendRequestResupplyModalOpenEvent,
	fn: () => ({
		name: 'Request Resupply Modal Open',
	}),
	target: events.sendGA4EnquiryEvent,
});

/**
 * GA4 - Resupply requested event
 */
sample({
	clock: events.sendResupplyRequestedEvent,
	fn: () => ({
		name: 'Requested Resupply',
	}),
	target: events.sendGA4EnquiryEvent,
});

/**
 * GA4 - Image for resupply selected event
 */
sample({
	clock: events.sendImageResupplySelectedEvent,
	fn: ({ kind }) => ({
		data: {
			kind: kind.name,
		},
		name: 'Request Image Resupply Selected',
	}),
	target: events.sendGA4EnquiryEvent,
});

/**
 * GA4 - Resupply docs request event
 */
sample({
	clock: events.sendResupplyDocsRequestEvent,
	fn: ({ name }) => ({
		name: `${name} selected`,
	}),
	target: events.sendGA4EnquiryEvent,
});

/**
 * GA4 - Manual payment
 */
sample({
	clock: events.manualPaymentEventClick,
	fn: ({ button_label, label, name }) => ({
		button_label,
		category: 'Manual payment',
		label,
		name,
	}),
	target: events.sendGA4EnquiryEvent,
});

/**
 * GA4 - Manual payment
 */
sample({
	clock: events.manualPaymentEventCtaClicked,
	fn: ({ button_label, label, name }) => ({
		button_label,
		category: 'Manual payment',
		label,
		name,
	}),
	target: events.sendGA4EnquiryEvent,
});
