import React from 'react';

import type { TableParams } from '@core/components/Table';
import { VRMField } from '@core/components/VRMField';
import { VRMColorClassName } from '@core/components/VRMField/VRMField.module';
import type { IDealer } from '@core/services/dealers';
import type { IPrioritisedVehicle } from '@core/services/prioritisedVehicles';
import type { IListVehicle, ISearchVehicle } from '@core/services/vehicles';
import type { SelfServeChasingLead } from '@services/selfServe';

export const renderVRM = (
	params: TableParams<IListVehicle | IDealer | IPrioritisedVehicle | ISearchVehicle | SelfServeChasingLead>,
) => {
	const colorClassName = (params.row as IListVehicle)?.isTrade ? VRMColorClassName.trade : VRMColorClassName.default;
	return (
		<VRMField colorClassName={colorClassName} id={params.row.id}>
			{params.value}
		</VRMField>
	);
};
