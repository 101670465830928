import { combine } from 'effector';
import { intersection, reduce } from 'lodash-es';

import type { VEHICLE_SECTIONS } from '@core/constants/vehiclePage';
import { flattenObject } from '@core/utils/dataHelpers';
import { RELATED_TO_FORM_FIELDS } from '@pages/Vehicle/VehiclePage.constants';

import * as effects from './effects';
import { ErrorsMapper } from './ErrorsMapper';
import * as events from './events';
import type { EnquiryError, FormMessage } from './model';
import * as page from './page';
import { domain } from './utils';
import { $vehicleSectionsOrder } from './visible.store';

const errorsMapper = new ErrorsMapper();

export const $validateErrors = domain
	.store<EnquiryError[]>([])
	.on(effects.validateStateChangeFx.doneData, (_, data) => data)
	.on(events.clearSubmitErrors, (state, clearErrors) =>
		state.filter((enq) => !clearErrors.includes(errorsMapper.getKey(enq))),
	)
	.reset([events.resetSubmitErrors, page.reset]);

export const $formsValidateErrors = $validateErrors.map((data) => errorsMapper.map(data));

export const $formsValidateErrorsMap = $formsValidateErrors.map(
	(validationErrors) =>
		new Map(
			reduce(
				flattenObject(validationErrors),
				(acc: Array<[string, FormMessage]>, value: string, key: string) => {
					acc.push([key, { message: value, type: 'error' }]);
					return acc;
				},
				[],
			),
		),
);

export const $formsValidateErrorsPerSection = combine($validateErrors, $vehicleSectionsOrder, (data, sections) => {
	const mapperFields: [VEHICLE_SECTIONS, boolean][] = sections.map((name) => {
		if (!RELATED_TO_FORM_FIELDS.has(name)) {
			return [name, false];
		}
		const fields = RELATED_TO_FORM_FIELDS.get(name);
		return [name, Boolean(intersection(errorsMapper.keys(data), fields).length)];
	});
	return new Map(mapperFields);
});
