export enum VIEWS {
	EMPTY = 'empty',
	LOADING = 'loading',
	NOTHING_FOUND = 'nothing_found',
	RESULTS = 'results',
}

export const RESULT_CELL_HEIGHT = 82; // 80 + 2px border bottom
export const TOO_MANY_RESULTS_HEIGHT = 72; // 72
export const MAX_RESULTS_HEIGHT = 792;
export const NO_RESULTS_HEIGHT = 216;
export const BODY_SCROLL_VARIABLE = 'body-scroll';
export const UNIVERSAL_SEARCH_RESULTS_MAX_HEIGHT_VARIABLE = 'universal-search-results-max-height';
