import type { Effect } from 'effector';

import type { JSENDAPIListResponse, RequestConfig } from '@core/http/model';
import { request } from '@core/http/request';
import type { ListResponse } from '@core/model';

import type { IAccountManager, IDealerExclusive, ISalesSpecialistManager, ISupportSpecialist } from '../user/internal';

import type { ILogEvent, IMake, IPermission, IRole } from './model';
import { domain } from './utils';

const ONE_DAY = 1000 * 60 * 60 * 24;

const defaultParams = {
	_end: 10000,
	_order: 'ASC',
	_sort: 'name',
	_start: 0,
};

/**
 * Get all roles
 */
export const getRolesFx: Effect<void, IRole[]> = domain.effect({
	handler: () =>
		request({
			cacheTTL: ONE_DAY,
			method: 'get',
			params: {
				include: 'permissions',
				order: 'ASC',
				sort: 'name',
			},
			url: '/agent/roles',
		} as RequestConfig),
});

/**
 * Get all permissions
 */
export const getPermissionsFx: Effect<void, JSENDAPIListResponse<IPermission[]>> = domain.effect({
	handler: () =>
		request({
			cacheTTL: ONE_DAY,
			method: 'get',
			params: {
				order: 'ASC',
				sort: 'displayOrder',
			},
			url: '/agent/permissions',
		} as RequestConfig),
});

/**
 * Get all account managers
 */
export const getAccountManagersFx: Effect<void, JSENDAPIListResponse<IAccountManager[]>> = domain.effect({
	handler: () =>
		request({
			cacheTTL: ONE_DAY,
			method: 'get',
			url: '/agent/accountManagers',
		} as RequestConfig),
});

/**
 * Get all makes
 */
export const getMakesFx: Effect<void, JSENDAPIListResponse<IMake[]>> = domain.effect({
	handler: () =>
		request({
			cacheTTL: ONE_DAY,
			headers: {
				'x-mway-force-jsend': '1', // TODO: Remove after complete v3 route implementation
			},
			method: 'get',
			url: '/premium-v3/makes',
		} as RequestConfig),
});

/**
 * Get all Sales Specialists
 */
export const getSalesSpecialistsFx: Effect<void, JSENDAPIListResponse<ISalesSpecialistManager[]>> = domain.effect({
	handler: () =>
		request({
			cacheTTL: ONE_DAY,
			method: 'get',
			url: '/agent/leadManagers',
		} as RequestConfig),
});

/**
 * Get dealer exclusives
 */
export const getDealerExclusivesFx: Effect<void, ListResponse<IDealerExclusive>> = domain.effect({
	handler: () =>
		request({
			cacheTTL: ONE_DAY,
			method: 'get',
			params: defaultParams,
			url: '/premium/dealer-exclusives',
		} as RequestConfig),
});

/**
 * Get all Sales Specialists
 */
export const getSupportSpecialistsFx: Effect<void, JSENDAPIListResponse<ISupportSpecialist[]>> = domain.effect({
	handler: () =>
		request({
			cacheTTL: ONE_DAY,
			method: 'get',
			url: '/agent/afterSalesAgents',
		} as RequestConfig),
});

/**
 * Send log event
 */
export const sendLogEventFx: Effect<ILogEvent, void> = domain.effect({
	handler: (data) =>
		request({
			data,
			method: 'post',
			url: '/logs/access',
		} as RequestConfig),
});
