import { pickBy } from 'lodash-es';

import { CATEGORIES, enquiryStates, SLUGS } from '@motorway/mw-enquiry-states';

export const BID_STATES = {
	ACCEPTED: 'Accepted',
	CANCELLED: 'Cancelled',
	EXPIRED: 'Expired',
	PENDING: 'Pending',
	REJECTED: 'Rejected',
} as const;

export const BID_TYPES = {
	OFFER: 'offer',
	PROXY: 'proxy',
} as const;

export const BID_ACTIONS = {
	ACCEPT: 'accept',
	CANCEL: 'cancel',
	CREATE: 'create',
	REJECT: 'reject',
} as const;

export const getSlugsByCategory = (category) => Object.keys(pickBy(enquiryStates, (val) => val.category === category));

export const BIDDING_ALLOWED_STATES = Object.freeze([
	SLUGS.FOR_SALE,
	SLUGS.SALE_ACTIVE,
	SLUGS.UNDER_OFFER,
	...getSlugsByCategory(CATEGORIES.UNSOLD),
	...getSlugsByCategory(CATEGORIES.CANCELLED),
]);

export const EDIT_CLOSING_BID_ALLOWED_STATES = Object.freeze([BID_STATES.ACCEPTED, BID_STATES.PENDING]);

export const TIME_TO_CLOSE_ALLOWED_STATES = [SLUGS.FOR_SALE, SLUGS.UNDER_OFFER];

export const BIDS_ALLOWED_STATES = [BID_STATES.ACCEPTED, BID_STATES.PENDING];

export const BODY_TYPE_TO_IMAGES = {
	HATCHBACK: 'hatchback',
	SALOON: 'saloon',
	SUV: 'suv',
};

export const BODY_TYPE_OPTIONS = [
	{
		id: 'Commercial vehicle',
		name: 'Commercial vehicle',
	},
	{
		id: 'Convertible',
		name: 'Convertible',
	},
	{
		id: 'Coupe',
		name: 'Coupe',
	},
	{
		id: 'Estate',
		name: 'Estate',
	},
	{
		id: 'Hatchback',
		name: 'Hatchback',
	},
	{
		id: 'MPV',
		name: 'MPV',
	},
	{
		id: 'Motorcycle',
		name: 'Motorcycle',
	},
	{
		id: 'Motorhome',
		name: 'Motorhome',
	},
	{
		id: 'SUV',
		name: 'SUV',
	},
	{
		id: 'Saloon',
		name: 'Saloon',
	},
	{
		id: 'Van',
		name: 'Van',
	},
	{
		id: 'Other',
		name: 'Other',
	},
];

export const INTERIOR_TYPE_OPTIONS = [
	{
		id: 'Cloth',
		name: 'Cloth',
	},
	{
		id: 'Faux leather',
		name: 'Faux leather',
	},
	{
		id: 'Half leather',
		name: 'Half leather',
	},
	{
		id: 'Half suede',
		name: 'Half suede',
	},
	{
		id: 'Leather',
		name: 'Leather',
	},
	{
		id: 'Suede',
		name: 'Suede',
	},
];

export const EXTERIOR_COLOURS = [
	'Beige',
	'Black',
	'Blue',
	'Bronze',
	'Brown',
	'Cream',
	'Gold',
	'Green',
	'Grey',
	'Maroon',
	'Multi-coloured',
	'Other',
	'Orange',
	'Pink',
	'Purple',
	'Red',
	'Silver',
	'Turquoise',
	'White',
	'Yellow',
].map((colour) => ({ id: colour, name: colour }));

export const TRANSMISSION_OPTIONS = [
	{
		id: 'Automatic',
		name: 'Automatic',
	},
	{
		id: 'CVT',
		name: 'CVT',
	},
	{
		id: 'Manual',
		name: 'Manual',
	},
	{
		id: 'Semi-automatic',
		name: 'Semi-automatic',
	},
	{
		id: 'Other',
		name: 'Other',
	},
];

export const FUELS = {
	DIESEL: 'Diesel',
	ELECTRIC: 'Electric',
	FUEL_CELL: 'Fuel cell',
	GAS: 'Gas',
	GAS_BI_FUEL: 'Gas bi-fuel',
	HYBRID: 'Hybrid',
	OTHER: 'Other',
	PETROL: 'Petrol',
} as const;

export const FUELS_OPTIONS = [
	{
		id: FUELS.DIESEL,
		name: FUELS.DIESEL,
	},
	{
		id: FUELS.ELECTRIC,
		name: FUELS.ELECTRIC,
	},
	{
		id: FUELS.FUEL_CELL,
		name: FUELS.FUEL_CELL,
	},
	{
		id: FUELS.GAS,
		name: FUELS.GAS,
	},
	{
		id: FUELS.GAS_BI_FUEL,
		name: FUELS.GAS_BI_FUEL,
	},
	{
		id: FUELS.HYBRID,
		name: FUELS.HYBRID,
	},
	{
		id: FUELS.PETROL,
		name: FUELS.PETROL,
	},
	{
		id: FUELS.OTHER,
		name: FUELS.OTHER,
	},
];

export const BATTERY_IN_LEASE_OPTIONS = [
	{ id: 'false', name: 'Owned' },
	{ id: 'true', name: 'Leased' },
];

export const EQUIPMENT_OPTIONS = [
	{
		id: 'sat_nav',
		name: 'Sat nav',
	},
	{
		id: 'heated_seats',
		name: 'Heated seats',
	},
	{
		id: 'panoramic_roof',
		name: 'Panoramic roof',
	},
	{
		id: 'parking_cam',
		name: 'Parking camera',
	},
	{
		id: 'sound_system',
		name: 'Upgraded sound system',
	},
];

export const CHARGING_CABLE_OPTIONS = [
	{ id: 'none', name: 'None' },
	{ id: 'fast', name: 'Fast charging' },
	{ id: '3PinDomestic', name: '3-pin domestic' },
];

export const WHEELS_AND_TYRES_TYPES = {
	ALLOYS: 'alloys',
	DIAMOND_CUT: 'diamond_cut',
	HUB_CAPS: 'hub_caps',
	OTHER: 'other',
	STEEL: 'steel',
} as const;

export const WHEELS_AND_TYRES_TYPES_OPTIONS = [
	{
		id: WHEELS_AND_TYRES_TYPES.HUB_CAPS,
		name: 'Hubcap',
	},
	{
		id: WHEELS_AND_TYRES_TYPES.ALLOYS,
		name: 'Alloys',
	},
	{
		id: WHEELS_AND_TYRES_TYPES.DIAMOND_CUT,
		name: 'Alloys - Diamond cut',
	},
	{
		id: WHEELS_AND_TYRES_TYPES.STEEL,
		name: 'Steel',
	},
	{
		id: WHEELS_AND_TYRES_TYPES.OTHER,
		name: 'Other',
	},
];

export const PHOTOS_S3_STAGE_URL_REGEX =
	/(https:\/\/s3\.eu-west-2\.amazonaws\.com\/vehicle-photos-stage|https:\/\/vehicle-photos-stage\.s3\.eu-west-2\.amazonaws\.com)/;
export const PHOTOS_S3_URL_REGEX =
	/(https:\/\/s3\.eu-west-2\.amazonaws\.com\/motorway-vehicle-photos|https:\/\/motorway-vehicle-photos\.s3\.eu-west-2\.amazonaws\.com)/;
