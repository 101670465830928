import { PAGINATION_PARAMS } from '@core/constants';

import type {
	IAssociate,
	IDealerContactPermissionsMap,
	IDealerContactPermissionType,
	IDealerKycDetailsWithAssociates,
} from './model';
import { ASSOCIATE_TYPE } from './model';

export const FULL_ACCESS_PERMISSIONS: IDealerContactPermissionType[] = ['invoicing', 'payments', 'withdraw_funds'];

export const SEND_DEALER_ACTIVATION_SMS = 'SEND_DEALER_ACTIVATION_SMS';

export const DEALER_FORM_NAME = 'dealerForm';

export const INITIAL_PERMISSIONS_STATE: IDealerContactPermissionsMap = {
	invoicing: true,
	payments: false,
	withdraw_funds: false,
};

export const ALLOWED_NOTES_PARAMS = [...PAGINATION_PARAMS, 'enquiryId', 'dealerId'];

export const DEFAULT_NOTES_PARAMS = {
	_end: 25,
	_order: 'DESC',
	_sort: 'createdAt',
	_start: 0,
};

export const DEFAULT_STATUS_LOGS_PARAMS = {
	order: 'desc',
};

export const DEFAULT_DEALER_STATUSES_PARAMS = {
	_end: 10000,
	_order: 'ASC',
	_sort: 'name',
	_start: 0,
};

export const DEFAULT_ASSOCIATE: Partial<IAssociate> = {
	dateOfBirth: '',
	emailAddress: '',
	fullName: '',
	isPrimaryContact: true,
};

export const DEFAULT_KYC_DETAILS: IDealerKycDetailsWithAssociates = {
	associates: {
		[ASSOCIATE_TYPE.DIRECTOR]: [{ ...(DEFAULT_ASSOCIATE as IAssociate), type: ASSOCIATE_TYPE.DIRECTOR }],
		[ASSOCIATE_TYPE.ACCOUNT_MANAGER]: [{ ...(DEFAULT_ASSOCIATE as IAssociate), type: ASSOCIATE_TYPE.ACCOUNT_MANAGER }],
		[ASSOCIATE_TYPE.SHAREHOLDER]: [{ ...(DEFAULT_ASSOCIATE as IAssociate), type: ASSOCIATE_TYPE.SHAREHOLDER }],
	},
	companyRegistrationNumber: '',
	legalName: '',
	registeredAddress: {},
	tradingAddress: {},
	tradingName: '',
	type: '',
};

export const KYC_STATUS_LOG_SOURCE = {
	DASHBOARD: 'Dashboard',
	DEALER_PRO: 'Dealer Pro',
} as const;
