import { PAGINATION_PARAMS } from '@core/constants';

export const IS_TRADE_FIELD_NAME = 'isTrade';

export const DEFAULT_VEHICLES_LIST_PARAMS = {
	_end: 25,
	_include: ['notes', 'closingBid'],
	_order: 'DESC',
	_sort: 'id',
	_start: 0,
	[IS_TRADE_FIELD_NAME]: false, // TODO: remove this field once BE add permission control for trade vehicles
};

const DEFAULT_IS_TRADE_VEHICLES_LIST_PARAMS = {
	[IS_TRADE_FIELD_NAME]: true,
};

export const DEFAULT_TRADE_VEHICLES_LIST_PARAMS = {
	...DEFAULT_VEHICLES_LIST_PARAMS,
	...DEFAULT_IS_TRADE_VEHICLES_LIST_PARAMS,
};

export const ALLOWED_VEHICLES_LIST_PARAMS = [
	...PAGINATION_PARAMS,
	'state',
	'_include',
	'keyword',
	'leadManagerId',
	'bids',
	'createdAtFrom',
	'createdAtTo',
	'updatedAt',
	IS_TRADE_FIELD_NAME,
];

export const SORT_PARAMS_MAP = {
	closingBid: 'closingBid.value',
	createdAt: 'id',
	prettyVrm: 'vrm',
};
