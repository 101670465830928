import { combine } from 'effector';

import { CATEGORIES } from '@motorway/mw-enquiry-states';

import { VEHICLE_SECTIONS, VEHICLE_SECTIONS_ORDER } from '@core/constants/vehiclePage';

import * as user from '../user/internal';

import { $enquiry } from './store';
import { isWNSDocReviewer } from './utils';

export const $vehicleSections = combine(user.$user, $enquiry, (userInfo, enquiry) => ({
	[VEHICLE_SECTIONS.BIDS_AND_OFFERS]: true,
	[VEHICLE_SECTIONS.CONDITION_AND_DAMAGE]: !isWNSDocReviewer(userInfo),
	[VEHICLE_SECTIONS.DELIVERY]: enquiry?.state?.category === CATEGORIES.SOLD,
	[VEHICLE_SECTIONS.DOCUMENTATION]: true,
	[VEHICLE_SECTIONS.NOTES]: true,
	[VEHICLE_SECTIONS.PHOTOS]: !isWNSDocReviewer(userInfo),
	[VEHICLE_SECTIONS.PRICING_AND_STATUS]: true,
	[VEHICLE_SECTIONS.SELLER_DETAILS]: true,
	[VEHICLE_SECTIONS.SELLER_DOCUMENTS]: userInfo.canSeeSellerDocuments || userInfo.canReviewSellerDocuments,
	[VEHICLE_SECTIONS.SERVICE_HISTORY]: !isWNSDocReviewer(userInfo),
	[VEHICLE_SECTIONS.SPECIFICATION]: !isWNSDocReviewer(userInfo),
	[VEHICLE_SECTIONS.STATUS_LOGS]: true,
	[VEHICLE_SECTIONS.VEHICLE_HISTORY]: true,
	[VEHICLE_SECTIONS.VEHICLE_PAYMENT]:
		userInfo.canSeeVehiclePayment && enquiry?.closingBid?.dealer?.features?.paymentsMVP,
	[VEHICLE_SECTIONS.WHEELS_AND_TYRES]: !isWNSDocReviewer(userInfo),
}));

/**
 * Vehicle sections
 */
export const $vehicleSectionsOrder = $vehicleSections.map((sections) =>
	VEHICLE_SECTIONS_ORDER.filter((it) => sections[it]),
);
