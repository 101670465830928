import { combine } from 'effector';

import * as users from '../agents/internal';
import * as app from '../app/internal';

import * as effects from './effects';
import type { IVehicleStatusLog } from './model';
import * as page from './page';
import * as store from './store';
import { domain } from './utils';

export const $statusLogsWithoutAuthor = domain
	.store<IVehicleStatusLog[]>([])
	.on(effects.getStatusLogsFx.doneData, (_, logs) => logs.data)
	.reset([app.reset, page.reset]);

export const $statusLogs = combine(
	$statusLogsWithoutAuthor,
	store.$vehicleStatusMap,
	users.$allAgentsMap,
	(statusLogs, vehicleStatusMap, agents) =>
		statusLogs.map((log) => ({
			...log,
			author: agents[log.userId] || 'System',
			state: vehicleStatusMap[log.stateId]?.name,
		})),
);
