import { PAGES } from '@core/constants';
import { createKeyValueUnit } from '@core/utils/store';

import * as app from '../app/internal';

import * as effects from './effects';
import type { Agent } from './model';
import { domain } from './utils';

/**
 * Agent key/value store
 */
export const { $active: $agent } = createKeyValueUnit<Agent>({
	$activeKey: app.$location.map(({ pathname }) => (pathname.match(PAGES.USER.PATTERN) || [])[1] || ''),
	domain,
	name: '@app/@agentService/$agent',
	reset: app.reset,
	upsert: effects.getAgentFx.doneData.map(({ data }) => data),
});
