import { sample } from 'effector';

import * as vehicleService from '../vehicle/internal';

import * as effects from './effects';
import * as store from './store';

/**
 * Get vehicle side images metadata on bodyType / height changes
 */
sample({
	clock: [
		vehicleService.$enquiry.map((it) => it?.spec?.bodyCategory || ''),
		vehicleService.$enquiry.map((it) => it?.spec?.height || ''),
	],
	filter: (enquiry) => !!enquiry.id,
	fn: (enquiry) => ({
		bodyType: enquiry.spec.bodyCategory,
		enquiryId: enquiry.id,
		height: enquiry.spec.height,
	}),
	source: vehicleService.$enquiry,
	target: effects.getVehicleSideDataFx,
});

/**
 * Get SVG parts for the vehicle side images
 */
sample({
	clock: effects.getVehicleSideDataFx.doneData,
	filter: (sides, { bodyType }) => !sides[bodyType],
	fn: (_, { bodyType, sides }) => ({ bodyType, sides }),
	source: store.$sides,
	target: effects.getVehicleSideImagesFx,
});
