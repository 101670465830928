import './side-effects';

import { instantPush, push, reset, resetAfterRedirect } from './events';
import { $notificationForRedirect } from './store';

export type { AppNotification } from './model';

export const notificationService = {
	$notificationForRedirect,
	instantPush,
	push,
	reset,
	resetAfterRedirect,
};
