import type { ReactNode } from 'react';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { Button } from '@mui/material';

import styles from './DashboardLink.module.scss';

interface DashboardLinkProps {
	children: ReactNode;
	url: string;
}

export const DashboardLink = ({ children, url }: DashboardLinkProps) => {
	const location = useLocation();
	return (
		<Button
			className={cx('mw-i', styles.navButton, {
				[styles.navButtonActive]: location.pathname.match(new RegExp(`^${url}`)),
			})}
			color="inherit"
			component={Link}
			data-test={`${url.slice(1)}-link`}
			to={url}
		>
			{children}
		</Button>
	);
};
