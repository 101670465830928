// makes component static, as we say never update
import React from 'react';

import { VRM } from '@THC/components/VRM';

import styles from './SearchCommon.module.scss';

export const Message = ({ children }) => (
	<div className={styles.messageBox}>
		<h4>{children}</h4>
	</div>
);

export const NothingFound = () => (
	<div className={styles.fallbackResultsContainer}>
		<VRM size="regular" text="OOPS" />
		<h4 className={styles.noResultsTitle}>No matches were found</h4>
		<p className={styles.noResultsText}>Please expand your search and try again</p>
	</div>
);
