import { sample } from 'effector';

import { notifyOnDoneFx, notifyOnFailFx } from '@core/utils/side-effects';

import * as effects from './effects';
import * as events from './events';

/**
 * Load payments page initial data
 */
sample({
	clock: events.init,
	target: [effects.getPostSaleOffersFx, effects.getPaymentsFx, effects.getPaymentsHistoryLogFx],
});

/**
 * Refetch initial data after new offer is created
 */
sample({
	clock: effects.createPostSaleOffersFx.done,
	fn: ({ params }) => params.enquiryId,
	target: events.init,
});

/**
 * Refetch initial data after damage desc was updated
 */
sample({
	clock: effects.updateLatestOfferDescFx.done,
	fn: ({ params }) => params.enquiryId,
	target: events.init,
});

/**
 * Refetch data after create manual payment
 */
sample({
	clock: effects.createManualPaymentFx.done,
	fn: ({ params }) => params.enquiryId,
	target: [effects.getPaymentsFx, effects.getPaymentsHistoryLogFx],
});

/**
 * Notify about sendNewOfferSMSFx events
 */
notifyOnDoneFx(effects.sendNewOfferSMSFx.done, () => 'Text message sent to seller');
notifyOnFailFx(effects.sendNewOfferSMSFx.fail, () => 'Could not send message to seller');

/**
 * generate OTP sms - dealer contact
 */
notifyOnFailFx(effects.generateOTPFx.fail, () => 'Could not send SMS code to dealer');

/**
 * update damage description
 * vehicle details - payments section
 */
notifyOnFailFx(effects.updateLatestOfferDescFx.fail, () => 'Could not update damage description');

/**
 * Notify about manual payment
 */
notifyOnDoneFx(effects.createManualPaymentFx.done, () => 'Payment marked as manually paid');
