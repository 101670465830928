import { compact, find } from 'lodash-es';

import { SELLER_ADDITIONAL_DOCUMENTS } from '@core/constants/documents';
import type { StateDocument } from '@core/utils/photo-docs.model';

import type { DocumentSection } from './model';

export const extractAdditionalDocuments = (states: StateDocument[]) =>
	SELLER_ADDITIONAL_DOCUMENTS.filter(({ seed: { name } }) => {
		const document = find(states, ['kind', name]);
		return document?.uploaded || document?.required;
	});

export const addAdditionalDocuments = (sections: DocumentSection[], kind: string): DocumentSection[] => {
	const newSection = find(SELLER_ADDITIONAL_DOCUMENTS, ['seed.name', kind]);
	return compact([...sections, newSection]);
};
