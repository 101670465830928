import type { Store } from 'effector';

import * as effects from './effects';
import * as events from './events';
import type { VehicleSideData, VehicleSides } from './model';
import { domain } from './utils';

/**
 * Vehicle side images meta data
 */
export const $sidesData = domain
	.store<VehicleSideData>({} as VehicleSideData)
	.on(effects.getVehicleSideDataFx.doneData, (_, data) => data)
	.reset(events.reset);

/**
 * SVG parts / metadata to be used for the damage panels, cached
 */
export const $sides: Store<VehicleSides> = domain
	.store({})
	.on(effects.getVehicleSideImagesFx.doneData, (state, data) => ({ ...state, ...data }));
