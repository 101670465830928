import './side-effects';

import {
	cancelDeliveryFx,
	getDeliveryTransportFx,
	getDeliveryTransportOptionsFx,
	getTransportProvidersFx,
	saveDeliveryFx,
} from './effects';
import { $delivery, $deliveryTransportOptions, $deliveryTransportOptionsError, $transportProviders } from './store';

export type { DeliveryTransport, DeliveryTransportOptions, TransportOption, TransportProvider } from './model';

export const deliveryService = {
	$delivery,
	$deliveryTransportOptions,
	$deliveryTransportOptionsError,
	$transportProviders,
	cancelDeliveryFx,
	getDeliveryTransportFx,
	getDeliveryTransportOptionsFx,
	getTransportProvidersFx,
	saveDeliveryFx,
};
