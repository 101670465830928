import { datadogRum } from '@datadog/browser-rum';
import { sample } from 'effector';
import { combineEvents } from 'patronum';

import * as photos from '@core/services/photos/internal';
import * as prepCost from '@core/services/prepcost/internal';

import * as analyticsService from '../analytics/internal';

import { NOTE_TEST_CREATION_TEXT } from './constants';
import * as effects from './effects';
import * as page from './page';
import * as store from './store';
import { domain } from './utils';

/**
 * Get data on page init
 */
sample({
	clock: page.init,
	filter: (id) => !!id,
	fn: (id) => Number(id),
	target: [effects.getEnquiryFx, photos.getPhotosEx.fx],
});

/**
 * Get notes on page init
 */
sample({
	clock: page.init,
	filter: (id) => !!id,
	fn: (id) => ({ enquiryId: Number(id) }),
	target: [effects.getNotesFx],
});

/**
 * Create event for vehicle page data is done
 */
const allVehicleDataDone = combineEvents({
	events: {
		0: page.init,
		1: prepCost.getManualPrepCostFX.done,
		2: prepCost.getManualPrepCostListFx.done,
		3: effects.getEnquiryFx.done,
		4: effects.getNotesFx.done,
		5: photos.getPhotosEx.done,
	},
	reset: page.reset,
});

/**
 * Create event for enquiry under test
 */
const triggerEnquiryTest = sample({
	clock: allVehicleDataDone,
	filter: ({ notes, prepCostShouldReset }) =>
		prepCostShouldReset && !notes?.find((item) => item.content?.includes(NOTE_TEST_CREATION_TEXT)),
	fn: ({ enquiry }) => ({
		enquiryId: enquiry.id,
		priceHigh: enquiry.priceHigh,
	}),
	source: {
		enquiry: store.$enquiry,
		notes: store.$notes,
		prepCostShouldReset: store.$prepCostShouldReset,
	},
});

/**
 * Generate note for enquiry under test
 */
sample({
	clock: triggerEnquiryTest,
	filter: page.$active,
	fn: ({ enquiryId }) => ({
		content: NOTE_TEST_CREATION_TEXT,
		enquiryId,
	}),
	target: effects.createNoteFx,
});

/**
 * Update enquiry with 0 prep const
 */
sample({
	clock: triggerEnquiryTest,
	fn: ({ enquiryId, priceHigh }) => ({
		id: enquiryId,
		prepCosts: 0,
		sellerWillAcceptPrice: priceHigh,
	}),
	target: effects.updateVehicleFx,
});

/**
 * Send GA event
 */
sample({
	clock: triggerEnquiryTest,
	fn: () => ({
		name: 'Prep calculator A/B test',
	}),
	target: analyticsService.sendGA4EnquiryEvent,
});

/**
 * Send DataDog 0 prep cost testing event
 */
sample({
	clock: triggerEnquiryTest,
	target: domain.effect(({ enquiryId, priceHigh }) => {
		datadogRum.addAction('Move enquiry to 0 prep cost testing', {
			enquiryId,
			price: priceHigh,
		});
	}),
});
