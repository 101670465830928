import type { AxiosError } from 'axios';
import type { Effect } from 'effector';

import type { PhotoInstance } from '@core/containers/PhotoEditor';
import type { JSENDAPIResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';
import type { EnhancedEffect } from '@core/utils/effects';
import { enhancedEffect } from '@core/utils/effects';
import { photoDocsUpdateRequestConfig } from '@core/utils/photo-docs';
import type {
	DeletePhotoDocResponse,
	PhotoDoc,
	UploadPhotoDocParams,
	UploadPhotoDocResponse,
} from '@core/utils/photo-docs.model';

import type { IVehicle } from '../vehicle/internal';

import { ARCHIVE_IMAGE_LOADING_KEY } from './constants';
import type {
	BulkUpdatePhotoParams,
	UpdatePhotoDamageLocationParams,
	UpdatePhotoDamageLocationResponse,
} from './model';
import { domain } from './utils';

/**
 * Get photos
 */
export const getPhotosEx: EnhancedEffect<string | number, PhotoDoc[], AxiosError> = enhancedEffect({
	domain,
	handler: (enquiryId) =>
		request({
			method: 'get',
			params: { enquiryId },
			url: '/dealership/photos',
		} as RequestConfig),
	name: 'getPhotosFx',
});

export const getArchivedPhotosFx: Effect<
	{ enquiryId: IVehicle['id']; kind: PhotoInstance['kind'] },
	JSENDAPIResponse<PhotoDoc[]>,
	AxiosError
> = domain.effect({
	handler: ({ enquiryId, kind }) =>
		request({
			method: 'get',
			params: { enquiryId },
			url: `/premium-v3/vehicleImages/${kind}/archived`,
		} as RequestConfig),
	name: 'getArchivedPhotosEx',
});

/**
 * Upload photo
 */
export const uploadPhotoEx: EnhancedEffect<UploadPhotoDocParams, UploadPhotoDocResponse, AxiosError> = enhancedEffect({
	domain,
	handler: ({ file, modified, photo, vehicleId }) =>
		request({
			...photoDocsUpdateRequestConfig({ file, modified, photo, vehicleId }),
			onUploadProgress: uploadPhotoEx.setProgress,
			url: '/api/v1/photos',
		} as RequestConfig),
	name: 'uploadPhotoFx',
	setKey: ({ idx, photo }) => `${photo.name}-${idx}`,
});

/**
 * Delete photo
 */
export const deletePhotoFx: Effect<Partial<PhotoDoc>, DeletePhotoDocResponse, AxiosError> = domain.effect({
	handler: ({ id: vehicleImageId }) =>
		request({
			data: { vehicleImageId },
			method: 'delete',
			url: '/dealership/photos',
		} as RequestConfig),
});

/**
 * Archive photo
 */
export const archivePhotoFx: Effect<PhotoInstance, DeletePhotoDocResponse, AxiosError> = domain.effect({
	handler: (photo) =>
		request({
			loadingKey: `${ARCHIVE_IMAGE_LOADING_KEY}_${photo.id}`,
			method: 'post',
			url: `/premium-v3/vehicleImages/${photo.id}/archive`,
		} as RequestConfig),
});

/**
 * Update photo damage location
 */
export const updatePhotoDamageLocationFx: Effect<
	UpdatePhotoDamageLocationParams,
	UpdatePhotoDamageLocationResponse,
	AxiosError
> = domain.effect({
	handler: (data) =>
		request({
			data,
			method: 'post',
			url: '/dealership/photos/update',
		} as RequestConfig),
});

/**
 * Update bulk photo meta
 */
export const updatePhotosFx: Effect<BulkUpdatePhotoParams, UpdatePhotoDamageLocationResponse[], AxiosError> =
	domain.effect({
		handler: (params = []) =>
			Promise.all(params.map(([id, data]) => updatePhotoDamageLocationFx({ vehicleImageId: id, ...data }))),
	});

export const restorePhotoFx: Effect<string | number, PhotoDoc, AxiosError> = domain.effect({
	handler: (enquiryId) =>
		request({
			method: 'post',
			url: `/premium-v3/vehicleImages/${enquiryId}/restore`,
		} as RequestConfig),
	name: 'restorePhotoFx',
});

/**
 * Mark wrong detected service history photo as service history
 */
export const markAsServiceHistoryPhotoFx: Effect<string | number, PhotoDoc, AxiosError> = domain.effect({
	handler: (vehicleImageId) =>
		request({
			method: 'post',
			url: `/classifier/images/${vehicleImageId}/markAsServiceHistory`,
		} as RequestConfig),
	name: 'markAsServiceHistoryPhotoFx',
});
