import type { Effect } from 'effector';

import type { JSENDAPIListResponse, JSENDAPIResponse } from '@core/http';
import { request } from '@core/http';

import type { NextLead, NextSelfServeLead, SelfServeChasingLead, SelfServeNextLead } from './model';
import { domain } from './utils';

/**
 * Get the next lead
 */
export const getNextLeadFx: Effect<void, JSENDAPIResponse<NextLead>> = domain.effect({
	handler: () =>
		request({
			method: 'get',
			url: '/pending-leads/nextLead',
		}),
});

/**
 * Get the next self-serve lead
 */
export const getNextSelfServeLeadFx: Effect<void, JSENDAPIResponse<NextSelfServeLead>> = domain.effect({
	handler: () =>
		request({
			method: 'get',
			url: '/pending-leads/nextSelfServeLead',
		}),
});

/**
 * Get the next chasing self-serve lead
 */
export const getNextChasingSelfServeLeadFx: Effect<void, JSENDAPIResponse<NextSelfServeLead>> = domain.effect({
	handler: () =>
		request({
			method: 'get',
			url: '/pending-leads/nextSelfServeLead/chasing',
		}),
});

/**
 * Get self-serve leads
 */
export const getSelfServeNextLeadsFx: Effect<void, JSENDAPIListResponse<SelfServeNextLead[]>> = domain.effect({
	handler: () =>
		request({
			method: 'get',
			url: '/pending-leads/selfServeLeads',
		}),
});

/**
 * Get self-serve chasing leads
 */
export const getSelfServeChasingLeadsFx: Effect<void, JSENDAPIListResponse<SelfServeChasingLead[]>> = domain.effect({
	handler: () =>
		request({
			method: 'get',
			url: '/pending-leads/selfServeLeads/chasing',
		}),
});
