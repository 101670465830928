import type { Effect } from 'effector';
import { isEmpty, isUndefined, omitBy } from 'lodash-es';

import { SLUGS } from '@motorway/mw-enquiry-states';

import { TIME_24_HOURS } from '@core/constants';
import type { JSENDAPIListResponse, JSENDAPIResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';
import { pickAllowedParams } from '@core/utils/api';

import * as app from '../app/internal';

import {
	ALLOWED_LOGS_PARAMS,
	ALLOWED_NOTES_PARAMS,
	CONFIRM_MODAL_BENEFICIARY_WARNING,
	CONFIRM_MODAL_OVERRIDE_VALIDATION,
	CONFIRMATION_DETAILS,
	DEFAULT_LOGS_PARAMS,
	DEFAULT_NOTES_PARAMS,
} from './constants';
import { ErrorsMapper } from './ErrorsMapper';
import type {
	EnquiryError,
	EnquiryWithValidationProps,
	IConfirmProfileDetails,
	IMOTAdvisory,
	IRefreshPricing,
	IVehicle,
	IVehicleNote,
	IVehicleStatus,
	IVehicleStatusLog,
	NotesGetParams,
	NotesParams,
	QualifyStateChangeParams,
	RequestImagesParams,
	ValidateStateChangeParams,
	VehicleStatusLogsParams,
} from './model';
import { domain } from './utils';

const errorsMapper = new ErrorsMapper();

const preparePayload = (enquiry: Partial<IVehicle>) =>
	omitBy(
		{
			...enquiry,
			chargingCables: enquiry.chargingCables ? JSON.parse(enquiry.chargingCables) : undefined,
			profilingStatus: enquiry.profilingStatus ? JSON.parse(enquiry.profilingStatus) : undefined,
			qaFailedReasons: enquiry.qaFailedReasons ? JSON.parse(enquiry.qaFailedReasons) : undefined,
		},
		isUndefined,
	);

/**
 * Validate errors when status changes
 */
export const validateStateChangeFx: Effect<ValidateStateChangeParams, EnquiryError[]> = domain.effect({
	handler: ({ statusSlug, vehicleId }) =>
		request({
			method: 'post',
			url: `/dealership/vehicles/${vehicleId}/validateStateChange/${statusSlug}`,
		} as RequestConfig),
});

/**
 * Qualify QA Ready state change
 */
export const qualifyQAReadyFx: Effect<QualifyStateChangeParams, EnquiryError[]> = domain.effect({
	handler: ({ vehicleId }) =>
		request({
			method: 'post',
			url: `/dealership/vehicles/${vehicleId}/validateStateChange/${SLUGS.QA_READY}`,
		} as RequestConfig),
});

/**
 * Update vehicle data
 */
export const updateVehicleFx: Effect<Partial<IVehicle>, IVehicle> = domain.effect({
	handler: (data) =>
		request({
			data: preparePayload(data),
			method: 'put',
			url: `/dealership/vehicles/${data.id}`,
		} as RequestConfig),
});

/**
 * Get vehicle status logs
 */
export const getStatusLogsFx: Effect<
	Partial<VehicleStatusLogsParams>,
	JSENDAPIListResponse<IVehicleStatusLog[]>
> = domain.effect({
	handler: ({ enquiryId, ...rest }) =>
		request({
			method: 'get',
			params: pickAllowedParams({ ...DEFAULT_LOGS_PARAMS, ...rest }, ALLOWED_LOGS_PARAMS),
			url: `/premium-v3/stateLogs/byEnquiryId/${enquiryId}`,
		} as RequestConfig),
});

/**
 * Get enquiry by id
 */
export const getEnquiryFx: Effect<IVehicle['id'], IVehicle> = domain.effect({
	handler: (enquiryId) =>
		request({
			method: 'get',
			params: { _exclude: 'seller' },
			url: `/dealership/vehicles/${enquiryId}`,
		} as RequestConfig),
});

/**
 * Get all vehicle statuses
 */
export const getVehicleStatusesFx: Effect<void, JSENDAPIListResponse<IVehicleStatus[]>> = domain.effect({
	handler: () =>
		request({
			cacheTTL: TIME_24_HOURS,
			method: 'get',
			url: '/premium-v3/states',
		}),
});

/**
 * Send confirmation email
 */
export const sendConfirmationFx: Effect<{ enquiryId: number; isConfirmed: boolean }, void> = domain.effect({
	handler: ({ enquiryId, isConfirmed }) =>
		request({
			method: 'post',
			url: `/dealership/vehicles/${enquiryId}/${
				isConfirmed ? 'send-final-dealer-confirmation-email' : 'send-confirmation-emails'
			}`,
		} as RequestConfig),
});

/**
 * Confirm profile
 */
export const confirmDetailsFx: Effect<{ enquiryId: number }, JSENDAPIResponse<IConfirmProfileDetails>> = domain.effect({
	handler: ({ enquiryId }) =>
		request({
			data: CONFIRMATION_DETAILS,
			method: 'put',
			url: `/premium-v3/enquiries/${enquiryId}/confirm`,
		} as RequestConfig),
});

/**
 * Refresh pricing
 */
export const refreshPricingFx: Effect<{ enquiryId: number }, IRefreshPricing> = domain.effect({
	handler: ({ enquiryId }) =>
		request({
			method: 'post',
			url: `/dealership/vehicles/${enquiryId}/update-market-price`,
		} as RequestConfig),
});

/**
 * Get advisories (MOT check)
 */
export const getMOTAdvisoriesFx: Effect<
	{ enquiryId: IVehicle['id'] },
	JSENDAPIListResponse<IMOTAdvisory[]>
> = domain.effect({
	handler: ({ enquiryId }) =>
		request({
			cacheKey: String(enquiryId),
			cacheTTL: TIME_24_HOURS,
			method: 'get',
			url: `/premium-v3/advisories/byEnquiryId/${enquiryId}`,
		}),
});

export const updateEnquiryWithValidationFx = domain.createEffect(async (vehicle: EnquiryWithValidationProps) => {
	const { canOverrideQAValidationCheck, hasPassedBankReview, id, isEnquiryUsingPayments, stateId, statuses, ...data } =
		vehicle;
	const status = statuses.find(({ id: statusId }) => statusId === stateId);
	let errors: EnquiryError[] = [];
	const isQaReadyStatus = status?.slug === SLUGS.QA_READY;
	const isDocsReviewedStatus = status?.slug === SLUGS.SOLD_DOCUMENTS_REVIEWED;

	if (isDocsReviewedStatus && isEnquiryUsingPayments && !hasPassedBankReview) {
		app.openModal(CONFIRM_MODAL_BENEFICIARY_WARNING);
		return {};
	}

	if (!isEmpty(data) || (stateId && !isQaReadyStatus)) {
		await updateVehicleFx({ id, stateId: isQaReadyStatus ? undefined : stateId, ...data });
	}

	if (id && status?.slug && isQaReadyStatus) {
		errors = await validateStateChangeFx({ statusSlug: status?.slug, vehicleId: id });
	}

	if (!errors.length && stateId && isQaReadyStatus) {
		await updateVehicleFx({ id, stateId });
	}

	if (errors.length && canOverrideQAValidationCheck) {
		app.openModal(CONFIRM_MODAL_OVERRIDE_VALIDATION);
	}

	if (errors.length) {
		return errorsMapper.map(errors);
	}

	return undefined;
});

/**
 * Get vehicle notes by sort option
 */
export const getNotesFx: Effect<NotesGetParams, JSENDAPIListResponse<IVehicleNote[]>> = domain.effect({
	handler: (params) =>
		request({
			headers: {
				'x-mway-force-jsend': '1', // @TODO:Remove after JSEND implementation
			},
			method: 'get',
			params: pickAllowedParams({ ...DEFAULT_NOTES_PARAMS, ...params }, ALLOWED_NOTES_PARAMS),
			url: `/premium-v3/notes/byEnquiryId/${params.enquiryId}`,
		} as RequestConfig),
});

/**
 * Create note
 */
export const createNoteFx: Effect<NotesParams, JSENDAPIResponse<IVehicleNote>> = domain.effect({
	handler: (data) =>
		request({
			data,
			method: 'post',
			url: '/premium-v3/notes/create',
		} as RequestConfig),
});

/**
 * Delete note
 */
export const deleteNoteFx: Effect<number, number> = domain.effect({
	handler: (id) =>
		request({
			method: 'post',
			url: `/premium-v3/notes/${id}/delete`,
		} as RequestConfig),
});

/**
 * Resupply images
 */
export const requestImagesFx: Effect<RequestImagesParams, JSENDAPIResponse<string>> = domain.effect({
	handler: (data) =>
		request({
			data,
			method: 'post',
			url: '/premium-v3/vehicleImages/resupply',
		}),
});
