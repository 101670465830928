import { $damageImages } from './damage.store';
import { getDamageImagesFx, updateDamageImagesFx } from './effects';
import { updateDamageImages } from './events';

export { DAMAGE_AI_DETECT_FEEDBACK, DAMAGE_TYPES } from './constants';

export type { IDamageImage, IDamageImageMeta } from './model';

export const damageService = {
	$damageImages,
	getDamageImagesFx,
	updateDamageImages,
	updateDamageImagesFx,
} as const;
