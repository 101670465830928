/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { createRoot } from 'react-dom/client';

import '@core/init';
import { initDatadogRum } from '@core/utils/datadogRum';
import { initSnowplow } from '@core/utils/snowplowInit';
import { Root } from '@pages/Root';

import './styles/app.scss';
import '@motorway/mw-highway-code/src/styles/theme/light.scss';
import '@motorway/mw-highway-code/src/styles/fontface.scss';

initDatadogRum();
initSnowplow();

const root = createRoot(document.getElementById('main'));
root.render(<Root />);
