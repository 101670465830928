import { isEmpty, isNull } from 'lodash-es';

import { VIEWS } from '../Search.constants';

export const resolveViewKey = (isLoading, data) => {
	switch (true) {
		case isLoading: {
			return VIEWS.LOADING;
		}
		case isEmpty(data): {
			return VIEWS.NOTHING_FOUND;
		}
		case isNull(data): {
			return VIEWS.EMPTY;
		}
		default: {
			return VIEWS.RESULTS;
		}
	}
};

export const getViewInfo = (isLoading, data) => {
	const viewKey = resolveViewKey(isLoading, data);

	return {
		isFallbackView: viewKey !== VIEWS.RESULTS,
		viewKey,
	};
};
