import * as effects from './effects.manual';
import * as events from './events';
import type { ManualPrepCostConfig, ManualPrepCostResponse } from './model';
import { domain } from './utils';

/**
 * Manual prep cost items
 */
export const $manualPrepCostConfig = domain
	.store<ManualPrepCostConfig>({})
	.on(effects.getManualPrepCostListFx.doneData, (_, { data: [{ content } = { content: {} }] }) => content);

/**
 * Manual prep cost store
 */
export const $manualPrepCost = domain
	.store<ManualPrepCostResponse['data']>([])
	.on(effects.getManualPrepCostFX.doneData, (_, { data = [] }) => data)
	.reset([events.reset]);
