import type { Effect } from 'effector';

import type { RequestConfig } from '@core/http';
import { request } from '@core/http';
import { pickAllowedParams } from '@core/utils/api';

import { ALLOWED_DEALER_LIST_PARAMS, ALLOWED_DEALERS_SEARCH_PARAMS } from './constants';
import type { DealersListResponse, GetDealersListParams } from './model';
import { domain } from './utils';

/**
 * Get dealers list
 */
export const getDealersListFx: Effect<Partial<GetDealersListParams>, DealersListResponse> = domain.effect({
	handler: (params) =>
		request({
			method: 'get',
			params: pickAllowedParams(params, ALLOWED_DEALER_LIST_PARAMS),
			url: '/premium/dealers',
		} as RequestConfig),
});

/**
 * Search for dealers
 */
export const searchForDealersFx: Effect<Partial<GetDealersListParams>, DealersListResponse> = domain.effect({
	handler: (params) =>
		request({
			method: 'get',
			params: pickAllowedParams(params, ALLOWED_DEALERS_SEARCH_PARAMS),
			url: '/premium/dealers',
		} as RequestConfig),
});
