import { datadogRum } from '@datadog/browser-rum';
import type { FormApi } from 'final-form';
import {
	compact,
	filter,
	find,
	flowRight,
	isEqual,
	isNull,
	isNumber,
	isObject,
	isPlainObject,
	omit,
	pick,
	reduce,
	set,
	sortBy,
	transform,
} from 'lodash-es';

import { SLUGS } from '@motorway/mw-enquiry-states';

import type { IVehicleStatus } from '@core/services/vehicle/model';
import type { FilterVehicleStatusesParams } from '@core/utils/model';
import { canMoveToRestrictedStatuses, hasDocumentReviewPermission } from '@core/utils/vehicleCheckers';

export const filterDeleteStatuses = (states: IVehicleStatus[]): IVehicleStatus[] =>
	filter(states, (vehicleStatus) => vehicleStatus.status !== 'disabled');

export const sortByName = (data: any[]) => sortBy(data, ['name']);

export const addMissingState = (states: IVehicleStatus[], stateId?: number) => (statuses: IVehicleStatus[]) =>
	find(statuses, ['id', stateId]) ? statuses : [...statuses, find(states, ['id', stateId])];

export const filterRestrictedStatuses = (userPermissions: string[]) => (states: IVehicleStatus[]) =>
	canMoveToRestrictedStatuses(userPermissions)
		? states
		: states.filter(
				(state) =>
					!state.restricted ||
					(state?.slug === SLUGS.SOLD_DOCUMENTS_REVIEWED && hasDocumentReviewPermission(userPermissions)),
		  );

export const filterNonAvailable =
	(stateId?: number) =>
	(statuses: IVehicleStatus[]): IVehicleStatus[] => {
		const { allowedNextStates } = find(statuses, ['id', stateId]) || {};
		return statuses.filter((state) => allowedNextStates?.some(({ type, value }) => state[type] === value));
	};

export const filterVehicleStatuses = ({
	allStates,
	currentStatusId,
	userPermissions = [],
}: FilterVehicleStatusesParams) =>
	flowRight([
		compact,
		sortByName,
		addMissingState(allStates, currentStatusId),
		filterRestrictedStatuses(userPermissions),
		filterNonAvailable(currentStatusId),
		filterDeleteStatuses,
	]);

export const omitNonFilters = (data) => omit(data, ['_end', '_order', '_sort', '_start', '_include']);

export const mapById = ({ data, ids, prop = 'name' }) =>
	(ids || []).map((id) => (data || []).find((it) => it.id === id)?.[prop]);

export const updateEnquiryReducer = (state, { enquiryId, ...rest }) => ({
	list: state.list.map((vehicle) => (vehicle.id !== enquiryId ? vehicle : { ...vehicle, ...rest })),
	total: state.total,
});

export const extractModifiedValues = <T = unknown>(values: Partial<T>, form: FormApi): Partial<T> =>
	pick(difference(values, form.getState().initialValues), form.getRegisteredFields()) as Partial<T>;

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object: Record<string, any>, base: Record<string, any>): Record<string, any> {
	return transform(object, (result, value, key) => {
		if (!isEqual(value, base[key])) {
			result[key] = isObject(value) && isObject(base[key]) ? difference(value, base[key]) : value;
		}
	});
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {string} bathPath base path
 * @return {Object}  Return flatten object
 */
export function flattenObject(object, bathPath = '') {
	return Object.keys(object).reduce((accumulator, currentValue) => {
		if (isPlainObject(object[currentValue])) {
			return { ...accumulator, ...flattenObject(object[currentValue], currentValue) };
		}
		return {
			...accumulator,
			[bathPath ? `${bathPath}.${currentValue}` : currentValue]: object[currentValue],
		};
	}, {});
}

export const toBase64 = (file: Blob): Promise<FileReader['result'] | null> =>
	new Promise((resolve, reject) => {
		if (!file) {
			resolve(null);
		}
		const reader = new FileReader();
		reader.readAsDataURL(file as Blob);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});

export const createDataTest = (name: string) =>
	name
		.replaceAll(/[^a-zA-Z\s]/g, '')
		.replaceAll(' ', '-')
		.toLowerCase();

export const sanitizeFormValues = (value) => (!value && !isNumber(value) ? null : value);

export const sanitizeSearchParamsValues = (params: Record<string, any>) =>
	reduce(
		params,
		(acc, value, key) => {
			set(acc, key, isNull(value) ? undefined : value);
			return acc;
		},
		{},
	);

/**
 * Check if value is valid number
 * Do not use Number, as it behaves differently
 */
export const isNumeric = (value: any) => !isNaN(parseFloat(value)) && isFinite(value); //eslint-disable-line

export const wait = (ms: number) =>
	new Promise((resolve) => {
		setTimeout(resolve, ms);
	});

export const copyToClipboard = async (text: string | number) =>
	new Promise((resolve) => {
		navigator.permissions.query({ name: 'notifications' }).then((result) => {
			if (result.state === 'granted' || result.state === 'prompt') {
				navigator.clipboard
					.writeText(String(text))
					.then(() => resolve(true))
					.catch((e) => {
						datadogRum.addError(e, {
							tag: 'clipboard error',
						});
					});
			}
		});
	});
