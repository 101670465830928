import './side-effects';

import { TYPE_OF_DOCUMENTS } from './constants';
import {
	deleteDocumentFx,
	getBeneficiariesDocumentsEx,
	getBeneficiaryEx,
	getDocumentsEx,
	getMatchDocumentsEx,
	getStateDocumentsEx,
	updateDocumentMetaFx,
	updateDocumentStateFx,
	updateDocumentStatesFx,
	uploadDocumentEx,
} from './effects';
import {
	$beneficiaries,
	$beneficiariesDocument,
	$documents,
	$documentsMatch,
	$documentsStates,
	$sections,
} from './store';

export * from './model';

export { TYPE_OF_DOCUMENTS } from './constants';

export const documentsService = {
	$beneficiaries,
	$beneficiariesDocument,
	$documents,
	$documentsMatch,
	$documentsStates,
	$sections,
	deleteDocumentFx,
	getBeneficiariesDocumentsEx,
	getBeneficiaryEx,
	getDocumentsEx,
	getMatchDocumentsEx,
	getStateDocumentsEx,
	TYPE_OF_DOCUMENTS,
	updateDocumentMetaFx,
	updateDocumentStateFx,
	updateDocumentStatesFx,
	uploadDocumentEx,
} as const;
