import type { Effect } from 'effector';

import type { RequestConfig } from '@core/http';
import { HEADER_ENABLE_SEARCH_SERVICE, request } from '@core/http';
import type { ListResponse } from '@core/model';
import { pickAllowedParams } from '@core/utils/api';

import type { IListVehicle } from '../vehicles/internal';

import { ALLOWED_VEHICLES_LIST_PARAMS } from './constants';
import type { PrioritisedVehiclesFiltersParams } from './model';
import { domain } from './utils';

/**
 * Get vehicles
 */
export const getVehiclesFx: Effect<
	Partial<PrioritisedVehiclesFiltersParams>,
	ListResponse<IListVehicle>
> = domain.effect({
	handler: (params) =>
		request({
			headers: {
				...HEADER_ENABLE_SEARCH_SERVICE,
			},
			method: 'get',
			params: pickAllowedParams(params, ALLOWED_VEHICLES_LIST_PARAMS),
			url: '/dealership/vehicles',
		} as RequestConfig),
});
