import React from 'react';
import { compact } from 'lodash-es';

import type { ISearchVehicle } from '@core/services/vehicles';

import type { TableParams } from '../Table';

export const matcherSentence =
	({ regExp, searchTerm }) =>
	(sentence) => {
		const searchTermEscaped = searchTerm.replace(regExp, '').replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
		const [perfectMatch] = sentence.match(new RegExp(searchTermEscaped, 'gi')) || [];

		return perfectMatch;
	};

export const highlighter = (sentence, perfectMatch) => {
	/**
	 * Perfect match catches char by char results:
	 *
	 * sentence: 'build a better car market for everyone.'
	 *
	 * searchTerm: 'market'
	 * match: true
	 *
	 * searchTerm: 'car market'
	 * match: true
	 *
	 * searchTerm: 'for ev'
	 * match: true
	 *
	 * searchTerm: 'build better'
	 * match: false
	 */

	const perfectMatchStart = sentence.indexOf(perfectMatch);
	const perfectMatchEnd = perfectMatchStart + perfectMatch.length;
	const perfectMatchSentenceSize = sentence.length;

	const resultStart = sentence.slice(0, perfectMatchStart);
	const resultMatch = sentence.slice(perfectMatchStart, perfectMatchEnd);
	const resultEnd = sentence.slice(perfectMatchEnd, perfectMatchSentenceSize);

	// we want to exclude already marked sentence
	if (sentence.slice(perfectMatchStart - 6, perfectMatchStart).includes('mark')) {
		return sentence;
	}

	const startResultTemplate = resultStart && `<span>${resultStart}</span>`;
	const endResultTemplate = resultEnd && `<span>${resultEnd}</span>`;

	return `${startResultTemplate}<mark>${resultMatch}</mark>${endResultTemplate}`;
};

export const renderSearchHighligth = (params: TableParams<ISearchVehicle>) => {
	if (!params.value) {
		return null;
	}

	// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
	const nonZeroReqExp = /^(\+44)/;
	const zeroStartReqExp = /^(0|\+44)/;
	const searchTerm = params.row?.searchTerm || '';
	const matches = compact(
		[zeroStartReqExp, nonZeroReqExp].map((req) => matcherSentence({ regExp: req, searchTerm })(params.value)),
	);

	if (matches.length) {
		const [first, second] = matches;
		const sent = highlighter(params.value, first || second);
		// unfortunately we cannot set twice react node to the same sentence, so we convert string to jsx
		// eslint-disable-next-line react/no-danger
		return <span dangerouslySetInnerHTML={{ __html: sent }} />;
	}

	return params.value;
};
