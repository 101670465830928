import type { Domain, Event, Store, StoreWritable } from 'effector';
import { combine } from 'effector';
import { assign, omit } from 'lodash-es';

import { domain as globalDomain } from '../domain';

interface DefaultConfig {
	key: string;
	storage?: Storage;
	sync?: boolean;
}

const defaultConfig = {
	key: 'persist',
	storage: localStorage,
	sync: false,
};

const getItem = (key: string, storage: Storage) => {
	const item = storage.getItem(key);
	return item ? JSON.parse(item) : null;
};

/**
 * Keeps in sync store data with chosen storage
 */
export const persistStore = (store: StoreWritable<any>, config: DefaultConfig) => {
	const { key, storage, sync } = { ...defaultConfig, ...config };

	const rehydrate = globalDomain.event('rehydrate');

	store.on(rehydrate, () => getItem(key, storage) || store.defaultState);
	rehydrate();

	sync && storage === localStorage && window.addEventListener('storage', () => rehydrate());

	store.watch((state) => {
		const storedItem = getItem(key, storage);
		const itemToStore = JSON.stringify(state);
		storedItem !== itemToStore && storage.setItem(key, itemToStore);
	});

	return store;
};

interface CreateKeyValueUnit<I> {
	$activeKey: Store<string>;
	activeValueDefault?: string | number | {} | boolean | null;
	domain?: Domain;
	key?: string;
	name: string;
	remove?: Event<string>;
	reset?: Event<void>;
	upsert: Event<I> | Store<I>;
}

/**
 * Utility to create key/value effector unit
 */
export const createKeyValueUnit = <I>({
	domain = globalDomain,
	key = 'id',
	$activeKey,
	name,
	remove = domain.event(),
	reset = domain.event(),
	upsert,
	activeValueDefault = {},
}: CreateKeyValueUnit<I>) => {
	// store containing map of items, key/value
	const $map = domain
		.store({}, { name })
		.on(upsert, (state, newItem) => assign({}, state, { [newItem[key]]: newItem }))
		.on(remove, (state, it) => omit(state, it[key]))
		.reset(reset);
	// store holding selected item
	const $active: Store<I> = combine($activeKey, $map, (id, map) => map[id] || activeValueDefault);
	return { $active, $map, remove, reset };
};
