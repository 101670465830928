import { isString, toUpper } from 'lodash-es';
import { parse } from 'url';

import { PHOTOS_S3_STAGE_URL_REGEX, PHOTOS_S3_URL_REGEX } from '@core/constants/vehiclesConsts';
import { replaceFallbackUrl } from '@core/utils/imgIxToTP';

export const getImgixCropParams = ({ height, rotate, width, x, y }) => {
	const rotateOption = rotate ? `&or=${rotate}` : '';
	return [width && height ? `&rect=${x},${y},${width},${height}` : '', `${rotateOption}`].join('');
};

export const initials = (name?: string): string => {
	if (!name) {
		return '';
	}

	const nameArray = name.split(' ');
	let result = nameArray[0].substring(0, 1).toUpperCase();

	if (nameArray.length > 1) {
		result += nameArray[nameArray.length - 1].substring(0, 1).toUpperCase();
	}
	return result;
};

export const formattedName = (name: string, fullName?: boolean, initialsOnly?: boolean): string => {
	if (fullName) {
		return name;
	}

	if (initialsOnly) {
		return initials(name);
	}

	return (name || '')
		.split(' ')
		.map((part, i): string => {
			let displayName = '';

			switch (i) {
				case 0:
					displayName = part;
					break;
				case 1:
					displayName = `${part.charAt(0).toUpperCase()}.`;
					break;
				default:
					break;
			}

			return displayName;
		})
		.filter((part) => part)
		.join(' ');
};

export const s3toImgix = (s3URL, opts = { h: 40, w: 40 } as any, source = 'imgix_agents_url'): string | undefined => {
	if (!s3URL) {
		return undefined;
	}

	if (s3URL.match(PHOTOS_S3_STAGE_URL_REGEX) || s3URL.match(PHOTOS_S3_URL_REGEX) || s3URL.match(/imgix\.net/)) {
		source = 'imgix_vehicles_url';
	}

	const h = opts.h || 40;
	const w = opts.w || 40;

	let imgixParamsCrop = '';
	const imgixParamsFit = opts.fit ? `&fit=${opts?.fit}` : '&fit=crop';

	if (opts.meta && Number.isInteger(parseInt(opts.meta.x))) {
		imgixParamsCrop = getImgixCropParams(opts.meta);
	}

	// @ts-ignore
	// eslint-disable-next-line no-underscore-dangle
	const imgixUrl = window.__app_state__[source];
	const dpr = window.devicePixelRatio;
	const uri = parse(s3URL);
	const key = decodeURIComponent(uri.pathname ?? '').replace(/^\/(vehicle-photos-stage|motorway-vehicle-photos)\//, '');
	return `${imgixUrl}${key}?w=${w}&h=${h}&dpr=${dpr}${imgixParamsFit}${imgixParamsCrop}`;
};

const numberFormat = new Intl.NumberFormat();

export const stringifyArrayValue = (value: string[]): string =>
	JSON.stringify(value ? [...value].sort((a, b) => String(a)?.localeCompare(String(b))) : value);

export const parseArrayValue = (value: string | unknown[]) => {
	if (isString(value)) {
		return value ? JSON.parse(value) : [];
	}
	return value || [];
};

export const fields = {
	currency: (value) => (value ? numberFormat.format(value) : ''),
	number: (value) => (value ? Number(value.replace(/,/g, '')) : ''),
	parseArrayValue,
	phoneNumber: (value) => (isString(value) ? value.replace(/[^\d.+]/g, '') : value || ''),
	stringifyArrayValue,
	toUpper,
};

export const formatNumber = (value: string): string => {
	if (!value) {
		return value;
	}
	const onlyNums = String(value).replace(/[^\d]/g, '');
	return Number(onlyNums).toLocaleString('en');
};

export const parseNumber = (value: string): number | null => {
	const onlyNums = value.replace(/[^\d]/g, '');
	if (!value || !onlyNums) {
		return null;
	}

	return parseInt(onlyNums);
};

export const gbpCurrencyFormatterNoDigits = new Intl.NumberFormat('en-GB', {
	currency: 'GBP',
	minimumFractionDigits: 0,
	style: 'currency',
});

export const gbpCurrencyFormatter = new Intl.NumberFormat('en-GB', {
	currency: 'GBP',
	style: 'currency',
});

export const boolToText = (val) => ({ [String(val)]: '-', false: 'No', true: 'Yes' })[String(val)];

export const toPrice = (v) => {
	const value = formatNumber(v);

	return value === 'N/A' ? '-' : `£${value}`;
};

export const getImgIxUrlBySize = ({ fit = 'crop', height = 1600, meta, url, width = 1600 }) => {
	const dpr = window.devicePixelRatio;
	const imgIxParamsCrop = meta ? getImgixCropParams(meta) : '';
	return replaceFallbackUrl(`${url}?w=${width}&h=${height}&fit=${fit}&dpr=${dpr}${imgIxParamsCrop}&auto=compress`);
};

export const getImgIxUrlByMeta = ({ fit = 'crop', meta, url }) => {
	if (meta && (meta.x || meta.y || meta.rotate)) {
		const dpr = window.devicePixelRatio;
		const imgIxParamsCrop = meta ? getImgixCropParams(meta) : '';
		return `${url}?&fit=${fit}&dpr=${dpr}${imgIxParamsCrop}&auto=compress`;
	}
	return null;
};
