export const hasOneOfPermissions = (oneOf: string[]) => (permissions: string[]) =>
	oneOf.some((permission) => permissions.includes(permission));

export enum ROLES {
	ACCOUNT_MANAGER = 'Account Manager',
	ADMIN = 'Admin',
	DEALER_EXPERIENCE_SPECIALIST = 'Dealer Experience Specialist',
	DEALER_SUPPORT = 'Dealer Support',
	DEVELOPER = 'Developer',
	DOCUMENT_REVIEWER = 'Document Reviewer',
	ONLINE_SALES_SPECIALIST = 'Online Sales Specialist',
	OPERATIONS = 'Operations',
	PAYMENT_OPS = 'Payment Ops',
	QA = 'QA',
	SALES_COMPLETION = 'Sales Completion',
	SALES_SPECIALIST = 'Sales Specialist',
	SENIOR_ACCOUNT_MANAGER = 'Senior Account Manager',
	SENIOR_SALES_SPECIALIST = 'Senior Sales Specialist',
	SUPPORT_SPECIALIST = 'Support Specialist',
	TRANSPORT_LOGISTICS_EXECUTIVE = 'Transport and Logistics Executive',
}

export enum DEALERS_PERMISSIONS {
	DEALERS_ADD = 'dealers_add',
	DEALERS_CONTACT_ADD = 'dealers_contact_add',
	DEALERS_DELETE = 'dealers_delete',
	DEALERS_FEE_OVERRIDE = 'dealers_fee_override',
	DEALERS_KYC_READ = 'dealers_kyc_read',
	DEALERS_KYC_WRITE = 'dealers_kyc_write',
	DEALERS_MANAGEMENT = 'dealers_management',
	DEALERS_MODIFY_STATUS = 'dealers_modify_status',
	DEALERS_VIEW = 'dealers_view',
}

export enum BIDS_PERMISSIONS {
	ALL_BIDS_DURING_SALE = 'see_all_bids_during_sales',
}

export enum USERS_PERMISSIONS {
	ACCESS_DEALER_CONTACT_PERMISSIONS_FOR_PAYMENTS = 'access_dealer_contact_permissions_for_payments',
	DELIVERY_EDIT = 'delivery_edit',
	EDIT_RESERVE_PREP_COST_FIELDS = 'edit_reserve_prep_cost_fields',
	GRADING_EDIT = 'grading_edit',
	IS_ACCOUNT_MANAGER = 'account_manager',
	IS_LEAD_MANAGER = 'lead_manager',
	NOTES_DELETE = 'notes_delete',
	SENIOR_LEAD_MANAGER_VIEW = 'senior_lead_manager_view',
	USERS_EDIT_LEAD_ASSIGNMENT = 'users_edit_lead_assignment',
	USERS_EDIT_PERMISSIONS = 'users_edit_permissions',
	USERS_EDIT_PROFILE_PICTURE = 'users_edit_profile_picture',
	USERS_MANAGEMENT = 'users_management',
	USERS_VIEW = 'users_view',
	VEHICLE_LIST_PRIORITISED = 'vehicle_list_prioritised',
	VEHICLE_LIST_STANDARD = 'vehicle_list_standard',
}

export enum VEHICLES_PERMISSIONS {
	ANYTIME_REFRESH_PRICING = 'anytime_refresh_pricing',
	EDIT_LOCKED_SALES_SPECIALIST = 'edit_locked_sales_specialist',
	EDIT_PRICE_AMENDMENT_FOR_PAYMENTS = 'edit_price_amendment_for_payments',
	MARK_AS_MANUAL_PAYMENT = 'mark_as_manual_payment',
	MODIFY_RESTRICTED_VEHICLE_STATUS = 'modify_restricted_vehicle_status',
	VEHICLES_OVERRIDE_QA_VALIDATION_CHECK = 'vehicles_override_qa_validation_check',
	VEHICLES_REASSIGN_SOLD = 'vehicles_reassign_sold',
	VEHICLES_UPDATE = 'vehicles_update',
	VEHICLES_UPDATE_DOCUMENTATION_FIELDS = 'vehicles_update_documentation_fields',
	VEHICLES_UPDATE_SPECIFICATION_FIELDS = 'vehicles_update_specification_fields',
	VEHICLES_VIEW = 'vehicles_view',
	VEHICLE_PAYMENT_VIEW = 'vehicle_payment_view',
}

export enum DOCUMENTS_PERMISSIONS {
	DOCUMENTS_ACCESS = 'documents_access',
	DOCUMENTS_REVIEW = 'documents_review',
}

export enum OTP_PERMISSIONS {
	VIEW_EDIT_DEALER_OTP_NUMBER = 'view_edit_dealer_otp_number',
}

export const PERMISSIONS = {
	...DEALERS_PERMISSIONS,
	...BIDS_PERMISSIONS,
	...USERS_PERMISSIONS,
	...VEHICLES_PERMISSIONS,
	...DOCUMENTS_PERMISSIONS,
	...OTP_PERMISSIONS,
};

export type Permissions = (
	| DEALERS_PERMISSIONS
	| BIDS_PERMISSIONS
	| VEHICLES_PERMISSIONS
	| USERS_PERMISSIONS
	| DOCUMENTS_PERMISSIONS
	| OTP_PERMISSIONS
)[];
