import { pickBy } from 'lodash-es';

/**
 * Pick allowed params from list
 */
export const pickAllowedParams = (params, allowed) => pickBy(params, (_, key) => allowed.includes(key));

export const isRetryStatus = (status: number) => status >= 500 || [424].includes(status);

/**
 * Load images using XMLHttpRequest
 */
export const loadImage = (url: string, retry: number = 0) =>
	new Promise<Blob>((resolve, reject) => {
		const request = new XMLHttpRequest();
		let count = 0;

		const getImage = () => {
			request.open('GET', url, true);
			request.send();
		};

		request.addEventListener('readystatechange', () => {
			if (request.readyState === 2 && request.status === 200) {
				request.responseType = 'blob';
			}
		});
		request.addEventListener('load', () => {
			if (request.status === 200) {
				resolve(request.response);
			} else if (isRetryStatus(request.status) && count !== retry) {
				count++;
				getImage();
			} else {
				const { response, responseText, responseType, responseURL, status, statusText } = request;
				// eslint-disable-next-line prefer-promise-reject-errors
				reject({
					response,
					responseText,
					responseType,
					responseURL,
					status,
					statusText,
				});
			}
		});

		getImage();
	});
