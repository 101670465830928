import './side-effects';
import './page.side-effects';

import * as effects from './effects';
import * as events from './events';
import * as requestImages from './request-images.store';
import * as statusLogs from './status-logs.store';
import * as store from './store';
import * as validateErrors from './validate-errors.store';
import * as visible from './visible.store';

export * from './constants';

export * as vehiclePage from './page';

export * from './model';

export const vehicleService = {
	...effects,
	...events,
	...statusLogs,
	...store,
	...validateErrors,
	...requestImages,
	...visible,
} as const;
