import type { JSENDAPIListResponse } from '@core/http';

import * as effects from './effects';
import * as events from './events';
import type { SelfServeChasingLead, SelfServeNextLead } from './model';
import { domain } from './utils';

// Next Leads
export const $nextLeads = domain
	.store<JSENDAPIListResponse<SelfServeNextLead[]>>({ data: [] })
	.on(effects.getSelfServeNextLeadsFx.doneData, (_, data) => data)
	.reset(events.reset);

export const $nextLeadsData = $nextLeads.map(({ data }) => data);

export const $nextLeadsHasData = $nextLeadsData.map((data) => !!data.length);

// Chasing Leads
export const initialChasingLeads = [...Array(50)].map((_, id) => ({ id })) as SelfServeChasingLead[];
export const $chasingLeads = domain
	.store<JSENDAPIListResponse<SelfServeChasingLead[]>>({ data: initialChasingLeads })
	.on(effects.getSelfServeChasingLeadsFx.doneData, (_, data) => ({
		data: data.data.map((it) => ({ ...it, id: it.enquiryId })),
		meta: data.meta,
		status: data.status,
	}))
	.reset(events.reset);

export const $chasingLeadsData = $chasingLeads.map(({ data }) => data);

export const $chasingLeadsHasData = $chasingLeadsData.map((data) => !!data.length);
