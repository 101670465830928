import { SELLER_DOCUMENTS } from '@core/constants/documents';
import type { PhotoInstance } from '@core/containers/PhotoEditor';
import type {
	BeneficiariesDocument,
	GetBeneficiaryResponse,
	MatchDocument,
	StateDocument,
} from '@core/utils/photo-docs.model';

import * as app from '../app/internal';

import * as effects from './effects';
import { addAdditionalDocuments, extractAdditionalDocuments } from './helpers';
import type { DocumentSection } from './model';
import { domain } from './utils';

/**
 * Create photos store
 */
export const $documents = domain
	.store<PhotoInstance[]>([])
	.on(effects.getDocumentsEx.doneData, (_, docs) =>
		docs.map((doc) => ({
			...doc,
			thumbnailUrl: doc?.presets?.find((it) => it.name === 'thumbnail')?.url || null,
		})),
	)
	.reset(app.reset);

/**
 * Create state documents store
 */
export const $documentsStates = domain
	.store<StateDocument[]>([])
	.on(effects.getStateDocumentsEx.doneData, (_, docs) => docs)
	.reset(app.reset);

/**
 * Create match documents store
 */
export const $documentsMatch = domain
	.store<MatchDocument>({} as MatchDocument)
	.on(effects.getMatchDocumentsEx.doneData, (_, docs) => docs)
	.reset(app.reset);

/**
 * Create beneficiaries document store
 */
export const $beneficiariesDocument = domain
	.store<BeneficiariesDocument>({} as BeneficiariesDocument)
	.on(effects.getBeneficiariesDocumentsEx.doneData, (_, beneficiaries) => beneficiaries)
	.reset(app.reset);

/**
 * Create beneficiaries store
 */
export const $beneficiaries = domain
	.store<GetBeneficiaryResponse>({ fields: [] })
	.on(effects.getBeneficiaryEx.doneData, (_, beneficiaries) => beneficiaries)
	.reset(app.reset);

/**
 * Create sections store
 */
export const $sections = domain
	.store<DocumentSection[]>([...SELLER_DOCUMENTS])
	.on(effects.getStateDocumentsEx.doneData, (_, states) => [...SELLER_DOCUMENTS, ...extractAdditionalDocuments(states)])
	.on(effects.updateDocumentMetaFx.doneData, (state, { isRequired, kind }) =>
		isRequired ? addAdditionalDocuments(state, kind) : state.filter(({ seed: { name } }) => name !== kind),
	)
	.reset(app.reset);
