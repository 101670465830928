import React from 'react';
import { Avatar, Button, Typography } from '@mui/material';

import { userService } from '@core/services/user';
import { initials } from '@core/utils/formatter';

import type { ProfileInfoProps } from './Profile.model';

import logoutButtonStyles from './LogoutButton.module.scss';
import styles from './Sidebar.module.scss';

const getGreeting = () => {
	const nowHours = new Date().getHours();
	const partOfDay = nowHours / 24;
	const index = Math.floor(partOfDay * 4);

	return ['Good evening', 'Good morning', 'Good afternoon', 'Good evening'][index];
};

export const ProfileInfo = ({ name, src }: ProfileInfoProps) => (
	<div className={styles.row}>
		<Avatar src={src} sx={{ height: 112, width: 112 }}>
			{initials(name)}
		</Avatar>
		<div className={styles.title}>
			<Typography className={styles.h2} component="h2" data-test="profile-info-greeting" variant="h5">
				{getGreeting()}
			</Typography>
			<Typography className={styles.h2} component="h2" data-test="profile-info-name" variant="h5">
				{name?.trim()}!
			</Typography>
			<Button
				className={logoutButtonStyles.button}
				data-test="profile-info-logout"
				onClick={() => userService.logoutFx()}
			>
				Logout
			</Button>
		</div>
	</div>
);
