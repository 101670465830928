import type { Effect } from 'effector';

import type { RequestConfig } from '@core/http';
import { request } from '@core/http';

import type {
	CalculatePrepCostRequest,
	CalculatePrepCostResponse,
	PrepCostResponse,
	SavePrepCostRequest,
} from './model';
import { domain } from './utils';

/**
 * Get automated prep cost list
 */
export const getPrepCostFX: Effect<string | number, PrepCostResponse> = domain.effect({
	handler: (enquiryId) =>
		request({
			method: 'get',
			url: `/prep-costs/autoPrep/${enquiryId}`,
		} as RequestConfig),
});

/**
 * Calculate prep cost
 */
export const calculatePrepCostFX: Effect<CalculatePrepCostRequest, CalculatePrepCostResponse> = domain.effect({
	handler: ({ enquiryId, ...data }) =>
		request({
			data,
			method: 'post',
			url: `/prep-costs/autoPrep/${enquiryId}/calculate`,
		} as RequestConfig),
});

/**
 * Update prep cost
 */
export const updatePrepCostFX: Effect<SavePrepCostRequest, PrepCostResponse> = domain.effect({
	handler: ({ data, enquiryId }) =>
		request({
			data: { data },
			method: 'post',
			url: `/prep-costs/autoPrep/${enquiryId}/upsert`,
		} as RequestConfig),
});
