import { sortBy } from 'lodash-es';

import { sortOrderFieldsPhotos } from '@core/constants/photoSections';

import type { Editor } from './model';
import { domain } from './utils';

const sortEditors = (editors) =>
	sortBy(
		sortBy(editors, (editor) => !editor.hasDamage),
		(editor) => sortOrderFieldsPhotos.indexOf(editor.kind),
	);

const initialState = [];
const add = domain.event<Editor>();
const remove = domain.event<number>();
const reset = domain.event();
const $editors = domain
	.store<Editor[]>(initialState)
	.on(add, (state, editor, data = [...state]) => {
		const idx = data.findIndex((it) => it.id === editor.id);
		data[idx] = idx > -1 ? editor : data[idx];
		return sortEditors(idx > 1 ? [...data] : [...data, editor]);
	})
	.on(remove, (state, id) => state.filter((it) => it.id !== id))
	.reset(reset);

export const navigation = {
	$: $editors,
	add,
	neighbours: (id) => {
		const state = $editors.getState();
		const idx = state.findIndex((it) => it.id === id);
		return {
			next: state[idx + 1]?.id,
			prev: state[idx - 1]?.id,
		};
	},
	next: (id) => {
		const state = $editors.getState();
		const idx = state.findIndex((it) => it.id === id);
		state[idx]?.hide();
		state[idx + 1]?.show();
	},
	prev: (id) => {
		const state = $editors.getState();
		const idx = state.findIndex((it) => it.id === id);
		state[idx]?.hide();
		state[idx - 1]?.show();
	},
	remove,
	reset,
};
