import './side-effects';

import * as effects from './effects';
import * as automatedEffects from './effects.automated';
import * as manualEffects from './effects.manual';
import * as events from './events';
import * as store from './store';
import * as automatedStore from './store.automated';
import * as manualStore from './store.manual';

export * from './model';
export * from './utils';

export const prepCostService = {
	...effects,
	...automatedEffects,
	...manualEffects,
	...store,
	...automatedStore,
	...manualStore,
	...events,
} as const;
