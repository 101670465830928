import type { Effect } from 'effector';

import type { JSENDAPIListResponse, RequestConfig } from '@core/http';
import { request } from '@core/http';

import {
	AGENT_WEBAPP_CONFIG,
	AGENT_WEBAPP_FEATURES_CONTENT_ID,
	AGENT_WEBAPP_NOTES,
	IMGIX_FALLBACK_CONTENT_ID,
	PROFILING_CONTENT_ID,
} from './constants';
import type { Content } from './model';
import { domain } from './utils';

/**
 * Get configuration content
 */
export const getContentFx: Effect<void, JSENDAPIListResponse<Content[]>> = domain.effect({
	handler: () =>
		request({
			isPublic: true,
			method: 'get',
			params: {
				names: [
					AGENT_WEBAPP_FEATURES_CONTENT_ID,
					IMGIX_FALLBACK_CONTENT_ID,
					PROFILING_CONTENT_ID,
					AGENT_WEBAPP_CONFIG,
					AGENT_WEBAPP_NOTES,
				].join(),
			},
			url: '/content/contentItems/public',
		} as RequestConfig),
});
