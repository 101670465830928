import { flow } from 'lodash-es';
import qs from 'query-string';

import { domain } from '@core/domain';
import * as auth from '@core/services/auth/http';
import * as loading from '@core/services/loading/http';

import { QUERY_STRING_CONFIG } from '../constants';

import { apiCache } from './api-cache';
import { axiosInstance } from './axios';
import { applyCancelToken } from './cancel-token';
import { handleError } from './error-handler';
import type { RequestConfig } from './model';

/**
 * Axios http request handler effect
 */

const serialize = (queryParams) => qs.stringify(queryParams, QUERY_STRING_CONFIG);

export const request = domain.effect({
	handler: async ({
		cacheKey = '',
		cacheTTL = 0,
		headers,
		isPublic = false,
		loadingKey,
		method = 'get',
		params,
		paramsSerializer = { serialize },
		url = '',
		...rest
	}: RequestConfig) => {
		// end session if expired
		if (!auth.isLoggedIn() && !isPublic) {
			auth.endSession();
			return Promise.reject(new Error('Session expired'));
		}

		// return cached response if valid/exist
		const cacheUrl = cacheKey || qs.stringifyUrl({ query: params, url }, QUERY_STRING_CONFIG);
		const cached = apiCache.get(cacheUrl);
		if (method.toLowerCase() === 'get' && cached) {
			return cached;
		}

		const axiosConfig = flow([applyCancelToken])({
			headers,
			method,
			params,
			paramsSerializer,
			url,
			...rest,
		});

		try {
			loading.start(loadingKey);
			const { data, headers: resHeaders } = await axiosInstance.request(axiosConfig);
			const response =
				'x-total-count' in resHeaders ? { list: data?.data || data, total: Number(resHeaders['x-total-count']) } : data;
			data && cacheTTL && apiCache.set(cacheUrl, response, cacheTTL);
			!isPublic && auth.setSession();
			return response;
		} catch (error) {
			return Promise.reject(error);
		} finally {
			setTimeout(() => loading.stop(loadingKey), 200);
		}
	},
	name: 'axiosRequestFx',
});

request.fail.watch(({ error, params }) => handleError(error, params));
