import type { Effect } from 'effector';

import type { JSENDAPIListResponse } from '@core/http';
import { request } from '@core/http';

import type { IVehicle } from '../vehicle/internal';

import type { IDamageImage, UpdateDamageImagesParams } from './model';
import { domain } from './utils';

/**
 * Get damage images detected AI
 */
export const getDamageImagesFx: Effect<IVehicle['id'], JSENDAPIListResponse<IDamageImage[]>> = domain.effect({
	handler: (enquiryId) =>
		request({
			method: 'get',
			url: `/classifier/damage/${enquiryId}`,
		}),
});

/**
 * Get damage images detected AI
 */
export const updateDamageImagesFx: Effect<
	UpdateDamageImagesParams,
	JSENDAPIListResponse<IDamageImage[]>
> = domain.effect({
	handler: async ({ damageType, feedback, imageId }) =>
		request({
			data: {
				damageType,
				feedback,
			},
			method: 'post',
			url: `/classifier/damage/feedback/${imageId}`,
		}),
});
