export const sortById = (a, b) => a.id - b.id;
export const sortByDamageMeta = (a, b) => {
	if (a.damageMeta && !b.damageMeta) {
		return -1;
	}
	if (!a.damageMeta && b.damageMeta) {
		return 1;
	}
	return sortById(a, b);
};

export const EXTERIOR_PHOTOS = {
	fields: [
		{
			label: 'Front - Driver side',
			name: 'exterior_front_driver',
		},
		{
			label: 'Back - Driver side',
			name: 'exterior_rear_driver',
		},
		{
			label: 'Front - Passenger side',
			name: 'exterior_front_passenger',
		},
		{
			label: 'Back - Passenger side',
			name: 'exterior_rear_passenger',
		},
	],
	key: 'EXTERIOR_PHOTOS',
	title: 'Exterior',
};

export const INTERIOR_PHOTOS = {
	fields: [
		{
			label: 'Front seats',
			name: 'interior_front_seats',
		},
		{
			label: 'Rear seats',
			name: 'interior_rear_seats',
		},
		{
			label: 'Dashboard',
			name: 'interior_dashboard',
		},
		{
			label: 'Boot interior',
			name: 'interior_boot',
		},
	],
	key: 'INTERIOR_PHOTOS',
	title: 'Interior',
};

export const INSTRUMENT_CLUSTER_PHOTOS = {
	fields: [
		{
			label: 'Instrument cluster',
			name: 'instrument_cluster',
		},
	],
	key: 'INSTRUMENT_CLUSTER',
	title: '',
};

export const TYRE_PHOTOS = {
	fields: [
		{
			label: 'Front - Driver side',
			name: 'tyre_tread_front_driver',
		},
		{
			label: 'Rear - Driver side',
			name: 'tyre_tread_rear_driver',
		},
		{
			label: 'Front - Passenger side',
			name: 'tyre_tread_front_passenger',
		},
		{
			label: 'Rear - Passenger side',
			name: 'tyre_tread_rear_passenger',
		},
	],
	key: 'TYRE_PHOTOS',
	title: 'Tyre tread photos',
};

export const WHEEL_PHOTOS = {
	fields: [
		{
			label: 'Front - Driver side',
			name: 'wheels_front_driver',
		},
		{
			label: 'Rear - Driver side',
			name: 'wheels_rear_driver',
		},
		{
			label: 'Front - Passenger side',
			name: 'wheels_front_passenger',
		},
		{
			label: 'Rear - Passenger side',
			name: 'wheels_rear_passenger',
		},
	],
	key: 'WHEEL_PHOTOS',
	title: 'Wheel photos',
};

export const SCUFFED_ALLOYS_PHOTOS = {
	key: 'SCUFFED_ALLOYS_PHOTOS',
	seed: { name: 'damage_alloy_scuffs' },
	title: 'Scratches and scuffs',
};

export const TYRE_PROBLEM_PHOTOS = {
	key: 'TYRE_PROBLEM_PHOTOS',
	seed: { name: 'damage_tyres' },
	title: 'Damage tyres',
};

export const DENT_PHOTOS = {
	key: 'DENT_PHOTOS',
	seed: {
		hasLocation: true,
		name: 'damage_dents',
	},
	sort: sortByDamageMeta,
	title: 'Damage dents',
};

export const MISSING_PARTS_PHOTOS = {
	key: 'MISSING_PARTS_PHOTOS',
	seed: {
		hasLocation: true,
		name: 'damage_missing_trims',
	},
	sort: sortByDamageMeta,
	title: 'Damage missing trims',
};

export const PAINT_PROBLEMS_PHOTOS = {
	key: 'PAINT_PROBLEMS_PHOTOS',
	seed: {
		hasLocation: true,
		name: 'damage_paintwork',
	},
	sort: sortByDamageMeta,
	title: 'Damage paintwork',
};

export const SCRATCHES_PHOTOS = {
	key: 'SCRATCHES_PHOTOS',
	seed: {
		hasLocation: true,
		name: 'damage_scratches',
	},
	sort: sortByDamageMeta,
	title: 'Damage scratches',
};

export const WARNING_LIGHTS_PHOTOS = {
	key: 'WARNING_LIGHTS_PHOTOS',
	seed: {
		name: 'damage_warning_lights',
	},
	sort: sortByDamageMeta,
	title: 'Damage warning lights',
};

export const WINDSCREEN_PROBLEMS_PHOTOS = {
	key: 'WINDSCREEN_PROBLEMS_PHOTOS',
	seed: {
		hasLocation: true,
		name: 'damage_windscreen',
	},
	sort: sortByDamageMeta,
	title: 'Damage windscreen',
};

export const SERVICE_HISTORY_PHOTOS = {
	allowPdf: true,
	key: 'SERVICE_HISTORY_PHOTOS',
	seed: { name: 'docs_service_history' },
	title: 'Service history photos',
};

export const DAMAGE_SECTIONS = [
	{
		descSrc: 'scratchesDesc',
		label: 'Scratches and scuffs',
		photoSection: SCRATCHES_PHOTOS,
		toggleSrc: 'hasScratches',
	},
	{
		descSrc: 'dentsDesc',
		label: 'Dents',
		photoSection: DENT_PHOTOS,
		toggleSrc: 'hasDents',
	},
	{
		descSrc: 'paintProblemsDesc',
		label: 'Paintwork problems',
		photoSection: PAINT_PROBLEMS_PHOTOS,
		toggleSrc: 'hasPaintProblems',
	},
	{
		descSrc: 'missingPartsDesc',
		label: 'Missing lights, mirrors or trim',
		photoSection: MISSING_PARTS_PHOTOS,
		toggleSrc: 'hasMissingParts',
	},
	{
		descSrc: 'warningLightsDesc',
		label: 'Warning lights',
		photoSection: WARNING_LIGHTS_PHOTOS,
		toggleSrc: 'hasWarningLights',
	},
	{
		descSrc: 'windscreenProblemsDesc',
		label: 'Windscreen',
		photoSection: WINDSCREEN_PROBLEMS_PHOTOS,
		toggleSrc: 'hasWindscreenProblems',
	},
];

export const sortOrderFieldsPhotos = [
	...EXTERIOR_PHOTOS.fields,
	...INTERIOR_PHOTOS.fields,
	...INSTRUMENT_CLUSTER_PHOTOS.fields,
	SCRATCHES_PHOTOS.seed,
	DENT_PHOTOS.seed,
	PAINT_PROBLEMS_PHOTOS.seed,
	MISSING_PARTS_PHOTOS.seed,
	WARNING_LIGHTS_PHOTOS.seed,
	WINDSCREEN_PROBLEMS_PHOTOS.seed,
	...WHEEL_PHOTOS.fields,
	...TYRE_PHOTOS.fields,
	SCUFFED_ALLOYS_PHOTOS.seed,
	TYRE_PROBLEM_PHOTOS.seed,
	SERVICE_HISTORY_PHOTOS.seed,
]
	.map((input) => input.name)
	.flat();

export const MAIN_PHOTOS_KINDS = [...EXTERIOR_PHOTOS.fields, ...INTERIOR_PHOTOS.fields].map(({ name }) => name);
