import React from 'react';
import cx from 'classnames';
import { Card, CardContent, Typography } from '@mui/material';

import type { StatsCardProps } from './Profile.model';

import styles from './StatsCard.module.scss';

export const StatsCard = ({ icon, loading, text, title }: StatsCardProps) => (
	<Card className={cx(styles.card, { [styles.placeholderItem]: loading, [styles.primary]: loading })}>
		<CardContent>
			<Typography className={cx(styles.title, { [styles.loading]: loading })} component="h2" variant="h5">
				{title}
			</Typography>
			<Typography gutterBottom className={cx(styles.text, { [styles.loading]: loading })} variant="h6">
				{text}
			</Typography>
			{icon}
		</CardContent>
	</Card>
);
