import './ga4.side-effects';
import './snowplow.side-effects';

import * as events from './events';

export * from './constants';

export const analyticsService = {
	...events,
};
