import './side-effects';

import * as store from './store';

export * from './model';
export * from './utils';

export const vehicleSidesService = {
	...store,
};
