import { SELLER_DOCUMENTS_KINDS } from '@core/constants/documents';
import {
	DAMAGE_SECTIONS,
	EXTERIOR_PHOTOS,
	INTERIOR_PHOTOS,
	SERVICE_HISTORY_PHOTOS,
	TYRE_PHOTOS,
	WHEEL_PHOTOS,
} from '@core/constants/photoSections';
import { VEHICLE_SECTIONS } from '@core/constants/vehiclePage';

export const SELLER_FIELDS = {
	ADDRESS1: 'address1',
	ADDRESS2: 'address2',
	ADDRESS3: 'address3',
	CITY: 'city',
	EMAIL: 'email',
	MOBILE: 'mobile',
	NAME: 'name',
	POSTCODE: 'postcode',
};

const extractPhotoSectionFields = ({ descSrc, photoSection, toggleSrc }) => [
	toggleSrc,
	descSrc,
	photoSection.seed.name,
];
const extractPhotoFields = (photo) => photo.name;

export const ENQUIRY_FIELDS = {
	ACCOUNT_MANAGER_ID: 'accountManagerId',
	ADDITIONAL_DETAILS: 'additionalDetails',
	ADDITIONAL_DOCUMENTS: 'additionalDocuments',
	ADDITIONAL_SPEC: 'additionalSpec',
	AFTER_SALES_AGENT_ID: 'aftersalesAgentId',
	ARE_BATTERIES_IN_LEASE: 'areBatteriesInLease',
	AVAILABLE_FROM: 'availableFrom',
	AVAILABLE_UNTIL: 'availableUntil',
	BANK_ACCOUNT_NAME: 'bankAccountName',
	BANK_ACCOUNT_NAME_MATCHES_V5: 'bankAccountNameMatchesV5',
	CHARGING_CABLES: 'chargingCables',
	COLLECTION_DATE: 'collectionDate',
	COLLECTION_NOTES: 'collectionNotes',
	COUNTER_OFFER_PRICE: 'counterOfferPrice',
	DENTS_DESC: 'dentsDesc',
	DOCS_NOTES: 'docsNotes',
	ELECTRIC_PROBLEMS_DESC: 'electricsProblemsDesc',
	EQUIPMENT: 'equipment',
	EXCLUSIVE_TO_DEALER: 'exclusiveToDealer',
	FINANCE_CLEANER: 'financeCleaner',
	FINANCE_FIGURE: 'financeFigure',
	FIRST_REGISTERED: 'spec.firstRegistered',
	GRADE: 'grade',
	HAS_ADDITIONAL_DAMAGE: 'hasAdditionalDamage',
	HAS_BEEN_SMOKED_IN: 'hasBeenSmokedIn',
	HAS_DAMAGE_ON_WHEELS: 'hasDamagedWheels',
	HAS_DENTS: 'hasDents',
	HAS_ELECTRIC_PROBLEMS: 'hasElectricsProblems',
	HAS_LOCKING_WHEEL_NUT: 'hasLockingWheelNut',
	HAS_MISSING_PARTS: 'hasMissingParts',
	HAS_PAINT_PROBLEMS: 'hasPaintProblems',
	HAS_PRIVATE_PLATE: 'hasPrivatePlate',
	HAS_SCRATCHES: 'hasScratches',
	HAS_SCUFFED_ALLOY: 'hasScuffedAlloy',
	HAS_SERVICE_RECORDS: 'hasServiceRecords',
	HAS_TOOLS_IN_BOOT: 'hasToolsInBoot',
	HAS_TYRE_PROBLEMS: 'hasTyreProblems',
	HAS_V5_LOGBOOK: 'hasV5LogBook',
	HAS_WARNING_LIGHTS: 'hasWarningLights',
	HAS_WINDSCREEN_PROBLEMS: 'hasWindscreenProblems',
	INDEPENDENT_SERVICE_STAMPS_COUNT: 'independentServiceStampsCount',
	INTERIOR_TYPE: 'interiorType',
	INVOICE_DATE: 'invoiceDate',
	INVOICE_STATUS: 'invoiceStatus',
	IS_VAT_QUALIFYING: 'isVatQualifying',
	IS_VEHICLE_ON_FINANCE: 'isVehicleOnFinance',
	KEEP_PRIVATE_PLATE: 'keepPrivatePlate',
	KEEPER_NAME: 'keeperName',
	KEEPER_START_DATE: 'keeperStartDate',
	KEEPER_TYPE: 'keeperType',
	KEYS_COUNT: 'keysCount',
	LAST_MOT_DATE: 'lastMOTDate',
	LEAD_MANAGER_ID: 'leadManagerId',
	LOCKING_WHEEL_NUT: 'lockingWheelNut',
	MANUFACTURE_YEAR: 'spec.manufactureYear',
	MARKET_PRICE: 'marketPrice',
	MILEAGE: 'mileage',
	MISSING_PART_DESC: 'missingPartsDesc',
	OFFICIAL_SERVICE_STAMPS_COUNT: 'officialServiceStampsCount',
	PAINT_PROBLEM_DESC: 'paintProblemsDesc',
	PLATE_HISTORY: 'plateHistory',
	PLATE_HISTORY_SELECTION: 'plateHistorySelection',
	PLATE_HISTORY_SELECTION_OTHER: 'plateHistorySelectionOther',
	PREP_COSTS: 'prepCosts',
	PRETTY_VRM: 'prettyVrm',
	PREVIOUS_KEEPERS_COUNT: 'previousKeepersCount',
	PRICE_HIGH: 'priceHigh',
	PRICE_SOURCE: 'priceSource',
	PROFILE_CONFIRMATION_DETAILS: 'profileConfirmationDetails',
	PROFILING_STATUS: 'profilingStatus',
	QA_FAILED_REASONS: 'qaFailedReasons',
	SALES_COMPLETION_ID: 'salesCompletionId',
	SCRATCHES_DESC: 'scratchesDesc',
	SELLER_WILL_ACCEPT_PRICE: 'sellerWillAcceptPrice',
	SELLING_VRM: 'sellingVrm',
	SERVICE_RECORD: 'serviceRecord',
	SOLD_DATE: 'soldDate',
	SPEC_BODYCATEGORY: 'spec.bodyCategory',
	SPEC_COLOUR: 'spec.colour',
	SPEC_ENGINESIZE: 'spec.engineSize',
	SPEC_FUEL: 'spec.fuel',
	SPEC_MAKE: 'spec.make',
	SPEC_MODEL: 'spec.model',
	SPEC_TRANSMISSION: 'spec.transmission',
	STATE_ID: 'stateId',
	TRAFFIC_SOURCE: 'trafficSource',
	V5LOG_BOOK_OWNED_BY_SELLER: 'v5LogBookOwnedBySeller',
	VIN: 'spec.vin',
	VRM: 'vrm',
	WARNING_LIGHTS_DESC: 'warningLightsDesc',
	WHEEL_TYPE: 'wheelType',
	WINDSCREEN_PROBLEMS_DESC: 'windscreenProblemsDesc',
} as const;

export const RELATED_TO_FORM_FIELDS = new Map<VEHICLE_SECTIONS, Array<string>>([
	[
		VEHICLE_SECTIONS.PRICING_AND_STATUS,
		[
			ENQUIRY_FIELDS.MARKET_PRICE,
			ENQUIRY_FIELDS.PRICE_HIGH,
			ENQUIRY_FIELDS.PRICE_SOURCE,
			ENQUIRY_FIELDS.PREP_COSTS,
			ENQUIRY_FIELDS.PROFILE_CONFIRMATION_DETAILS,
			ENQUIRY_FIELDS.SELLER_WILL_ACCEPT_PRICE,
			ENQUIRY_FIELDS.COUNTER_OFFER_PRICE,
			ENQUIRY_FIELDS.AVAILABLE_FROM,
			ENQUIRY_FIELDS.AVAILABLE_UNTIL,
			ENQUIRY_FIELDS.EXCLUSIVE_TO_DEALER,
			ENQUIRY_FIELDS.STATE_ID,
			ENQUIRY_FIELDS.PROFILING_STATUS,
			ENQUIRY_FIELDS.QA_FAILED_REASONS,
			ENQUIRY_FIELDS.COLLECTION_DATE,
			ENQUIRY_FIELDS.LEAD_MANAGER_ID,
			ENQUIRY_FIELDS.AFTER_SALES_AGENT_ID,
			ENQUIRY_FIELDS.ACCOUNT_MANAGER_ID,
			ENQUIRY_FIELDS.SOLD_DATE,
			ENQUIRY_FIELDS.INVOICE_DATE,
		],
	],
	[
		VEHICLE_SECTIONS.DOCUMENTATION,
		[
			ENQUIRY_FIELDS.COLLECTION_NOTES,
			ENQUIRY_FIELDS.VIN,
			ENQUIRY_FIELDS.PREVIOUS_KEEPERS_COUNT,
			ENQUIRY_FIELDS.LAST_MOT_DATE,
			ENQUIRY_FIELDS.FIRST_REGISTERED,
			ENQUIRY_FIELDS.PRETTY_VRM,
			ENQUIRY_FIELDS.MILEAGE,
			ENQUIRY_FIELDS.HAS_V5_LOGBOOK,
			ENQUIRY_FIELDS.V5LOG_BOOK_OWNED_BY_SELLER,
			ENQUIRY_FIELDS.KEEPER_TYPE,
			ENQUIRY_FIELDS.KEEPER_NAME,
			ENQUIRY_FIELDS.KEYS_COUNT,
			ENQUIRY_FIELDS.HAS_PRIVATE_PLATE,
			ENQUIRY_FIELDS.PLATE_HISTORY_SELECTION,
			ENQUIRY_FIELDS.PLATE_HISTORY_SELECTION_OTHER,
			ENQUIRY_FIELDS.SELLING_VRM,
			ENQUIRY_FIELDS.IS_VEHICLE_ON_FINANCE,
			ENQUIRY_FIELDS.IS_VAT_QUALIFYING,
			ENQUIRY_FIELDS.FINANCE_CLEANER,
			ENQUIRY_FIELDS.FINANCE_FIGURE,
			ENQUIRY_FIELDS.DOCS_NOTES,
			ENQUIRY_FIELDS.MANUFACTURE_YEAR,
			ENQUIRY_FIELDS.KEEPER_START_DATE,
			ENQUIRY_FIELDS.KEEP_PRIVATE_PLATE,
		],
	],
	[
		VEHICLE_SECTIONS.SPECIFICATION,
		[
			ENQUIRY_FIELDS.SPEC_MAKE,
			ENQUIRY_FIELDS.SPEC_MODEL,
			ENQUIRY_FIELDS.SPEC_BODYCATEGORY,
			ENQUIRY_FIELDS.SPEC_ENGINESIZE,
			ENQUIRY_FIELDS.SPEC_TRANSMISSION,
			ENQUIRY_FIELDS.SPEC_FUEL,
			ENQUIRY_FIELDS.CHARGING_CABLES,
			ENQUIRY_FIELDS.ARE_BATTERIES_IN_LEASE,
			ENQUIRY_FIELDS.SPEC_COLOUR,
			ENQUIRY_FIELDS.INTERIOR_TYPE,
			ENQUIRY_FIELDS.EQUIPMENT,
			ENQUIRY_FIELDS.ADDITIONAL_SPEC,
		],
	],
	[
		VEHICLE_SECTIONS.WHEELS_AND_TYRES,
		[
			...WHEEL_PHOTOS.fields.map(extractPhotoFields),
			...TYRE_PHOTOS.fields.map(extractPhotoFields),
			ENQUIRY_FIELDS.HAS_TYRE_PROBLEMS,
			ENQUIRY_FIELDS.LOCKING_WHEEL_NUT,
			ENQUIRY_FIELDS.HAS_TOOLS_IN_BOOT,
			ENQUIRY_FIELDS.HAS_SCUFFED_ALLOY,
		].flat(),
	],
	[
		VEHICLE_SECTIONS.CONDITION_AND_DAMAGE,
		[
			...DAMAGE_SECTIONS.map(extractPhotoSectionFields),
			ENQUIRY_FIELDS.HAS_ADDITIONAL_DAMAGE,
			ENQUIRY_FIELDS.HAS_BEEN_SMOKED_IN,
			ENQUIRY_FIELDS.ADDITIONAL_DETAILS,
			ENQUIRY_FIELDS.HAS_ELECTRIC_PROBLEMS,
		].flat(),
	],
	[
		VEHICLE_SECTIONS.SERVICE_HISTORY,
		[
			ENQUIRY_FIELDS.SERVICE_RECORD,
			ENQUIRY_FIELDS.HAS_SERVICE_RECORDS,
			ENQUIRY_FIELDS.OFFICIAL_SERVICE_STAMPS_COUNT,
			ENQUIRY_FIELDS.INDEPENDENT_SERVICE_STAMPS_COUNT,
			SERVICE_HISTORY_PHOTOS.seed.name,
		],
	],
	[VEHICLE_SECTIONS.SELLER_DOCUMENTS, [SELLER_DOCUMENTS_KINDS.PROOF_OF_PURCHASE]],
	[VEHICLE_SECTIONS.SELLER_DETAILS, Object.values(SELLER_FIELDS).map((name) => `seller.${name}`)],
	[
		VEHICLE_SECTIONS.PHOTOS,
		[...EXTERIOR_PHOTOS.fields.map(extractPhotoFields), ...INTERIOR_PHOTOS.fields.map(extractPhotoFields)].flat(),
	],
]);

export enum INVOICE_STATUSES_MAP {
	InvoiceRaised = 'Invoiced',
	SalesOrderCreated = 'Pending',
}
