import * as app from '../app/internal';
import * as enquiry from '../vehicle/internal';

import * as effects from './effects';
import * as events from './events';
import type { AddressSuggestion, Seller } from './model';
import { domain, mapAddressSuggestions } from './utils';

export const $seller = domain
	.store<Seller>({} as Seller)
	.on(effects.getSellerFx.doneData, (_, data) => data)
	.on(effects.updateSellerFx.doneData, (state, data) => ({ ...state, ...data }))
	.on(effects.updateSellerEmailFx.done, (state, { params: { email } }) => ({ ...state, email }))
	.reset([app.reset, events.reset, enquiry.$enquiryId]);

export const $addresses = domain
	.store<{ postcode: string; result: AddressSuggestion[] }>({ postcode: '', result: [] })
	.on(effects.getAddressesByPostcodeFx.done, (_, { params, result }) => ({
		postcode: params,
		result: mapAddressSuggestions(result),
	}))
	.on(effects.getAddressesByPostcodeFx, () => ({ postcode: '', result: [] }))
	.reset([app.reset, events.reset, enquiry.$enquiryId]);
