import { useEffect, useRef, useState } from 'react';
import { useField, useFormState } from 'react-final-form';
import { usePrevious } from 'react-use';
import type { Event } from 'effector';
import { useUnit } from 'effector-react';
import { debounce } from 'lodash-es';

import { currentPage } from '@core/services/app';
import { FORM_NAME, vehicleService } from '@core/services/vehicle';

export const usePageInit = ({
	documentTitle,
	pageService,
	params,
	scrollToTop = true,
	title,
}: {
	documentTitle?: string;
	pageService: any;
	params?: any;
	scrollToTop?: boolean;
	title?: string;
}) => {
	useEffect(() => {
		pageService.init(params);
		scrollToTop && window?.scrollTo(0, 0);
		return () => {
			pageService.reset();
		};
	}, [pageService, params, scrollToTop]);

	useEffect(() => {
		currentPage.setMeta({
			...(documentTitle && { documentTitle }),
			...(title && { title }),
		});
	}, [documentTitle, title]);
};

export const setCSSVariable = (variableName, value, element = document.documentElement) => {
	if (element) {
		value ? element.style.setProperty(`--${variableName}`, value) : element.style.removeProperty(`--${variableName}`);
	}
};

export const useCssVar = (vars, extRef?) => {
	const ref = useRef<any | null>(document.documentElement);
	const elmRef = extRef || ref;
	const varsStr = JSON.stringify(vars);
	useEffect(() => {
		const element = elmRef.current;
		Object.keys(vars).forEach((name) => setCSSVariable(name, vars[name], element));
		return () => {
			Object.keys(vars).forEach((name) => setCSSVariable(name, '', element));
		};
	}, [elmRef, varsStr]); // eslint-disable-line

	return { ref: elmRef };
};

export const useFormName = (name: string) => {
	const { values } = useFormState();

	return values[FORM_NAME] ? `${values[FORM_NAME]}.${name}` : name;
};

export const useMessages = (name: string) => {
	const messages = useUnit(vehicleService.$formsValidateErrorsMap);
	const {
		meta: { modified },
	} = useField(name);
	const formName = useFormName(name);
	const message = messages.get(formName);
	const prevModified = usePrevious(modified);
	const { message: text } = message || {};

	useEffect(() => {
		if (modified && !prevModified && text) {
			vehicleService.clearSubmitErrors([formName]);
		}
	}, [modified, prevModified, text, formName]);

	return message || {};
};

export const useEffEventData = <T>(event: Event<T> | undefined, cb: (data: T) => void, deps: any[] = []) => {
	useEffect(() => {
		const unsub = event?.watch((data: T) => cb(data));
		return () => {
			unsub?.();
		};
	}, [event, cb, ...deps]);
};

export const useDebouncedChangeHandler = ({
	onChange,
	timeout = 300,
	value,
}: {
	onChange: (value: string) => void;
	timeout?: number;
	value: string;
}) => {
	const debouncedChange = useRef(debounce(onChange, timeout)).current;
	const [inputValue, setInputValue] = useState('');

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	const onInputChange = (data: string) => {
		setInputValue(data);
		debouncedChange(data);
	};

	return { inputValue, onInputChange };
};
