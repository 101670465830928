import { contentService } from '@core/services/content';
import { captureException } from '@core/utils/exceptions';

interface Params {
	bg?: string;
	dpr?: string;
	fit?: string;
	h?: string;
	q?: string;
	rect?: string;
	w?: string;
	x?: string;
	y?: string;
}

interface TransformParams {
	data: Params;
	result?: string[];
}

// Do not change the order, it will break correct replacement
const TRANSFORM_URLS = [
	['motorway-photos-redacted-stage.imgix.net', 'motorway-stage.twic.pics/photos-redacted'],
	['motorway-photos-modified-stage.imgix.net', 'motorway-stage.twic.pics/photos-modified'],
	['motorway-photos-stage.imgix.net', 'motorway-stage.twic.pics/photos'],
	['motorway-photos-redacted.imgix.net', 'motorway.twic.pics/photos-redacted'],
	['motorway-photos-modified.imgix.net', 'motorway.twic.pics/photos-modified'],
	['motorway-photos.imgix.net', 'motorway.twic.pics/photos'],
];

const imgIxToTwicPicsUrl = (url = '') => TRANSFORM_URLS.reduce((acc, [ix, twc]) => acc.replace(ix, twc), url);

const dprIze = (value?: string, dpr = '1') => (value ? (Number(value) * Number(dpr)).toFixed() : '-');

const fitResize = ({ data, result = [] }: TransformParams): TransformParams => {
	const { dpr, fit, h, rect, w, ...rest } = data || {};
	const size =
		(operator = 'contain') =>
		() =>
			`${operator}=${dprIze(w, dpr)}x${dprIze(h, dpr)}`;
	// eslint-disable-next-line sort-keys-fix/sort-keys-fix
	const params = (key = '') => ({ [key]: size(), crop: size('cover') }[key]);
	return h || w
		? {
				data: { ...rest, ...(dpr && { dpr }) },
				result: [...result, params(fit)()],
		  }
		: { data: { ...rest, ...(dpr && { dpr }), ...(h && { h }), ...(w && { w }), ...(rect && { rect }) }, result };
};

const TRANSFORMATIONS = {
	bg: ({ data, result = [] }: TransformParams): TransformParams => {
		const { bg, ...rest } = data || {};
		return bg
			? {
					data: rest,
					result: [...result, `background=${bg}`],
			  }
			: { data: rest, result };
	},
	fit: fitResize,
	h: fitResize,
	q: ({ data, result = [] }: TransformParams): TransformParams => {
		const { q, ...rest } = data || {};
		return q
			? {
					data: rest,
					result: [...result, `quality=${q}`],
			  }
			: { data: rest, result };
	},
	rect: ({ data, result = [] }: TransformParams): TransformParams => {
		const { rect, ...rest } = data || {};
		const [x, y, w, h] = rect?.split(',') || [];
		return x && y && w && h
			? {
					data: rest,
					result: [...result, `crop=${w}x${h}@${x}x${y}`],
			  }
			: { data: rest, result };
	},
	w: fitResize,
};

const TRANSFORMATION_ORDER = [
	TRANSFORMATIONS.fit,
	TRANSFORMATIONS.rect,
	TRANSFORMATIONS.q,
	TRANSFORMATIONS.w,
	TRANSFORMATIONS.h,
	TRANSFORMATIONS.bg,
];

const replaceParams = (params: TransformParams, idx = 0): TransformParams => {
	if (TRANSFORMATION_ORDER[idx]) {
		const { data, result } = TRANSFORMATION_ORDER[idx](params);
		return Object.keys(data).length ? replaceParams({ data, result }, idx + 1) : { data, result };
	}
	return params;
};

export const getTPLink = (imgIxUrl: string): string => {
	try {
		if (imgIxUrl && !imgIxUrl.includes('ixlib=')) {
			const decodedUrl = decodeURIComponent(imgIxUrl);
			const { origin, pathname, searchParams } = new URL(decodedUrl);
			const tpUrl = imgIxToTwicPicsUrl(`${origin}${pathname}`);
			const { result } = replaceParams({ data: Object.fromEntries(searchParams) });
			const tpParams = result?.length ? `?twic=v1/${result.join('/')}` : '';

			return tpUrl.includes('.twic.pics') ? `${tpUrl}${tpParams}` : imgIxUrl;
		}
		return imgIxUrl;
	} catch (error) {
		captureException({
			contexts: {
				imgIxUrl,
			},
			error,
			fingerprint: 'twicpics-conversion',
			title: 'Error during ImgIx -> TP conversion',
		});
	}
	return imgIxUrl;
};

export const replaceFallbackUrls = (photos) =>
	photos.map((photo) => ({
		...photo,
		legacyUrl: getTPLink(photo.legacyUrl),
		mainImgIxUrl: photo.url,
		modifiedUrl: getTPLink(photo.modifiedUrl),
		originalUrl: getTPLink(photo.originalUrl),
		redactedUrl: getTPLink(photo.redactedUrl),
		thumbnailUrl: getTPLink(photo.thumbnailUrl),
		url: getTPLink(photo.url),
	}));

export const replaceFallbackUrl = (url = ''): string => {
	const { enabled } = contentService.$imgIxFallback.getState();
	return enabled && url ? getTPLink(url) : url;
};
